//@flow
//$FlowFixMe
import React, { useEffect, Suspense, useCallback } from 'react'

import { Navbar, Toast, Loader, Intercom, Tabs } from 'sputnik-ui'
import { connect } from 'react-redux'
import { Switch, Route, Link, withRouter, Redirect } from 'react-router-dom'
import { AppTemplate } from 'components'
import requireAuth from 'utils/requireAuth'
import { Tab, Tooltip, IconButton, Typography } from '@material-ui/core'
import { Update } from '@material-ui/icons'
import styled from 'styled-components'
import type { Location } from 'react-router-dom'

import { clear, showError, showMessage } from 'features/ui/uiSlice'
import { fetchBillingAccount } from 'store/billing/operations'
import { useTranslation } from 'react-i18next'
import BillingAccountModal from 'features/billing/BillingAccountModal'
import LicenseModal from 'features/auth/LicenseModal'
import PartnerProgramPage from 'components/pages/PartnerProgramPage'
import AcceptProgramModal from 'features/PartnerProgram/AcceptProgramModal'
import { AcceptLicenseModal } from 'components/organisms/AcceptLicenseModal'
import { fetchStatisticPartnerProgram, fetchUserCompany } from 'store/auth/operations'


const DevicesPage = React.lazy(() => import('./components/pages/DevicesPage'))
const ServicesPage = React.lazy(() => import('./components/pages/ServicesPage'))
const BillingPage = React.lazy(() => import('./components/pages/BillingPage'))
const BuyPage = React.lazy(() => import('./components/pages/BuyPage'))
const AccountsPage = React.lazy(() => import('./components/pages/AccountsPage'))
const AccountPage = React.lazy(() => import('./components/pages/AccountPage'))
const CustomizationPage = React.lazy(() =>
  import('./components/pages/CustomizationPage')
)
const AnalyticsPage = React.lazy(() =>
  import('./components/pages/AnalyticsPage/index')
)
const RoadmapPage = React.lazy(() =>
  import('./components/pages/RoadmapPage.jsx')
)

const re = /\/[^/]*/g // until 2nd occurence of /

const Wrap = styled.div`
  background: ${(p) => p.theme.palette.secondary.main};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.875rem;
  @media screen and (max-width: ${(p) => p.theme.sizes.phone}) {
    padding: 3%;
    height: auto;
  }
`

function App({
  location,
  msg,
  info,
  error,
  dispatch,
  updateAction,
  balance,
  ...props
}: Props) {
  const { t } = useTranslation(['common', 'notifications'])
  const tabs = [
    {
      label: t('common:menu.buy'),
      to: '/products',
      disabled: true,
    },
    {
      label: t('common:menu.devices'),
      to: '/devices',
    },
    {
      label: t('common:menu.billing'),
      to: '/billing',
    },
    {
      label: t('common:menu.accounts'),
      to: '/accounts',
    },
    {
      label:  t('common:menu.customization'),
      to: '/customization',
    },
    {
      label: t('common:menu.analytics'),
      to: '/analytics',
    },
    {
      label: t('common:menu.partner_program'),
      to: '/partner_program',
    },
  ]
  const regexMatch = location.pathname.match(re)
  const namespace = regexMatch && regexMatch[0]

  useEffect(() => {
    // AOS.init({
    //   duration: 600,
    // })
    dispatch(fetchUserCompany())
    dispatch(fetchStatisticPartnerProgram())
    dispatch(fetchBillingAccount())
  }, [dispatch])

  useEffect(() => {
    if (balance < 0) {
      dispatch(showError(t('notifications:statuses.negative_balance')))
    }
  }, [])

  const copySome = (e) => {
    const el = document.createElement('textarea')
    el.value = e
    document.body && document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body && document.body.removeChild(el)
    dispatch(showMessage('Номер ЛК скопирован в буфер обмена'))
  }

  console.log('start') // todo remove

  return (
    <AppTemplate
      header={
        <Navbar
          app
          shadow
          logo
          size="normal"
          user
          copy={copySome}
          actions={
            <Tooltip title="Баланс" placement="bottom">
              <IconButton disableRipple component={Link} to="/billing">
                <Typography color="inherit">{props.userBalance}</Typography>
              </IconButton>
            </Tooltip>
          }
        >
          <Tabs value={namespace}>
            {tabs.map((tab) => (
              <Tab
                disableRipple
                label={tab.label}
                value={tab.to}
                to={tab.to}
                key={tab.to}
                component={Link}
                onClick={
                  tab?.disabled
                    ? () =>
                        dispatch(
                          showError('Раздел обновляется и временно недоступен')
                        )
                    : null
                }
              />
            ))}
          </Tabs>
        </Navbar>
      }
    >
      {/* <Wrap>
        Оцените приоритетность
        <Link to="/roadmap" style={{ margin: '0 .325rem' }}>
          релизов
        </Link>
        <div>для вашей компании</div>
        <div>(cписок может обновляться)</div>
      </Wrap> */}
      <Suspense fallback={<Loader />}>
        <Switch>
          <Redirect exact from="/" to="/devices" />
          <Route path="/devices/:device/" component={DevicesPage} />
          {/* <Route path="/products" component={BuyPage} /> */}
          <Route path="/devices" component={DevicesPage} />
          <Route path="/partner_program" component={PartnerProgramPage} />
          {/* <Route path="/subscriptions" component={ServicesPage} /> */}
          <Route path="/billing" component={BillingPage} />
          <Route exact path="/accounts" component={AccountsPage} />
          <Route path="/accounts/:account" component={AccountPage} />
          <Route path="/roadmap" component={RoadmapPage} />
          <Route path="/customization" component={CustomizationPage} />
          {/* <Route path="/roadmap" component={RoadmapPage}></Route> */}
          <Route path="/analytics">
            <AnalyticsPage></AnalyticsPage>
          </Route>

          <Redirect to="/devices" />
        </Switch>
        <LicenseModal />
        <BillingAccountModal />
        <AcceptProgramModal />
        <AcceptLicenseModal />
      </Suspense>
      <Intercom />
      <Toast
        variant="info"
        open={!!updateAction}
        action={
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            disableRipple
            style={{
              marginRight: -15,
            }}
          >
            <Update />
          </IconButton>
        }
        onClick={updateAction}
      >
        <div>
          <Typography variant="h6">{t('common:toast.new_version')}</Typography>

          <Typography color="textSecondary" component="span">
            {t('common:button.click_to_update')}
          </Typography>
        </div>
      </Toast>
      <Toast variant="info" open={!!info} handleClose={() => dispatch(clear())}>
        {info}
      </Toast>
      <Toast
        variant="success"
        open={!!msg}
        handleClose={() => dispatch(clear())}
      >
        {msg}
      </Toast>
      <Toast
        variant="error"
        open={!!error}
        handleClose={() => dispatch(clear())}
      >
        {error}
      </Toast>
    </AppTemplate>
  )
}

type ReduxProps = {
  msg: ?string,
  error: ?string,
  searchQuery: string,
  userBalance: ?string,
}

type Props = ReduxProps & {
  dispatch: Function,
  location: Location,
}

const mapStateToProps = ({
  services,
  billing,
  devices,
  products,
  auth,
  users,
  servicePartnersOld,
  ui,
}): ReduxProps => ({
  msg:
    services.msg ||
    billing.msg ||
    products.msg ||
    users.msg ||
    servicePartnersOld.msg ||
    ui.msg,
  error:
    auth.error||
    services.error ||
    billing.error ||
    products.error ||
    users.error ||
    servicePartnersOld.error ||
    ui.error,
  info: ui.info,
  searchQuery: devices.query,
  userBalance: billing.account && billing.account.balance_with_currency,
  balance: billing.account && billing.account.balance,
  updateAction: ui.updateAction,
})

export default withRouter(requireAuth(connect(mapStateToProps)(App)))

{
  /* <Wrap
style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 40,
}}
>
Пройдите{' '}
<Link to="/roadmap" style={{ margin: '0 .325rem' }}>
  опрос
</Link>
на график релиза новых функций
</Wrap> */
}
