//@flow
import * as actions from './actions'
import Api from 'utils/api'
import type { User } from './types'
import config from 'config'
import { showError } from 'features/ui/uiSlice'

export const getUsers: Function = ({
  q,
  page,
  limit,
  sort,
  order,
}: {
  q: string,
  page: number,
  limit: number,
  sort?: 'email' | 'company' | 'full_name' | 'phone',
  order?: 'asc' | 'desc',
}): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.getUsersRequest())
  try {
    const setParams = () => {
      let params = []

      if (q) {
        params.push(`q=${q}`)
      }
      if (limit) {
        params.push(`limit=${limit}`)
      }
      if (limit && page) {
        params.push(`offset=${limit * page}`)
      }
      if (sort) {
        params.push(`sort=${sort}`)
      }
      if (order) {
        params.push(`order=${order}`)
      }

      return params.join('&')
    }

    const users: Array<Object> = await Api.request(
      `/users?${setParams()}`,
      {},
      config.ssoUri,
    )

    dispatch(actions.getUsersSuccess(users))
  } catch (err) {
    dispatch(actions.getUsersError(err))
  }
}

export const getCurrentUser: Function = (id: number): Function => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getCurrentUserRequest())
  try {
    const user: User = await Api.request(`/users/${id}`, {}, config.ssoUri)
    dispatch(actions.getCurrentUserSuccess(user))
  } catch (err) {
    dispatch(
      actions.getCurrentUserError(
        'Не удалось получить информацию о данном пользователе',
      ),
    )
    return Promise.reject(err)
  }
}


export const inviteUser: Function = (body): Function => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.inviteUserRequest())
  try {
    const response = await Api.request(
      `/invites`,
      {
        method: 'POST',
        body: JSON.stringify(body),
      },
      config.ssoUri,
    )

    dispatch(actions.inviteUserSuccess())
    return Promise.resolve(response)
  } catch (err) {
    const { message } = await err.json()
    // dispatch(actions.inviteUserError(message)) //hz nahuya eto bilo i kakaya ideya bila
    dispatch(showError(message))
    return Promise.reject(err)
  }
}

export const getUserPermissions: Function = (
  userId: number,
  appId?: number,
): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.getUserPermissionsRequest())
  try {
    const permissions = appId
      ? await Api.request(
          `/users/${userId}/permissions?app_id=${appId}`,
          {},
          config.ssoUri,
        )
      : await Api.request(`/users/${userId}/permissions`, {}, config.ssoUri)
    dispatch(actions.getUserPermissionsSuccess(permissions))
    return Promise.resolve(permissions)
  } catch (err) {
    dispatch(actions.inviteUserError(err))
    return Promise.reject(err)
  }
}

export const updateUserPermissions: Function = ({
  userId,
  appId,
  abilities,
}): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.updateUserPermissionsRequest())
  try {
    const response = await Api.request(
      `/permissions?user_id=${userId}&app_id=${appId}`,
      {
        method: 'PUT',
        body: JSON.stringify({ abilities }),
      },
      config.ssoUri,
    )

    dispatch(actions.updateUserPermissionsSuccess(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(actions.updateUserPermissionsError(err))
    return Promise.reject(err)
  }
}

export const blockUser: Function = ({
  userId,
  appId,
  abilities,
}): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.blockUserRequest())
  try {
    const response = await Api.request(`/user`, {}, config.ssoUri)
    dispatch(actions.blockUserSuccess(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(actions.blockUserError(err))
    return Promise.reject(err)
  }
}

export const deleteUser: Function = (id): Function => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.deleteUserRequest())
  try {
    const response = await Api.request(`/users/${id}`, { method: 'DELETE' })
    dispatch(actions.deleteUserSuccess(response))
    return Promise.resolve(response)
  } catch (err) {
    const errorMessage =
      err.status === 403
        ? 'У вас отсутсвуют права на удаление пользователя'
        : 'При удалении пользователя произошла ошибка'
    dispatch(actions.deleteUserError(errorMessage))
    return Promise.reject(errorMessage)
  }
}

export const updateUser: Function = ({
  userId,
  email,
  password,
  app_ids,
  is_blocked,
  company_id,
}): Object => async (dispatch: Function): Promise<any> => {
  dispatch(actions.updateUserRequest())

  const setParams = () => {
    let params = []

    if (email) params.push(`email=${email}`)
    if (password) params.push(`password=${password}`)
    if (app_ids) params.push(`app_ids=${app_ids}`)
    if (is_blocked !== undefined) params.push(`is_blocked=${is_blocked}`)
    if (company_id) params.push(`company_id=${company_id}`)
    params = params.join('&')

    return `?${params}`
  }

  try {
    const response = await Api.request(`/users/${userId}${setParams()}`, {
      method: 'PUT',
    })
    dispatch(actions.updateUserSuccess(response))
    return Promise.resolve(response)
  } catch (err) {
    dispatch(actions.updateUserPermissionsError(err))
    return Promise.reject(err)
  }
}

export const updateCurrentUser: Function = ({
  email,
  name,
  surname,
}): Object => async (dispatch: Function): Promise<any> => {
  dispatch(actions.updateCurrentUserRequest())
  try {
    const response = await Api.request(`/users/me`, {
      method: 'PATCH',
      body: JSON.stringify({ email, name, surname }),
    })
    dispatch(actions.updateCurrentUserSuccess(response))
    return Promise.resolve(response)
  } catch (err) {
    const { message } = await err.json()
    dispatch(actions.updateCurrentUserError(message))
    return Promise.reject(message)
  }
}
