import { TablePagination } from '@material-ui/core'
import { TableBody } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Checkbox } from 'sputnik-ui'
import { Loader } from 'sputnik-ui'
import { TableCell } from 'sputnik-ui'
import { TableRow } from 'sputnik-ui'
import { Table } from 'sputnik-ui'
import {
  addressesActions,
  fetchAddresses,
  getAddressesList,
  getAddressesMeta,
  getAddressesPending,
  getAddressesSelected,
} from 'store/addresses'

export const AddressesList = () => {
  const selectedAddresses = useSelector(getAddressesSelected)
  const addressesPending = useSelector(getAddressesPending)
  const addresses = useSelector(getAddressesList)
  const addressesMeta = useSelector(getAddressesMeta)
  const { selectAddress } = addressesActions
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)

  const handleSelectAddress = useCallback(
    (addresses) => {
      dispatch(selectAddress(addresses))
    },
    [dispatch, selectAddress]
  )

  useEffect(() => {
    if (page < 1) setPage(1)
    dispatch(fetchAddresses({ page, limit }))
  }, [dispatch, page, limit])

  if (addressesPending && addresses.length < 1) {
    return <Loader />
  }

  return (
    <div>
      <Table>
        <TableBody>
          {addresses ? (
            addresses?.map((address) => {
              return (
                <TableRow key={address.uuid}>
                  <TableCell>
                    <Checkbox
                      checked={selectedAddresses?.find(
                        (selected) => selected.uuid === address.uuid
                      )}
                      onChange={(e) => handleSelectAddress(address)}
                    />
                    {address.address}
                  </TableCell>
                </TableRow>
              )
            })
          ) : (
            <TableRow>Тут ничего нет</TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={addressesMeta?.total}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 20]}
        page={page}
        labelRowsPerPage="Строк на странице:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
        onChangePage={(e, page) => {
          setPage(page)
        }}
        onChangeRowsPerPage={(e) => {
          setLimit(e.target.value)
        }}
        style={{ background: 'white' }}
      />
    </div>
  )
}
