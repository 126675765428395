import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'sputnik-ui'
import { CompanyPermissionsCard } from 'components/organisms/CompanyPermissionsCard/CompanyPermissionsCard'
import { getAppAbilitiesList, getPermissionsList, permissionsActions } from 'store/permissions'

export const PermissionsForm = () => {
  const appAbilities = useSelector(getAppAbilitiesList)
  const permissions = useSelector(getPermissionsList)
  const { setPermissionsList } = permissionsActions
  const dispatch = useDispatch()

  const handleSelect = (key) => (newActions) => {
    const oldPermissions = permissions

    const newActionValues = newActions ? newActions.map((a) => a.value) : []
    let nextPermissions = {
      ...oldPermissions,
      [key]: [...newActionValues],
    }

    dispatch(setPermissionsList(nextPermissions))
  }

  if (!appAbilities) {
    return <Loader msg="Загружаем список приложений..." error={'dsad'} fullPage />
  }

  return (
    <div>
      <CompanyPermissionsCard
        appAbilities={appAbilities}
        permissions={permissions}
        handleSelect={handleSelect}
      />
    </div>
  )
}
