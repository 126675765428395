/* @flow */
import * as types from './types'
import type { Action } from 'globalTypes'

export const signIn = (user: Object): Action => ({
  type: types.SIGN_IN,
  payload: { user },
})
export const signInError = (): Action => ({
  type: types.SIGN_IN_ERROR,
  payload: {},
})
export const checkAuth = (): Action => ({
  type: types.CHECK_AUTH,
  payload: {},
})

export const logOutRequest = (): Action => ({
  type: types.LOG_OUT_REQUEST,
  payload: {},
})
export const logOutSuccess = (): Action => ({
  type: types.LOG_OUT_SUCCESS,
  payload: {},
})
export const logOutError = (): Action => ({
  type: types.LOG_OUT_ERROR,
  payload: {},
})

export const fetchUserCompanyRequest = (): Action => ({
  type: types.FETCH_USER_COMPANY_REQUEST,
  payload: {},
})
export const fetchUserCompanySuccess = (company: Object): Action => ({
  type: types.FETCH_USER_COMPANY_SUCCESS,
  payload: { company },
})
export const fetchUserCompanyError = (): Action => ({
  type: types.FETCH_USER_COMPANY_FAILURE,
  payload: {},
})

export const fetchPartnerAcceptSuccess = (status) => ({
  //ok
  type: types.FETCH_PARTNER_ACCEPT,
  payload: { status },
})
export const fetchPartnerAcceptFailure = (error) => ({
  type: types.FETCH_PARTNER_ACCEPT_FAILURE,
  payload: { error },
})
export const fetchPartnerAccept = () => ({
  type: types.FETCH_PARTNER_ACCEPT,
  payload: {},
})

export const fetchPartnerStatistic = () => ({
  type: types.FETCH_PARTNER_STATISTIC,
})

export const fetchPartnerStatisticSuccess = (statistic) => ({
  type: types.FETCH_PARTNER_STATISTIC_SUCCESS,
  payload: statistic,
})
export const fetchPartnerStatisticFailure = (error) => ({
  type: types.FETCH_PARTNER_STATISTIC_FAILURE,
  payload: { error },
})

export const acceptLicense = () => ({
  type: types.ACCEPT_LICENSE,
})

export const acceptLicenseSuccess = (msg) => ({
  type: types.ACCEPT_LICENSE_SUCCESS,
  payload: { msg },
})

export const acceptLicenseFailure = (error) => ({
  type: types.ACCEPT_LICENSE_FAILURE,
  payload: { error },
})
