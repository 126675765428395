//@flow
import * as React from 'react'
import styled, { css } from 'styled-components'
import { Typography } from '@material-ui/core'

const ifSelected = css`
  /* transform: scale(1.04); */
  /* box-shadow: 0 2px 30px 0px ${p => p.theme.palette.secondary[100]}; */
  color: ${p => p.theme.palette.primary.contrastText};
  background: ${p => p.theme.palette.primary[500]};
  transform:none;
 &:hover {
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.07); */
    cursor: default;
    transform: none;
  }
`

const DepositOptionWrapper = styled.div`
  padding: 0.625rem;
  border-radius: 8px;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.07); */
  box-shadow: ${p => p.theme.shadows.main};
  display: flex;
  align-items: center;
  transition: 200ms all cubic-bezier(0.39, -0.79, 0.41, 2.85);

  & > svg {
    margin-right: 8px;
  }

  &:hover {
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.07); */
    cursor: pointer;
    transform: translateX(0.325rem);
  }

  ${p => p.selected && ifSelected};
`

const DepositOption = ({
  children,
  icon,
  selected,
  onClick,
  ...props
}: Props) => {
  return (
    <DepositOptionWrapper selected={selected} onClick={onClick}>
      {icon}
      <Typography color="inherit">{children}</Typography>
    </DepositOptionWrapper>
  )
}

type Props = {
  children: any,
  icon: React.Node,
  selected: boolean,
  onClick: Function,
}

export default DepositOption
