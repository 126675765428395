import React, { Component } from 'react'
import { UserCard, UserCardActions } from 'components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Loader } from 'sputnik-ui'
import type { State, Props } from './types'
import styles from './styles'
import { withStyles } from '@material-ui/core/styles'

import {
  getCurrentUser,
  getUserPermissions,
  updateUserPermissions,
  deleteUser,
  updateUser,
} from 'store/users/operations'
import { getUserApps, getAppAbilities } from 'store/apps/operations'

class UserPage extends Component<Props, State> {
  state = {
    appAbilities: null,
    permissions: null,
    deleteUserModal: false,
    addAppModal: false,
    editCompanyModal: false,
    snackbarSuccess: false,
    snackbarError: false,
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    const { dispatch, match } = this.props
    const { id } = match.params

    dispatch(getCurrentUser(id)).catch(err =>
      setTimeout(() => {
        this.props.history.push('/')
      }, 1000),
    )
    const apps = await dispatch(getUserApps(id))

    // bunch apps and their abilities together
    const appAbilities = await Promise.all(
      apps.map(async app => {
        const abilities = await dispatch(getAppAbilities(app.app_id))
        return { app, abilities }
      }),
    )

    /* get permissions, and change their structure */
    const permissions = await dispatch(getUserPermissions(id)).then(
      permissions =>
        permissions.reduce(
          (acc, permission) => ({
            ...acc,
            [permission.app_id]: permission.abilities
              ? Object.values(permission.abilities).reduce(
                  (abilities, ability) => ({
                    ...abilities,
                    [`${ability.namespace ? ability.namespace + '/' : ''}${
                      ability.name
                    }`]: ability.privileges.map(x => x.action),
                  }),
                  {},
                )
              : [],
          }),
          {},
        ),
    )

    this.setState({ appAbilities, permissions })
  }

  handleSelect = (appId, ability) => newActions => {
    const { permissions: oldPermissions } = this.state
    const newActionValues = newActions ? newActions.map(a => a.value) : []
    console.log(newActionValues)
    // this.state.appAbilities
    const permissions = {
      ...oldPermissions,
      [appId]: {
        //$FlowFixMe
        ...oldPermissions[appId],
        [`${ability.namespace ? ability.namespace + '/' : ''}${
          ability.name
        }`]: newActionValues,
      },
    }
    this.setState({ permissions })
  }

  updateUserPermissions = async () => {
    const { permissions } = this.state
    const { match, dispatch } = this.props

    try {
      await Promise.resolve(
        // for each client
        await Object.entries(permissions).forEach(
          async ([appId, abilities]) =>
            await dispatch(
              updateUserPermissions({
                userId: match.params.id,
                appId,
                abilities,
              }),
            ),
        ),
      )
      this.toggleModal('snackbarSuccess')()
      this.init()
    } catch (error) {
      this.toggleModal('snackbarError')()
    }
  }

  toggleModal = modalName => () =>
    this.setState({ [modalName]: !this.state[modalName] })

  deleteUser = () => {
    this.props
      .dispatch(deleteUser(this.props.match.params.id))
      .then(() => this.props.history.push('/'))
      .catch(this.toggleModal('snackbarError'))
  }

  toggleUserBlock = () => {
    if (this.props.currentUser) {
      this.props
        .dispatch(
          updateUser({
            userId: this.props.match.params.id,
            is_blocked: !this.props.currentUser.is_blocked,
          }),
        )
        .then(() => this.init())
    }
  }

  render() {
    const {
      classes,
      currentUser,
      usersError,
      appsError,
      authorizedUser,
      history,
    } = this.props
    const { appAbilities, permissions } = this.state

    if (!currentUser) {
      return (
        <Loader
          msg="Загружаем информацию о пользователе..."
          error={usersError}
          fullPage
        />
      )
    }

    if (!appAbilities || !permissions) {
      return (
        <Loader
          msg="Загружаем список приложений..."
          error={appsError}
          fullPage
        />
      )
    }

    return (
      <UserCard
        classes={classes}
        currentUser={currentUser}
        appAbilities={appAbilities}
        permissions={permissions}
        handleSelect={this.handleSelect}
        updateUserPermissions={this.updateUserPermissions}
        history={history}
        actions={
          <UserCardActions
            toggleModal={this.toggleModal}
            currentUser={currentUser}
            classes={classes}
            toggleUserBlock={this.toggleUserBlock}
            authorizedUser={authorizedUser}
          />
        }
      />
    )
  }
}

const mapStateToProps = ({ users, apps, auth: { user } }) => ({
  currentUser: users.currentUser,
  userApps: apps.userApps,
  usersError: users.error,
  appsError: apps.error,
  authorizedUser: user,
})

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(UserPage)),
)

/* <EditCompanyModal
          open={this.state.editCompanyModal}
          handleClose={this.toggleModal('editCompanyModal')}
          onConfirm={this.deleteUser}
        />
        <DeleteUserAlert
          open={this.state.deleteUserModal}
          handleClose={this.toggleModal('deleteUserModal')}
          onConfirm={this.deleteUser}
        />
        <AddAppModal
          open={this.state.addAppModal}
          handleClose={() => {
            this.init()
            this.toggleModal('addAppModal')()
          }}
        /> */
