//@flow

import * as types from './types'
import type { Action, Company } from 'globalTypes'

type State = {
  +isLoggedIn: boolean,
  +error: ?string,
  +company: ?Company,
  +user: ?Object,
}

const initialState: State = {
  isLoggedIn: false,
  error: null,
  msg: null,
  company: null,
  user: null,
  partnerProgramStatistic: null,
}

export default function auth(state: State = initialState, { type, payload }: Action) {
  switch (type) {
    case types.SIGN_IN:
      return { ...state, isLoggedIn: true, error: null, user: payload.user }
    case types.SIGN_IN_ERROR:
      return { ...state, isLoggedIn: false }
    case types.LOG_OUT_SUCCESS:
      return { ...state, isLoggedIn: false, error: null, user: null }
    case types.FETCH_USER_COMPANY_SUCCESS:
      return { ...state, company: payload.company }

    case types.ACCEPT_LICENSE_SUCCESS: {
      return { ...state, msg: payload.msg }
    }
    case types.ACCEPT_LICENSE_FAILURE: {
      return { ...state, error: payload.error }
    }

    case types.FETCH_PARTNER_STATISTIC_SUCCESS:
      return {
        ...state,
        partnerProgramStatistic: {
          amountByMonths: payload.amount_by_months,
          medianActiveFlats: payload.median_active_flats,
        },
      }
    case 'ui/clear':
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
