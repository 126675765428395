import React, { useEffect, useState, useCallback } from 'react'
import { List, Typography, IconButton, ListItem } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { Search, Container, useInput, Select } from 'sputnik-ui'
import Skeleton from 'react-loading-skeleton'

import Device from './Device'
import DevicesToolbar from './DevicesToolbar'
import DevicesBottombar from './DevicesBottombar'
import FiltersWrapper from 'components/atoms/FiltersWrapper'
import FilterButton from 'components/atoms/FilterButton'
// import Select from 'components/atoms/Select'
import DevicePlaceholder from './DevicePlaceholder'

import { fetchDevices, clearDevices, setSearch } from 'store/devicesSlice'
import { fetchLocalities, setLocalityFilter } from 'store/localitiesSlice'
import {
  fetchServicePartners,
  setServicePartnerFilter,
} from 'store/servicePartnersSlice'
import ReassignIntercomModal from 'components/organisms/ReassignIntercomModal'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDebouncedCallback } from 'use-debounce'

const StyledSelectContainer = styled.div`
  width: 250px;
  @media screen and (max-width: ${(p) => p.theme.sizes.phone}) {
    width: 100%;
  }
`

export default function DevicesList() {
  const dispatch = useDispatch()
  const { limit, page, search, total, devices } = useSelector(
    ({ devices }) => ({
      devices: devices.list,
      limit: devices.limit,
      page: devices.page,
      search: devices.search,
      total: devices.total,
    }),
  )
  const { t } = useTranslation('device')
  const localities = useSelector(({ localities }) => localities.list)
  const localityFilter = useSelector(({ localities }) => localities.filter)

  const servicePartners = useSelector(
    ({ servicePartners }) => servicePartners.list,
  )
  const servicePartnerFilter = useSelector(
    ({ servicePartners }) => servicePartners.filter,
  )

  const { bind, value } = useInput(search)
  useEffect(() => {
    dispatch(setSearch(value))
  }, [value])

  const fetchDevicesDebounce = useDebouncedCallback(
    () =>
      dispatch(
        fetchDevices({
          page,
          limit,
          locality_uuid: localityFilter.value,
          service_partner_uuid: servicePartnerFilter.value,
          search,
        }),
      ),
    250,
  )

  useEffect(() => {
    fetchDevicesDebounce()
    return () => dispatch(clearDevices())
  }, [
    page,
    limit,
    search,
    localityFilter.value,
    servicePartnerFilter.value,
    dispatch,
    fetchDevicesDebounce,
  ])

  useEffect(() => {
    dispatch(fetchLocalities())
    dispatch(fetchServicePartners())
  }, [devices])

  const [reassignModal, setReassignModal] = useState(false)
  const toggleReassignModal = () => setReassignModal(!reassignModal)

  return (
    <>
      <Container>
        <Search {...bind} placeholder={t('device:search')} />
        <FiltersWrapper>
          <StyledSelectContainer>
            <Select
              options={localities}
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              cacheOptions
              defaultOptions
              value={localityFilter}
              onChange={(o) => {
                dispatch(setLocalityFilter(o))
              }}></Select>
          </StyledSelectContainer>
          <StyledSelectContainer>
            <Select
              style={{minWidth:'300px'}}
              options={servicePartners}
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              cacheOptions
              defaultOptions
              value={servicePartnerFilter}
              onChange={(o) => {
                dispatch(setServicePartnerFilter(o))
              }}></Select>
          </StyledSelectContainer>
          {/* 
          <FilterButton>Камеры</FilterButton>
          <FilterButton>Домофоны</FilterButton> */}
          {/* <Typography color="textSecondary" style={{ alignSelf: 'center' }}>
            Вы можете создать свою группировку, выбрав устройства и объединив их
            в группу.
          </Typography> */}
        </FiltersWrapper>
      </Container>
      <DevicesToolbar toggleReassignModal={toggleReassignModal} />
      <Container>
        <List>
          {devices
            ? devices.map((d, idx) => <Device {...d} key={d.uuid}></Device>)
            : [...Array(limit).keys()].map(() => <DevicePlaceholder />)}
        </List>
      </Container>
      <DevicesBottombar />
      <ReassignIntercomModal
        open={reassignModal}
        onClose={toggleReassignModal}
      />
    </>
  )
}
