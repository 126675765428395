import styled from 'styled-components'
import { Checkbox } from 'sputnik-ui'
import { Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { fetchCustomizationAddresses } from './customizationSlice'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const FilterBlock = styled.div`
  position: absolute;
  top: 120%;
  right: 0%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  width: 227px;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  z-index: 1;
`
const Row = styled.div`
  display: flex;
  align-items: center;
`

function CustomizationFilterBlock({
  isOpen,
  addressParams,
  handleChangeAddressParams,
}) {
  const { t } = useTranslation('customization')
  const didMountRef = useRef(false)
  const [withPatterns, setWithPatterns] = useState(false)
  const [withoutPatterns, setWithoutPatterns] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (didMountRef.current) {
      let status
      if (withPatterns && withoutPatterns) {
        status = null
      } else if (withPatterns) {
        status = true
      } else if (withoutPatterns) {
        status = false
      } else {
        status = null
      }
      dispatch(
        fetchCustomizationAddresses({
          ...addressParams,
          with_customization: status,
        })
      )
      handleChangeAddressParams({
        ...addressParams,
        with_customization: status,
      })
    } else {
      didMountRef.current = true
    }
  }, [withPatterns, withoutPatterns])

  return (
    <>
      {isOpen && (
        <FilterBlock>
          <Row>
            <Checkbox
              color="blue"
              checked={withPatterns}
              onChange={() => {
                setWithPatterns(!withPatterns)
              }}
            />
            <Typography>{t('customization:filter.with_templates')}</Typography>
          </Row>
          <Row>
            <Checkbox
              color="blue"
              checked={withoutPatterns}
              onChange={() => {
                setWithoutPatterns(!withoutPatterns)
              }}
            />
            <Typography>
              {t('customization:filter.without_templates')}
            </Typography>
          </Row>
        </FilterBlock>
      )}
    </>
  )
}

export default CustomizationFilterBlock
