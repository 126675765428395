import { FormControlLabel } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Checkbox,
  FormModal,
  FormModalActions,
  FormModalContent,
  FormModalTitle,
  Typography,
} from 'sputnik-ui'
import { acceptLicense } from 'store/auth/operations'

export const AcceptLicenseModal = () => {
  const licenseStatus = useSelector(({ auth }) => auth.company?.license_agreement_accepted)
  const [isAccepted, setAccepted] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const acceptToggle = () => {
    setAccepted((prev) => !prev)
  }

  const handleAccept = () => {
    dispatch(acceptLicense()).then(handleCloseModal)
  }

  useEffect(() => {
    if (!licenseStatus && licenseStatus !== undefined) {
      handleOpenModal()
    }
  }, [licenseStatus])

  return (
    <FormModal open={isModalOpen} onClose={handleCloseModal}>
      <FormModalTitle>Обратите внимание</FormModalTitle>
      <FormModalContent>
        <Typography variant="body" color="textSecondary">
          Мы обновили текст лицензионного соглашения.
        </Typography>
        <Typography variant="body" color="textSecondary">
          Вы можете ознакомиться с документом по{' '}
          <Link
            to={{
              pathname: 'https://sso.sputnik.systems/license.pdf',
            }}
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <Typography
              variant="body2"
              color="textPrimary"
              component="span"
              style={{ cursor: 'pointer' }}
            >
              ссылке.
            </Typography>
          </Link>
        </Typography>
        <Typography variant="body" color="textSecondary">
          Продолжая работу на платформе, вы подтверждаете своё согласие с ним
        </Typography>
        <FormControlLabel
          style={{ marginTop: 24 }}
          label={
            <Typography variant="body" color="textSecondary">
              Прочитано, подтверждаю
            </Typography>
          }
          value={isAccepted}
          onChange={acceptToggle}
          control={
            <Checkbox
              color="blue"
              checked={isAccepted}
              style={{
                paddingTop: 0,
                paddingBottom: 0,
              }}
            />
          }
        />
      </FormModalContent>
      <FormModalActions>
        <Button variant="v2" primary fullWidth disabled={!isAccepted} onClick={handleAccept}>
          Продолжить
        </Button>
        <Button variant="v2" tertiary onClick={handleCloseModal}>
          Не сейчас
        </Button>
      </FormModalActions>
    </FormModal>
  )
}
