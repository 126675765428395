import { ListItemIcon } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import React from 'react'
import { ListItemText } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AttachMoney } from '@material-ui/icons'

import { ReactComponent as FaceRec } from 'features/Services/face_rec.svg'
import { ReactComponent as Refund } from 'features/Services/refund.svg'
import { ReactComponent as Debit } from 'features/Services/debit_bonus.svg'
import { ReactComponent as AccrualBonus } from 'features/Services/accrual_bonus.svg'
import { ReactComponent as WithdrawalMoney } from 'features/Services/withdrawal_money.svg'

import { Archive3, Archive5, Archive7, Archive14, Archive30, IconColors } from 'sputnik-icons'

const Price = styled(Typography)`
  && {
    color: ${(p) => (p.negative ? p.theme.palette.error[500] : p.theme.palette.success[500])};
  }
`

const StyledListItemText = styled(ListItemText)`
  && {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: ${(p) => p.theme.sizes.phone}) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const icon = { width: 24, height: 24 }

export default function Transaction({
  title,
  identifier,
  total_cost,
  type,
  service_cost,
  services_count,
  ...props
}) {
  const serviceIcons = {
    archive_3: <Archive3 color={IconColors.blue} />,
    archive_5: <Archive5 color={IconColors.blue} />,
    archive_7: <Archive7 color={IconColors.blue} />,
    archive_14: <Archive14 color={IconColors.blue} />,
    archive_30: <Archive30 color={IconColors.blue} />,
    face_rec: <FaceRec style={icon} />,
    refund: <Refund style={icon} />,
    debit_bonus: <Debit style={icon} />,
    accrual_bonus: <AccrualBonus style={icon} />,
    withdrawal_money: <WithdrawalMoney style={icon} />,
  }

  const { t } = useTranslation(['billing', 'products'])
  const IconComponent = serviceIcons[identifier] || <AttachMoney style={icon} />

  return (
    <ListItem>
      <ListItemIcon style={{ minWidth: 48 }}>{IconComponent}</ListItemIcon>
      <StyledListItemText
        primary={
          <ContentContainer>
            <span>{title}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{t(`billing:transaction.${type}`)}</span>
          </ContentContainer>
        }
        secondary={
          <ContentContainer>
            <Price negative={type === 'payment'} style={{ fontSize: 'inherit' }}>
              {total_cost}
            </Price>

            <span style={{ whiteSpace: 'nowrap' }}>
              {service_cost} x {services_count || 1}{' '}
              {t('products:units.device', { count: services_count })}
            </span>
          </ContentContainer>
        }
        primaryTypographyProps={{
          style: { fontSize: '1.2rem' },
        }}
        // disableTypography
      />
    </ListItem>
  )
}
