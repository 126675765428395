import * as types from './types'

export const fetchServicesRequest = () => ({
  type: types.FETCH_SERVICES_REQUEST,
})
export const fetchServicesSuccess = services => ({
  type: types.FETCH_SERVICES_SUCCESS,
  payload: {
    services,
  },
})
export const fetchServicesFailure = error => ({
  type: types.FETCH_SERVICES_FAILURE,
  payload: {
    error,
  },
})

export const fetchPackagesRequest = () => ({
  type: types.FETCH_PACKAGES_REQUEST,
})
export const fetchPackagesSuccess = packages => ({
  type: types.FETCH_PACKAGES_SUCCESS,
  payload: {
    packages,
  },
})
export const fetchPackagesFailure = error => ({
  type: types.FETCH_PACKAGES_FAILURE,
  payload: {
    error,
  },
})

export const fetchServiceRequest = () => ({
  type: types.FETCH_SERVICE_REQUEST,
})
export const fetchServiceSuccess = service => ({
  type: types.FETCH_SERVICE_SUCCESS,
  payload: {
    service,
  },
})
export const fetchServiceFailure = error => ({
  type: types.FETCH_SERVICE_FAILURE,
  payload: {
    error,
  },
})

export const fetchPackageRequest = () => ({
  type: types.FETCH_PACKAGE_REQUEST,
})
export const fetchPackageSuccess = currentPackage => ({
  type: types.FETCH_PACKAGE_SUCCESS,
  payload: {
    currentPackage,
  },
})
export const fetchPackageFailure = error => ({
  type: types.FETCH_PACKAGE_FAILURE,
  payload: {
    error,
  },
})

export const activateServiceRequest = () => ({
  type: types.ACTIVATE_SERVICE_REQUEST,
})
export const activateServiceSuccess = msg => ({
  type: types.ACTIVATE_SERVICE_SUCCESS,
  payload: {
    msg,
  },
})
export const activateServiceFailure = error => ({
  type: types.ACTIVATE_SERVICE_FAILURE,
  payload: {
    error,
  },
})

export const deactivateServiceRequest = () => ({
  type: types.DEACTIVATE_SERVICE_REQUEST,
})
export const deactivateServiceSuccess = msg => ({
  type: types.DEACTIVATE_SERVICE_SUCCESS,
  payload: {
    msg,
  },
})
export const deactivateServiceFailure = error => ({
  type: types.DEACTIVATE_SERVICE_FAILURE,
  payload: {
    error,
  },
})

export const activatePackageRequest = () => ({
  type: types.ACTIVATE_PACKAGE_REQUEST,
})
export const activatePackageSuccess = msg => ({
  type: types.ACTIVATE_PACKAGE_SUCCESS,
  payload: {
    msg,
  },
})
export const activatePackageFailure = error => ({
  type: types.ACTIVATE_PACKAGE_FAILURE,
  payload: {
    error,
  },
})

export const deactivatePackageRequest = () => ({
  type: types.DEACTIVATE_PACKAGE_REQUEST,
})
export const deactivatePackageSuccess = msg => ({
  type: types.DEACTIVATE_PACKAGE_SUCCESS,
  payload: {
    msg,
  },
})
export const deactivatePackageFailure = error => ({
  type: types.DEACTIVATE_PACKAGE_FAILURE,
  payload: {
    error,
  },
})


export const clearServices = () => ({
  type: types.CLEAR_SERVICES,
  payload: {},
})
