import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  msg: null,
  error: null,
  info: null,
  updateAction: null,
}

// reducers
export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showMessage: (state, { payload }) => {
      state.msg = payload
    },
    showError: (state, { payload }) => {
      state.error = payload
    },
    showInfo: (state, { payload }) => {
      state.info = payload
    },
    clear: () => initialState,
    showUpdateMessage: (state, { payload: updateAction }) => {
      state.updateAction = updateAction
    },
  },
})

export const {
  showMessage,
  showError,
  showUpdateMessage,
  showInfo,
  clear,
} = uiSlice.actions

export default uiSlice.reducer
