import * as types from './types'

const initialState = {}

export default function intercom(state = initialState, { type, payload }) {
  switch (type) {
    default:
      return state
  }
}
