import Api from 'utils/api'
import * as actions from './actions'

export const buyProduct = (appId, amount) => async dispatch => {
  dispatch(actions.buyProductRequest())
  try {
    await Api.request(`/products/${appId}/buy?amount=${amount}`, {
      method: 'POST',
    })

    dispatch(actions.buyProductSuccess('Покупка была успешно совершена'))
    return Promise.resolve()
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.buyProductFailure(message))
    return Promise.reject()
  }
}
