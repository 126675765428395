//@flow
import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Map } from 'components'
import { Loader, Button, formatDeviceId } from 'sputnik-ui'
import { Typography, Tooltip } from '@material-ui/core'
import {
  FiberManualRecord,
  Gavel,
  SubdirectoryArrowRight,
  History,
} from '@material-ui/icons'
import type { IService } from 'globalTypes'
import { animated, useTransition, config } from 'react-spring'
import { IconButton } from '@material-ui/core'
import Link from 'components/atoms/Link'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Service from 'features/Services/Service'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
`

const ServicesWrapper = styled.div`
  /* height: 225px; */
  width: calc(100% + 16px);
  margin-top: 1rem;
  white-space: nowrap;
  padding: 8px;
  padding-bottom: 24px;
  padding-left: 16px;
  margin-left: -16px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(300px, min-content));
  align-items: start;
  overflow-x: auto;
  overflow-y: visible;

  /* & > div {
    margin-right: calc(1rem + 8px);
  } */
  &::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 2px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(192, 192, 192, 1);
    border-radius: 10px;
  }
`

type Device = {
  coordinates: ?{
    lat: string,
    lon: string,
  },
  serial_number: string,
  services_count: number,
  services: {
    active: IService[],
    inactive: IService[],
  },
  installed: boolean,
  is_online: boolean,
}

type Props = {
  device?: Device,
  theme: Object,
}



const DeviceDetailsContent = ({
  theme,
  toggleReassignModal,
  openHistory,
}: Props) => {
  const device = useSelector(({ devices }) => devices.current)
  const services = useSelector(({ devices }) => devices.currentServices)

  const mapZoomTransition = useTransition(Boolean(device), null, {
    from: {
      opacity: 0,
      transform: 'translate3d(0,-100px,0) scale3d(1.2, 1.2, 1.2)',
      sc: 1.2,
      translate: -100,
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0,0,0) scale3d(1,1,1)',
      sc: 1,
      translate: 0,
    },
    config: config.slow,
  })

  const { t } = useTranslation(['service'])

  if (!device) {
    return <Loader />
  }

  return (
    <Wrapper>
      {mapZoomTransition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              key={key}
              style={{
                opacity: props.opacity,
                transform: props.transform,
              }}
            >
              <Map style={styles.map} lat={device?.lat} lon={device?.lon} />
            </animated.div>
          ),
      )[0]}  

      <div style={styles.mainContent}>
        <Typography variant="h4">
          {device?.type === 'intercom'
            ? formatDeviceId(device.serial_number)
            : device?.serial_number}

          <Tooltip
            placement="bottom"
            title={`устройство ${device.is_online ? 'онлайн' : 'оффлайн'}`}
          >
            <FiberManualRecord
              style={{
                ...styles.icon,
                marginLeft: '.325rem',
                color: device.is_online
                  ? theme.palette.success['500']
                  : theme.palette.error['500'],
                animation: `fade-${
                  device.is_online ? 'green' : 'red'
                }-color 1s ease-out infinite alternate-reverse`,
              }}
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={`устройство ${
              device.installed ? 'установлено' : 'не установлено'
            }`}
          >
            <Gavel
              style={{
                ...styles.icon,
                color: device.is_online
                  ? theme.palette.success['500']
                  : theme.palette.error['500'],
              }}
            />
          </Tooltip>
        </Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '2rem',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          <Typography color="textSecondary">
            Обслуживается: {(device && device.service_partner) || '-'}
          </Typography>

          <Button
            rounded
            // style={{ marginLeft: 'auto' }}
            variant="text"
            color="primary"
            onClick={toggleReassignModal}
          >
            {t('service:actions.reassign')}
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            marginBottom: '2rem',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="h4" style={styles.categoryTitle}>
            Услуги
          </Typography>
          <Tooltip title={t('service:history')}>
            <IconButton style={{ marginLeft: '.625rem' }} onClick={openHistory}>
              <History />
            </IconButton>
          </Tooltip>
        </div>
        <ServicesWrapper style={{ marginBottom: '.625rem' }}>
          {services?.map((service) => (
            <Service {...service} key={service.name} />
          ))}
        </ServicesWrapper>

        {/* 
        {device.services_count !== 0 && (
          <>
            <Typography variant="h5" style={styles.categoryTitle}>
              Подключенные услуги ({device.services_count})
            </Typography>

            <ServicesWrapper style={{ marginBottom: '.625rem' }}>
              {services.active.map(service => (
                <Service
                  // data-aos="fade-left"
                  key={service.base_info.payment_name}
                  isConnected
                  {...service}
                />
              ))}
            </ServicesWrapper>
          </>
        )}

        {services.inactive.length > 0 && (
          <>
            <Typography variant="h5" style={styles.categoryTitle}>
              Дополнительные услуги
            </Typography>
            <ServicesWrapper>
              {services.inactive.map(service => (
                <Service key={service.base_info.payment_name} {...service} />
              ))}
            </ServicesWrapper>
          </>
        )} */}
      </div>
    </Wrapper>
  )
}

const styles = {
  icon: {
    height: '1.06rem',
    width: '1.06rem',
    marginRight: '.625rem',
  },
  mainContent: {
    padding: '2rem',
    paddingRight: 0,
  },
  offsetLeft: {
    marginLeft: '1.625rem',
  },
  map: {
    height: 300,
  },
  categoryTitle: {},
}

export default withTheme<*, *>(DeviceDetailsContent)
