import React from 'react'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Wrapper = styled.div`
  display: inline-flex;
`

const TextWrapper = styled.a`
  padding-left: 0.325rem;
  padding-right: 0.325rem;
  display: inline-flex;
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary['500']};

  ${p =>
    p.variant === 'skewed' &&
    css`
      background: ${({ theme }) => theme.palette.secondary['100']};
      color: ${({ theme }) => theme.palette.secondary['700']};

      font-weight: 500;
      padding-left: 0.325rem;
      padding-right: 0.325rem;
    `};

  ${p =>
    p.variant === 'arrow' &&
    css`
      background: ${p =>
        (p.color && p.theme.palette[p.color]['500']) ||
        p.theme.palette.primary['500']};
      color: ${p => p.textColor};

      &::after {
        content: ' ';
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
        border-bottom: 1rem solid transparent;
        position: absolute;
        right: -1rem;
        border-left: 1rem solid
          ${p =>
            (p.color && p.theme.palette[p.color]['500']) ||
            p.theme.palette.primary['500']};
      }
    `};

  ${p =>
    p.href &&
    css`
      cursor:pointer;
      &:hover {
        background: ${p =>
          (p.color && p.theme.palette[p.color]['A700']) ||
          p.theme.palette.primary['A700']};
        /* color: ${p => p.textColor}; */
        
        &::after {
          border-left-color: ${p =>
            (p.color && p.theme.palette[p.color]['A700']) ||
            p.theme.palette.primary['A700']};
        }
      }
    `};
`

const Headline = ({
  children,
  variant = 'arrow',
  fontSize,
  color,
  textColor = 'white',
  href,
  ...props
}) => (
  <Wrapper {...props}>
    <TextWrapper
      color={color}
      textColor={textColor}
      href={href}
      variant={variant}
      target="_blank"
    >
      <Typography
        color="inherit"
        variant="h5"
        style={{ fontSize, fontWeight: 500 }}
      >
        {children}
      </Typography>
    </TextWrapper>
  </Wrapper>
)

export default Headline
