import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 10px 0;
`
const Content = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${(p) => p.theme.sizes.phone}) {
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
`
const Image = styled.img`
  height: 44px;
  width: 44px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-right: 15px;
`
const Column = styled.div`
  margin-right: 74px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    margin-bottom: 10px;
  }
`
const TypographyBold = styled(Typography)`
  && {
    font-weight: 600;
  }
`
const Title = styled(Typography)`
  && {
    font-size: 14px;
    margin-bottom: 1rem;
  }
`
const Website = styled.a`
  text-decoration: none;
  color: #0ea7ff;
  font-weight: 500;
  line-height: 1.5;
`
const Price = styled(Typography)`
  && {
    margin-left: auto;
    font-size: 1.5rem;
  }
`
const Line = styled.hr`
  margin: 0;
  margin-top: 10px;
  padding: 0;
  border: 1px solid #ededed;
`

function ViewItem({ credit, index, service }) {
  const { title, phone, img, website, description, link, price } = credit
  const { t } = useTranslation('customization')
  return (
    <Wrapper>
      <Title>
        {service
          ? t('customization:card.service')
          : t('customization:card.company')}{' '}
        #{index + 1}
      </Title>
      <Content>
        <Image src={img} />
        <Column>
          <TypographyBold>{title}</TypographyBold>
          <Website href={link || website} target="_blank">
            {link || website}
          </Website>
        </Column>
        <Column>
          <TypographyBold>
            {service ? t('customization:card.about_company') : phone}
          </TypographyBold>
          <Typography>{description}</Typography>
        </Column>
        {service && <Price>{price} ₽</Price>}
      </Content>
      <Line />
    </Wrapper>
  )
}

export default ViewItem
