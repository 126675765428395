import * as types from './types'

export const buyProductRequest = () => ({
  type: types.BUY_PRODUCT_REQUEST,
})
export const buyProductSuccess = msg => ({
  type: types.BUY_PRODUCT_SUCCESS,
  payload: { msg },
})
export const buyProductFailure = error => ({
  type: types.BUY_PRODUCT_FAILURE,
  payload: {
    error,
  },
})
