import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import MoneyCard from './MoneyCard'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, auto));
  grid-gap: 3rem;
  row-gap: 3rem;
  align-items: center;
  justify-content: center;
`

function MoneyCardContainer({ amountByMonths }) {
  const [activeCard, setActiveCard] = useState(1)

  const isActive = (index) => {
    if (index === activeCard) {
      return 'active'
    }
  }

  const data = useMemo(() => {
    const sortedData = Object.entries(amountByMonths).sort(([a, ,], [b, ,]) => dayjs(a) - dayjs(b))
    let prevValue = 0
    return sortedData
      .map(([date, value], index) => {
        const isFirst = index === 0
        let points, status
        if (value === prevValue) {
          points = isFirst ? 100 : 0
          status = 0
        }
        if (value > prevValue) {
          points = isFirst ? 100 : Math.floor((value / prevValue) * 100) - 100
          status = 1
        }
        if (value < prevValue) {
          points = isFirst ? 100 : Math.floor((value / prevValue) * 100) - 100
          status = -1
        }
        prevValue = value
        if (points < 0) points = points * -1
        return { status, points, date, value, isStart: isFirst }
      })
      .slice(1, 4)
  }, [amountByMonths])

  return (
    <Container
      onMouseLeave={() => {
        setActiveCard(1)
      }}
    >
      {data.map(({ value, points, status, date, isStart }, index) => (
        <MoneyCard
          points={points}
          moneyCount={value}
          status={status}
          isStart={isStart}
          date={date}
          isActive={isActive}
          setActiveCard={setActiveCard}
          index={index}
        />
      ))}
    </Container>
  )
}

export default MoneyCardContainer
