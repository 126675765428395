import React, { useCallback, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Typography, withWidth } from '@material-ui/core'
import { NavigateNext } from '@material-ui/icons'
import { Button } from 'sputnik-ui'
import { Link } from 'react-router-dom'
import ym from 'react-yandex-metrika'
import { darkerColor } from 'utils/helpers'
import { useDispatch } from 'react-redux'

const COLORS = ['#eed7df', '#E8F5E9', '#bad8e0', '#fad6b4']
const MAX_HEIGHT = 700
const MARG_TOP = '2rem'

const ProductWrapper = styled.div`
  height: 100%;
  display: flex;
  width: calc(100% - 4rem - 48px - 24px);
  order: ${(p) => p.order};
  margin-right: 4rem;
  transition: opacity 600ms ease-in-out;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    width: 100%;
    flex-direction: column;
    /* height: 90%; */
    height: auto;
  }
`

const jump = keyframes`
  from {
    transform: translateX(0);
  } to {
    transform: translateX(5px);
  }
`

const jumpRotated = keyframes`
  from {
    transform: translateX(0) rotate(-180deg);
  } to {
    transform: translateX(5px) rotate(-180deg);
  }
`

const NextIcon = styled(NavigateNext)`
  animation: ${(p) => (p.isLast ? jumpRotated : jump)} 1s ease-out infinite
    alternate-reverse;
`

const ProductImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  background: ${(p) => {
    return COLORS[p.idx % COLORS.length] || COLORS[0]
  }};
  align-items: center;
  overflow: hidden;
  /* transition: all 600ms cubic-bezier(0.39, -0.79, 0.41, 2.85); */
  transition: height 600ms ease-in-out, margin 600ms ease-in-out,
    background-color 300ms ease-in-out;

  ${(p) =>
    !p.isCurrentItem &&
    css`
      height: calc(100% - 8rem);
      margin-top: 4rem;
      cursor: pointer;
      &:hover {
        background: ${(p) => {
          return darkerColor(COLORS[p.idx % COLORS.length] || COLORS[0], 0.05)
        }};
      }
    `};

  @media screen and (max-width: ${(p) => p.theme.sizes.screen}) {
    width: 100%;
  }
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    width: 100%;
    height: 250px;
    margin-top: 0;
    /* justify-content: start; */
    /* padding-left: 2rem; */
  }
`

const ProductImage = styled.img`
  height: calc(55% - ${MARG_TOP} * 2);
  z-index: 1;

  @media screen and (max-width: ${(p) => p.theme.sizes.screen}) {
    height: calc(100% - ${MARG_TOP} * 2);
  }
`

const ProductInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: visible;
  max-width: 500px;
  &::-webkit-scrollbar {
    display: none;
  }

  /* order: 1; */
  order: 1;
  padding-left: 4rem;
  padding-top: 4rem;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    /* margin-bottom: 4rem; */
    max-width: none;
    padding: 2rem;
  }
`

const ProductTitle = styled(Typography)`
  && {
    margin-top: ${MARG_TOP};
    font-weight: 300;
    font-size: 3rem;
    /* order: 2; */
    @media screen and (max-height: ${MAX_HEIGHT}px) {
      margin-top: 0;
    }
  }
`

const ProductDescription = styled(Typography).attrs((p) => ({
  color: 'textSecondary',
}))`
  && {
    margin-top: ${MARG_TOP};
    margin-bottom: 0.625rem;

    /* order: 3; */
  }
`

const ProductRow = styled.div`
  display: flex;
  align-items: center;
`

const ProductOrderSeparator = styled.div`
  height: 3px;
  width: 50%;
  margin: 0 1rem;
  background: #000;
`

const PaginationRow = styled(ProductRow)`
  @media screen and (max-height: ${MAX_HEIGHT}px) {
    order: 4;
    margin-top: ${MARG_TOP};
    display: none;
  }
`

const ActionsRow = styled(ProductRow)`
  justify-content: space-between;
  width: 100%;
  margin-top: ${MARG_TOP};
  margin-bottom: ${MARG_TOP};
  order: 4;

  @media screen and (max-height: ${MAX_HEIGHT}px) {
    order: 2;
  }
`

const Feature = styled(Typography).attrs((p) => ({
  color: 'textSecondary',
}))`
  position: relative;
  margin-left: 0.125rem;
`

type Props = {
  title: string,
  img: string,
}

const ProductCard = ({
  title,
  img,
  cost,
  order,
  productsNumber,
  calculator_link,
  currentPosition,
  match,
  idx,
  setCurrentPosition,
  width,
  description,
  features,
  setRef,
  ...props
}: Props) => {
  
  const isCurrentItem = idx === currentPosition
  const isMobile = width === 's' || width === 'xs'

  return (
    <ProductWrapper {...props} ref={setRef} order={order}>
      <ProductInfo idx={idx}>
        <PaginationRow>
          <Typography>
            {(idx + 1).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>{' '}
          <ProductOrderSeparator />
          <Typography>
            {productsNumber.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
        </PaginationRow>
        <ProductTitle variant="h4">{title}</ProductTitle>
        <ProductDescription>{description}</ProductDescription>

        {features && features.map((f) => <Feature key={f}>{f}</Feature>)}

        <ActionsRow>
          <Button variant="text" color="primary" size="small" disabled bold>
            демо
          </Button>
          <Button
            variant="text"
            size="small"
            color="primary"
            // disabled={!calculator_link}
            disabled
            // component="a"
            // href={calculator_link}
            // target="_blank"
            component={Link}
            to={`${match.url}/${currentPosition}/unit`}
            bold
          >
            unit-экономика
          </Button>
     
        </ActionsRow>
      </ProductInfo>

      <ProductImageWrapper
        productsNumber={productsNumber}
        currentPosition={currentPosition}
        idx={idx}
        isCurrentItem={isCurrentItem}
        onClick={() => {
          if (idx === 1) {
            ym('reachGoal', 'partner_products_cameraButtonClick')
          }
          setCurrentPosition(idx)
        }}
      >
        {!isCurrentItem && !isMobile && (
          <>
            <Typography
              style={{
                position: 'absolute',
                left: '-110px',
                transform: 'rotate(270deg)',
                fontWeight: 300,
              }}
              variant="h5"
            >
              {title}
            </Typography>
            <NextIcon
              isLast={props.isLast}
              style={{
                position: 'absolute',
                left: 44,
              }}
            />
          </>
        )}
        <ProductImage src={img} />
      </ProductImageWrapper>
    </ProductWrapper>
  )
}

export default withWidth()(ProductCard)
