//@flow
import * as types from './types'
import { clear } from 'features/ui/uiSlice'

type State = {
  +list: Object[],
  +packages: Object[],
  +currentService: ?Object,
  +currentPackage: ?Object,
  +error: ?string,
  +msg: ?string,
  +loading: boolean,
}

type Action = {
  type: string,
  payload: Object,
}

const initialState: State = {
  list: [],
  packages: [],
  currentService: null,
  currentPackage: null,
  loading: false,
  error: null,
  msg: null,
}

export default function services(
  state: State = initialState,
  { type, payload }: Action
) {
  switch (type) {
    case types.FETCH_SERVICES_SUCCESS:
      return { ...state, list: payload.services }
    // case types.FETCH_SERVICES_FAILURE:
    //   return { ...state, error: payload.error }
    case types.ACTIVATE_SERVICE_REQUEST:
      return { ...state, loading: true }
    case types.ACTIVATE_SERVICE_SUCCESS:
      return { ...state, msg: payload.msg, loading: false }
    case types.ACTIVATE_SERVICE_FAILURE:
      return { ...state, error: payload.error, loading: false }
    case types.DEACTIVATE_SERVICE_REQUEST:
      return { ...state, loading: true }
    case types.DEACTIVATE_SERVICE_SUCCESS:
      return { ...state, msg: payload.msg, loading: false }
    case types.DEACTIVATE_SERVICE_FAILURE:
      return { ...state, error: payload.error, loading: false }
    case types.ACTIVATE_PACKAGE_REQUEST:
      return { ...state, loading: true }
    case types.ACTIVATE_PACKAGE_SUCCESS:
      return { ...state, msg: payload.msg, loading: false }
    case types.ACTIVATE_PACKAGE_FAILURE:
      return { ...state, error: payload.error, loading: false }
    case types.DEACTIVATE_PACKAGE_REQUEST:
      return { ...state, loading: true }
    case types.DEACTIVATE_PACKAGE_SUCCESS:
      return { ...state, msg: payload.msg, loading: false }
    case types.DEACTIVATE_PACKAGE_FAILURE:
      return { ...state, error: payload.error, loading: false }
    case types.FETCH_PACKAGES_SUCCESS:
      return { ...state, packages: payload.packages }
    // case types.FETCH_PACKAGES_FAILURE:
    //   return { ...state, error: payload.error }
    case types.FETCH_PACKAGE_SUCCESS:
      return { ...state, currentPackage: payload.currentPackage }
    // case types.FETCH_PACKAGE_FAILURE:
    //   return { ...state, error: payload.error }
    case types.FETCH_SERVICE_SUCCESS:
      return { ...state, currentService: payload.service }
    // case types.FETCH_SERVICE_FAILURE:
    //   return { ...state, error: payload.error }
    case types.CLEAR_SERVICES:
      return { ...state, currentService: null, currentPackage: null }
    case [clear.type]:
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
