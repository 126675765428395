import * as types from './types'

export const createConversationRequest = () => ({
  type: types.CREATE_CONVERSATION_REQUEST,
})
export const createConversationSuccess = () => ({
  type: types.CREATE_CONVERSATION_SUCCESS,
})
export const createConversationFailure = (error) => ({
  type: types.CREATE_CONVERSATION_FAILURE,
  payload: {
    error,
  },
})
