//@flow
import * as actions from './actions'
import Api from 'utils/api'
import type { App } from './types'
import config from 'config'

export const getApps: Function = (): Function => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getAppsRequest())
  try {
    const apps: App[] = await Api.request('/apps', {}, config.ssoUri)
    dispatch(actions.getAppsSuccess(apps))
  } catch (err) {
    dispatch(
      actions.getAppsError('Произошла ошибка при получении списка приложений.'),
    )
  }
}

export const getUserApps: Function = (id: number): Function => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getUserAppsRequest())
  try {
    const apps: App[] = await Api.request(
      `/users/${id}/apps`,
      {},
      config.ssoUri,
    )
    dispatch(actions.getUserAppsSuccess(apps))
    return Promise.resolve(apps)
  } catch (err) {
    dispatch(actions.getUserAppsError(err))
    return Promise.reject(err)
  }
}

export const getAppAbilities: Function = (appId: number): Function => async (
  dispatch: Function,
): Promise<any> => {
  dispatch(actions.getAppAbilitiesRequest())
  try {
    const abilities: Object[] = await Api.request(
      `/apps/${appId}/abilities`,
      {},
      config.ssoUri,
    )
    dispatch(actions.getAppAbilitiesSuccess(abilities))
    return Promise.resolve(abilities)
  } catch (err) {
    dispatch(
      actions.getAppAbilitiesError(
        'При получении списка возможностей приложения произошла ошибка. Проверьте свой уровень доступа.',
      ),
    ) // !
    return Promise.reject(err)
  }
}

export const getAbilityActions: Function = (
  abilityId: number,
): Function => async (dispatch: Function): Promise<any> => {
  dispatch(actions.getAbilityActionsRequest())
  try {
    const abilityActions: Object[] = await Api.request(
      `/actions?ability_id=${abilityId}`,
      {},
      config.ssoUri,
    )
    dispatch(actions.getAbilityActionsSuccess(abilityActions))
    return Promise.resolve(abilityActions)
  } catch (err) {
    dispatch(actions.getAbilityActionsError(err))
    return Promise.reject(err)
  }
}

// export const getAppsPermissions: Function = (
//   userId: number
// ): Function => async (dispatch: Function): Promise<any> => {
//   dispatch(actions.)
// }
