import * as types from './types'

export const fetchDevicesRequest = () => ({
  type: types.FETCH_DEVICES_REQUEST,
})
export const fetchDevicesSuccess = ({ devices, totalPages }) => ({
  type: types.FETCH_DEVICES_SUCCESS,
  payload: {
    devices,
    totalPages,
  },
})
export const fetchDevicesFailure = error => ({
  type: types.FETCH_DEVICES_FAILURE,
  payload: {
    error,
  },
})

export const fetchDeviceInfoRequest = () => ({
  type: types.FETCH_DEVICE_INFO_REQUEST,
})
export const fetchDeviceInfoSuccess = device => ({
  type: types.FETCH_DEVICE_INFO_SUCCESS,
  payload: {
    device,
  },
})
export const fetchDeviceInfoFailure = error => ({
  type: types.FETCH_DEVICE_INFO_FAILURE,
  payload: {
    error,
  },
})

export const clearCurrentDevice = () => ({
  type: types.CLEAR_CURRENT_DEVICE,
})

export const setDeviceSearchQuery = query => ({
  type: types.SET_DEVICE_SEARCH_QUERY,
  payload: {
    query,
  },
})
