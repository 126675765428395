//@flow
import * as actions from './actions'
import Api from 'utils/api'
import config from 'config'
import { buildUrl } from 'utils/helpers'
import { showError } from 'features/ui/uiSlice'

export const checkAuth =
  (ignoreSessionStorage: ?boolean) => async (dispatch: Function, getState: Function) => {
    dispatch(actions.checkAuth())
    // if (getState().auth.isLoggedIn && !ignoreSessionStorage) return
    try {
      const user: Object = await Api.request(
        `/users/me?app=${config.redirectUri}`,
        {},
        config.ssoUri
      )
      dispatch(actions.signIn(user))
    } catch (err) {
      const { redirect_to } = await err.json()
      if (redirect_to) {
        window.location = config.ssoUri + redirect_to
      }
      dispatch(actions.signInError())
    }
  }

export const logOut =
  () =>
  async (dispatch: Function): Promise<any> => {
    dispatch(actions.logOutRequest())
    try {
      const response = await Api.request('/logout', { method: 'DELETE' }, config.redirectUri)
      dispatch(actions.logOutSuccess())
      sessionStorage.clear()
      window.location.reload()
      return Promise.resolve(response)
    } catch (err) {
      dispatch(actions.logOutError())
      return Promise.reject(err)
    }
  }

export const fetchUserCompany = () => async (dispatch: Function) => {
  dispatch(actions.fetchUserCompanyRequest())
  try {
    const company: Object = await Api.request('/users/me/company')
    dispatch(actions.fetchUserCompanySuccess(company))
  } catch (err) {
    dispatch(actions.fetchUserCompanyError())
  }
}

export const acceptPartnerProgram = () => async (dispatch) => {
  dispatch(actions.fetchPartnerAccept())
  try {
    const partnerStatus = await Api.request(`/users/me/company`, {
      method: 'PATCH',
      body: JSON.stringify({ partners_program_accepted: true }),
    })
    dispatch(actions.fetchPartnerAcceptSuccess(true)) // then fix
    return Promise.resolve(partnerStatus)
  } catch (error) {
    dispatch(actions.fetchPartnerAcceptFailure(error))
    dispatch(showError('При принятии партнерской программы произошла ошибка'))
    return Promise.reject(error)
  }
}

export const fetchStatisticPartnerProgram = () => async (dispatch) => {
  dispatch(actions.fetchPartnerStatistic())
  try {
    const partnerStatus = await Api.request(
      buildUrl('/analytics/partner_program/statistic'),
      {
        dispatch,
      },
      `${config.redirectUri}/api/v2`
    )

    dispatch(actions.fetchPartnerStatisticSuccess(partnerStatus))
    return Promise.resolve(partnerStatus)
  } catch (error) {
    dispatch(actions.fetchPartnerStatisticFailure(error))
    throw new Error(error)
  }
}

export const acceptLicense = () => async (dispatch) => {
  dispatch(actions.acceptLicense())
  try {
    const partnerStatus = await Api.request(`/users/me/accept_license_agreement`, {
      method: 'PATCH',
    })
    dispatch(actions.acceptLicenseSuccess(partnerStatus?.message))
    return Promise.resolve(partnerStatus)
  } catch (err) {
    const { error } = err.json()
    dispatch(actions.acceptLicenseFailure(error))
    return Promise.reject(err)
  }
}
