import React, { useState, useEffect } from 'react'
import { Typography, TablePagination } from '@material-ui/core'
import { fetchTransactions } from 'store/billing/operations'
import dayjs from 'dayjs'
import { TransactionsTable } from 'components'
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'
import Transaction from 'features/billing/Transaction'


const StyledCollapse = styled(Collapse)`
  && {
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

const HistoryOption = styled.div`

`

/* <StyledCollapse in={isOpened} timeout="auto" unmountOnExit>
              <TransactionsTable transactions={transactions} />
            </StyledCollapse> 
            
            'type': 'payment' / 'deposit',
'identifier': ' archive_3' #optional,
'title': 'архивация глубиной 3 дня' / 'пополнение баланса',
'total_cost': '— 300,00 ₽' / '+ 6200,00 ₽',
'status': 'pending' / 'success' / 'error' #как посмотрю стейты обновить наверное придется,
'service_cost': '3,00₽' #optional,
'services_count: 100 #optional
            */

const Transactions = ({ operations, selectedYear, dispatch, error }) => {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)

  useEffect(() => {
    if (page < 0) setPage(0)
    dispatch(fetchTransactions(selectedYear, page, limit))
  }, [dispatch, selectedYear, page, limit])

  return operations?.operations ? (
    <List style={styles.list} disablePadding dense>
      {Object.entries(operations.operations).map(([month, transactions]) => {
        return (
          <HistoryOption key={month}>
            <Typography variant="h6">
              {dayjs(month, 'DD-MM-YYYY').calendar()}
            </Typography>
            {transactions.map((t) => (
              <Transaction {...t}></Transaction>
            ))}
          </HistoryOption>
        )
      })}
      <TablePagination
        component="div"
        count={operations?.meta?.total}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 20, 30]}
        page={page}
        labelRowsPerPage="Строк на странице:"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} из ${count}`
        }
        onChangePage={(e, page) => {
          setPage(page)
        }}
        onChangeRowsPerPage={(e) => {
          setLimit(e.target.value)
        }}
        style={{ background: 'white' }}
      />
    </List>
  ) : (
    <div style={styles.flex}>
      <div style={styles.skeleton}>
        <Skeleton height={48} count={2} />
      </div>
    </div>
  )
}

const styles = {
  skeleton: {
    width: '100%',
  },
  flex: {
    display: 'flex',
    justifyContent: 'center',
  },
  list: {
    width: '100%',
    overflow: 'hidden',
  },
  listItem: {
    cursor: 'pointer',
    padding: '0 -1rem',
  },
}

export default Transactions
