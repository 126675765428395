import React from 'react'
import { InputBase, InputAdornment } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import styled from 'styled-components'

const SearchField = styled(InputBase).attrs(p => ({
  type: 'search',
  inputProps: {
    style: { padding: 0 },
  },
  startAdornment: (
    <InputAdornment position="start">
      <Search />
    </InputAdornment>
  ),
}))`
  && {
    padding: ${p => p.theme.sizes.scale[6]};
    background: ${p => p.theme.palette.grayscale[2]};
    border-radius: 8px;
    transition-property: background-color, border-color;
    transition-duration: 0.25s;
    transition-timing-function: ease;

    &:hover,
    &:focus,
    &:active {
      background: ${p => p.theme.palette.grayscale[1]};
    }

    &:focus-within {
      background: ${p => p.theme.palette.secondary['50']};
    }
  }
`

// const Search = ({ ...props }) => (
// <SearchField placeholder="номер заявки" {...props} />
// )

export default SearchField
