//@flow
import * as actions from './actions'
import Api from 'utils/api'
import { checkAuth } from 'store/auth/actions'

export const fetchServices = () => async (dispatch: Function) => {
  dispatch(actions.fetchServicesRequest())
  try {
    const services: Object[] = await Api.request(`/services`)

    dispatch(actions.fetchServicesSuccess(services))
  } catch (error) {
    dispatch(
      actions.fetchServicesFailure(
        'При загрузке списка услуг произошла ошибка',
      ),
    )
  }
}

export const activatePackage = (appId: string) => async (
  dispatch: Function,
) => {
  dispatch(actions.activatePackageRequest())
  try {
    await Api.request(`/packages/${appId}/activate`, { method: 'POST' })

    const msg = `Пакет был успешно подключен`
    dispatch(actions.activatePackageSuccess(msg))
    dispatch(checkAuth())
    return Promise.resolve(msg)
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.activatePackageFailure(message))
    return Promise.reject(message)
  }
}

export const deactivatePackage = (appId: string) => async (
  dispatch: Function,
) => {
  dispatch(actions.deactivatePackageRequest())
  try {
    await Api.request(`/packages/${appId}/deactivate`, {
      method: 'POST',
    })

    const msg = `Пакет был успешно отключен`
    dispatch(actions.deactivatePackageSuccess(msg))
    dispatch(checkAuth())
    return Promise.resolve(msg)
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.deactivatePackageFailure(message))
    return Promise.reject()
  }
}

export const activateService = (appId: string, device?: string) => async (
  dispatch: Function,
) => {
  dispatch(actions.activateServiceRequest())
  try {
    if (device) {
      await Api.request(`/partners/devices/${device}/activate_service`, {
        method: 'POST',
        body: JSON.stringify({ app_id: appId }),
      })
    } else {
      await Api.request(`/services/${appId}/activate`, { method: 'POST' })
    }

    const msg = `Услуга был успешно подключена`
    dispatch(actions.activateServiceSuccess(msg))
    fetchServices()
    dispatch(checkAuth())
    return Promise.resolve(msg)
  } catch (error) {
    const { message } = await error.json()

    dispatch(actions.activateServiceFailure(message))
    return Promise.reject(message)
  }
}

export const deactivateService = (appId: string, device?: string) => async (
  dispatch: Function,
) => {
  dispatch(actions.deactivateServiceRequest())
  try {
    if (device) {
      await Api.request(`/partners/devices/${device}/deactivate_service`, {
        method: 'POST',
        body: JSON.stringify({ app_id: appId }),
      })
    } else {
      await Api.request(`/services/${appId}/deactivate`, {
        method: 'POST',
      })
    }

    const msg = `Услуга была успешно отключена`
    dispatch(actions.deactivateServiceSuccess(msg))
    fetchServices()
    dispatch(checkAuth())
    return Promise.resolve(msg)
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.deactivatePackageFailure(message))
    return Promise.reject(message)
  }
}

export const fetchPackages = () => async (dispatch: Function) => {
  dispatch(actions.fetchPackagesRequest())
  try {
    const packages: Object[] = await Api.request(`/packages`)
    dispatch(actions.fetchPackagesSuccess(packages))
  } catch (error) {
    dispatch(actions.fetchPackagesFailure(error))
  }
}

export const fetchService = (appId: string) => async (dispatch: Function) => {
  dispatch(actions.fetchServiceRequest())
  try {
    const service: Object = await Api.request(`/services/${appId}`)
    dispatch(actions.fetchServiceSuccess(service))
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.fetchServiceFailure(message))
  }
}

export const fetchPackage = (appId: string) => async (dispatch: Function) => {
  dispatch(actions.fetchPackageRequest())
  try {
    const currentPackage: Object = await Api.request(`/packages/${appId}`)
    dispatch(actions.fetchPackageSuccess(currentPackage))
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.fetchPackageFailure(message))
  }
}
