//@flow
import * as React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { AccountBalanceWallet } from '@material-ui/icons'
import { Button, Wrapper } from 'sputnik-ui'
import CreditCard from 'components/atoms/CreditCard'
import type { BillingAccount } from 'globalTypes'
import { useDispatch } from 'react-redux'
import { createConversation } from 'store/intercom/operations'
import { showInfo } from 'features/ui/uiSlice'
import { useTranslation } from 'react-i18next'

const BalanceWrapper = styled(Wrapper)`
  /* min-height: 200px; */
  flex-direction: column;
  flex-wrap: wrap;
`

const StyledMoney = styled(Typography)`
  && {
    display: inline;
    font-weight: 300;
  }
`

const formatDate = (date) => {
  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  const dateObj = new Date(date)
  const isToday = new Date().toDateString() === dateObj.toDateString()
  return isToday
    ? `сегодня, ${dateObj.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
      })}`
    : dateObj.toLocaleString(undefined, dateOptions)
}

const AccountBalance = ({
  billingAccount,
  ...props
}: {
  billingAccount: BillingAccount,
  onClick: Function,
}) => {
  const { t } = useTranslation(['messages'])
  const dispatch = useDispatch()
  const handleClick = React.useCallback(() => {
    // temporary solution
    dispatch(showInfo(t('messages:top_up_balance')))

    // code below maybe uncomment later

    // dispatch(
    //   createConversation(
    //     `Хочу пополнить счет личного кабинета, выставьте мне счет`,
    //   ),
    // ).then(() => window.Intercom('showMessages'))
  }, [dispatch])

  if (
    billingAccount.balance_with_currency === null ||
    billingAccount.balance_with_currency === undefined
  ) {
    return null
  }
  const [bigNums, smallNums] = billingAccount.balance_with_currency.split('.')

  return (
    <BalanceWrapper {...props} background="primary">
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}>
        <Typography variant="h5" style={{ marginRight: '0.625rem' }}>
          Баланс
        </Typography>
        <Button
          size="small"
          rounded
          primary
          onClick={handleClick}
          variant="contained">
          <AccountBalanceWallet
            style={{ marginRight: 8, width: '1.2rem', height: '1.2rem' }}
          />{' '}
          Пополнить
        </Button>
      </div>
      <Typography variant="caption" style={{ marginTop: '0.325rem' }}>
        {formatDate(billingAccount.balance_last_update)}
      </Typography>
      <div style={{ margin: '1.625rem 0 0.625rem 0' }}>
        <StyledMoney variant="h3">{bigNums}</StyledMoney>
        <StyledMoney
          style={{ fontSize: '1.875rem' }}
          variant="body1"
          color="textSecondary">
          .{smallNums}
        </StyledMoney>
      </div>
      {billingAccount.card_info && (
        <div style={{ display: 'flex' }}>
          <CreditCard {...billingAccount.card_info} />
          {/* <Tooltip title="отвзязать">
            <IconButton>
              <DeleteForever color="error" />
            </IconButton>
          </Tooltip> */}
        </div>
      )}
    </BalanceWrapper>
  )
}

export default AccountBalance
