import React, { useState } from 'react'
import { BillingAccountForm } from 'components'
import { Button, Loader, Wrapper } from 'sputnik-ui'
import { connect } from 'react-redux'

import { changeBillingAccountInfo } from 'store/operations'
import { fetchBillingAccount } from 'store/billing/operations'

const BillingAccountInfo = (props) => {
  const notFilled =
    !props.billingAccount?.company_name ||
    props.billingAccount?.company_name === '' ||
    !props.billingAccount?.phone_number ||
    props.billingAccount?.phone_number === ''
  const [isDisabled, setIsDisabled] = useState(!notFilled)

  const handleSubmit = (values) => {
    return props
      .dispatch(changeBillingAccountInfo(values))
      .then(fetchBillingAccount)
      .then(() => {
        setIsDisabled(true)
      })
  }

  return (
    <>
      <Wrapper>
        {!props.billingAccount ? (
          <Loader />
        ) : (
          <BillingAccountForm
            billingAccount={props.billingAccount}
            isDisabled={isDisabled}
            handleSubmit={handleSubmit}>
            {({ isSubmitting }) =>
              isDisabled ? (
                <Button
                  onClick={() => setIsDisabled(false)}
                  rounded
                  secondary
                  type="button"
                  key="_billing_account_edit_button"
                  size="small">
                  изменить
                </Button>
              ) : (
                <Button
                  type="submit"
                  rounded
                  primary
                  disabled={isSubmitting}
                  key="_billing_account_submit_button"
                  size="small">
                  Сохранить
                </Button>
              )
            }
          </BillingAccountForm>
        )}
      </Wrapper>
    </>
  )
}

const mapStateToProps = ({ billing }) => ({
  billingAccount: billing.account,
})

export default connect(mapStateToProps)(BillingAccountInfo)
