import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { TextField, Typography } from '@material-ui/core'
import { Button } from 'sputnik-ui'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import FileInput from './FileInput'
import { useDispatch, useSelector } from 'react-redux'
import {
  editCurrentCredit,
  removeCurrentCredit,
  setErrCurrentCredit,
} from './customizationSlice'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { showError } from 'features/ui/uiSlice'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  margin-top: 2.5rem;
`
const StyledForm = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
`

const StyledGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.5rem;
  margin-bottom: 1rem;
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    grid-template-columns: 1fr;
  }
`

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png']
const FILE_SIZE = 5e6
const phoneValidation = new RegExp(
  /^(\+7|7|8)[\s-]?\(?[3489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/
)
const phoneValidationKz = new RegExp(/^\+?77(\d{9})$/)
const isValidOffsetImage = (value) => {
  const MIN_HEIGHT = 160
  const MIN_WIDTH = 160
  return new Promise((resolve) => {
    const offset = new Image()
    offset.src = value
    offset.onload = () => {
      return offset.width >= MIN_WIDTH && offset.height >= MIN_HEIGHT
        ? resolve(true)
        : resolve(false)
    }
  })
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let baseURL = ''
    const reader = new FileReader()
    if (file) {
      reader.readAsDataURL(file)
      reader.onload = () => {
        baseURL = reader.result
        isValidOffsetImage(baseURL).then((el) =>
          el ? resolve(baseURL) : reject('Ошибка загрузки изображения')
        )
      }
    }
  })
}

const isValidUrl = (url) => {
  try {
    new URL(url)
  } catch (e) {
    return url === 'http://' || url === '' || url === null || url === undefined
  }
  return true
}

const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('введите название'),
    phone: Yup.string()
      .trim()
      .required('введите телефон')
      .test(
        'phone',
        'неверный формат',
        (value) => phoneValidation.test(value) || phoneValidationKz.test(value)
      ),
    website: Yup.string().test('is-url-valid', 'URL is not valid', (value) => {
      return isValidUrl(value)
    }),
    description: Yup.string().required('введите описание'),
    img: Yup.mixed()
      .test('fileSize', 'файл слишком большой', (value) => {
        if (isValidUrl(value)) {
          return true
        }
        return value && value.size <= FILE_SIZE
      })
      .test('fileFormat', 'неверный формат', (value) => {
        return value && SUPPORTED_FORMATS.includes(value.type)
      })
      .test('fileOffset', 'изображение слишком маленькое', (value) => {
        if (value) {
          return getBase64(value)
            .then((el) => el && true)
            .catch(() => false)
        }
      }),
  })

function CustomizationForm({ card, index }, ...props) {
  const { t } = useTranslation(['customization', 'common'])
  const len = useSelector(
    ({ customization }) => customization.current?.credits.length
  )
  const editMode = useSelector(({ customization }) => customization.editMode)
  const dispatch = useDispatch()
  const firstRender = useRef(true)
  const scroll = useRef()
  const handleDelete = () => {
    dispatch(removeCurrentCredit(card.id))
  }

  const { values, handleChange, errors, touched, handleBlur } = useFormik({
    initialValues: {
      ...card,
    },
    validationSchema: validationSchema,
  })

  const handleFileInputChange = (e) => {
    let file = e.target.value
    return getBase64(file)
      .then((result) => {
        file['base64'] = result
        return result
      })
      .catch((err) => {
        dispatch(showError(err))
      })
  }

  const handleChangeState = async (e) => {
    if (e?.target) {
      const { value, name } = e?.target
      dispatch(
        editCurrentCredit({
          id: card.id,
          value: name === 'img' ? await handleFileInputChange(e) : value,
          name: name,
        })
      )
    }
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    dispatch(setErrCurrentCredit({ id: card.id, err: { ...errors } }))
  }, [errors])

  useEffect(() => {
    if (index > 0 && !editMode) scroll.current.scrollIntoView()
  }, [])

  return (
    <Wrapper {...props} style={{ marginTop: index === 0 ? 0 : '1.5rem' }}>
      <div
        ref={scroll}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography>
          {t('customization:form.credit.company')} {index + 1}
        </Typography>
        {len > 1 ? (
          <Button
            variant="text"
            size="small"
            style={styles.btnDel}
            rounded
            secondary
            onClick={handleDelete}
          >
            <span style={{ marginRight: '10px', textTransform: 'capitalize' }}>
              {' '}
              {t('common:button.delete')}
            </span>
            <DeleteOutlineOutlined />
          </Button>
        ) : null}
      </div>

      <StyledForm>
        <StyledGrid>
          <TextField
            value={values?.title}
            label={t('customization:form.credit.name.label')}
            onChange={(e) => {
              handleChange(e)
              handleChangeState(e)
            }}
            error={touched.title && !!errors.title}
            helperText={touched.title && errors.title}
            onBlur={handleBlur}
            required
            margin="dense"
            name="title"
          />
          <TextField
            value={values?.phone}
            label={t('customization:form.credit.phone.label')}
            placeholder="+7 xxx xxx xx xx"
            onChange={(e) => {
              handleChange(e)
              handleChangeState(e)
            }}
            type="tel"
            error={touched.phone && !!errors.phone}
            helperText={touched.phone && errors.phone}
            onBlur={handleBlur}
            required
            margin="dense"
            name="phone"
          />
        </StyledGrid>

        <StyledGrid>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: '100%',
                flexDirection: 'column',
                marginTop: '0.8rem',
              }}
            >
              <FileInput
                label={t('customization:form.credit.logo.label')}
                name="img"
                linkValue={values.img}
                error={!!errors.img}
                helperText={errors.img}
                onChange={(e) => {
                  handleChange(e)
                  handleChangeState(e)
                }}
              />
            </div>

            <Typography
              color="textSecondary"
              style={{
                fontSize: '0.9rem',
                whiteSpace: 'nowrap',
                margin: '0px',
                marginLeft: '1rem',
              }}
            >
              (png или jpg, размер до 5Мб,
              <br />
              разрешение от 160 х 160 px)
            </Typography>
          </div>

          <TextField
            fullWidth
            value={values?.website}
            label={t('customization:form.credit.website.label')}
            onChange={(e) => {
              handleChange(e)
              handleChangeState(e)
            }}
            error={touched.website && !!errors.website}
            helperText={touched.website && errors.website}
            onBlur={handleBlur}
            margin="dense"
            name="website"
          />
        </StyledGrid>
        <TextField
          fullWidth
          value={values?.description}
          label={t('customization:form.credit.description.label')}
          onChange={(e) => {
            handleChange(e)
            handleChangeState(e)
          }}
          error={touched.description && !!errors.description}
          helperText={touched.description && errors.description}
          onBlur={handleBlur}
          required
          margin="dense"
          style={{ marginBottom: '1rem' }}
          name="description"
        />
      </StyledForm>
    </Wrapper>
  )
}

const styles = {
  btnDel: {
    color: '#FF0166',
    fontFamily: 'Futura PT',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '21px',
    textTransform: 'none',
    letterSpacing: 'normal',

    borderBottom: 'none',
  },
  form: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '2.5rem',
  },
}

export default CustomizationForm
