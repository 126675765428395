import React from 'react'
import merge from 'lodash/merge'
import styled from 'styled-components'

import L from 'leaflet'
import { Map, Marker, TileLayer } from 'react-leaflet'
import { leafletLocale } from 'sputnik-ui'
import { default as markerIcon } from './place-24.svg'

L.drawLocal = merge(L.drawLocal, leafletLocale)

const myIcon = L.icon({
  iconUrl: markerIcon,
  iconSize: [38, 95],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
})

const StyledMap = styled(Map)`
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: grayscale(100%);
  height: 300px;
`

const _Map = ({ lat = 120, lon = 12, ...props }) => (
  <div {...props}>
    <StyledMap center={{ lat, lon }} zoom={16} zoomControl={false}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={{ lat, lon }} icon={myIcon} />
    </StyledMap>
  </div>
)

export default _Map
