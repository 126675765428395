import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'

import Table from './Table'

import { fetchDevices } from 'store/operations'
import ReassignIntercomModal from '../ReassignIntercomModal'

class TableContainer extends Component {
  state = {
    orderBy: 'asc',
    sortBy: 'serial_number',
    limit: 5,
    rowCount: 5,
    page: 0,
    rowsPerPage: 30,
    selected: [],
    reassignModal: false,
  }

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchQuery !== this.props.searchQuery) {
      // const { searchQuery } = this.props
      // const { page, rowsPerPage: items } = this.state

      this.setState({ page: 0 }, this.fetchData)
    }

    // if (prevProps.searchQuery !== this.props.searchQuery) {
    //   this.setState({ page: 0 })
    // }

    if (
      prevState.orderBy !== this.state.orderBy ||
      prevState.sortBy !== this.state.sortBy ||
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.page !== this.state.page
    ) {
      this.fetchData()
    }
  }

  handleSortRequest = (event, property) => {
    const sortBy = property
    let orderBy = 'desc'
    if (this.state.sortBy === property && this.state.orderBy === 'desc') {
      orderBy = 'asc'
    }
    this.setState({ orderBy, sortBy })
  }

  getSorting = (orderBy, sortBy) => {
    return orderBy === 'desc'
      ? (a: Object, b: Object) => (b[sortBy] < a[sortBy] ? -1 : 1)
      : (a: Object, b: Object) => (a[sortBy] < b[sortBy] ? -1 : 1)
  }

  handleChangePage = (event: ?SyntheticEvent<>, page: number) => {
    this.setState({ page, devices: null })
  }

  fetchData = () => {
    this.props.fetchDevices({
      page: this.state.page,
      items: this.state.rowsPerPage,
      orderBy: this.state.orderBy,
      sortBy: this.state.sortBy,
      query: this.props.searchQuery,
    })
  }

  handleChangeRowsPerPage = (e: SyntheticEvent<>) => {
    //$FlowFixMe
    this.setState({ rowsPerPage: e.target.value })
  }

  handleSelect = (e: SyntheticEvent<>, uuid: string, isSelected: boolean) => {
    const { selected } = this.state

    this.setState({
      selected: isSelected
        ? selected.filter(i => i !== uuid)
        : [...selected, uuid],
    })
  }

  handleSelectAll = e => {
    if (e.target.checked && this.props.devices) {
      return this.setState({
        selected: this.props.devices.map(d => d.duuid),
      })
    }

    this.setState({
      selected: [],
    })
  }

  openReassignIntercomModal = () => {
    this.setState({ reassignModal: true })
  }

  extractProps = () => ({
    ...this.state,
    ...this.props,
    handleSortRequest: this.handleSortRequest,
    // getSorting: this.getSorting,
    handleChangePage: this.handleChangePage,
    handleChangeRowsPerPage: this.handleChangeRowsPerPage,
    handleSelect: this.handleSelect,
    handleSelectAll: this.handleSelectAll,
    openReassignIntercomModal: this.openReassignIntercomModal,
  })

  render() {
    return (
      <>
        <Table {...this.extractProps()} />
        <ReassignIntercomModal
          open={this.state.reassignModal}
          handleClose={() => this.setState({ reassignModal: false })}
          intercoms={this.state.selected}
          callback={this.fetchData}
        ></ReassignIntercomModal>
      </>
    )
  }
}

const mapStateToProps = ({ devices }) => ({
  devices: devices.list,
  totalPages: devices.totalPages,
  searchQuery: devices.query,
})

const mapDispatchToProps = dispatch => ({
  fetchDevices: debounce(args => dispatch(fetchDevices(args)), 200),
  dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TableContainer))
