import React from 'react'
import { Wrapper, Button } from 'sputnik-ui'
import styled, { css } from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import SpringCard from 'components/molecules/SpringCard'

const DARK_GREY = '#515151'
const LIGHT_GREY = '#989898'

const Body = styled(Wrapper)`
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  border: none;
  box-shadow: ${p => p.theme.shadows.strong};

  ${p =>
    p.isActive &&
    css`
      background: ${p => p.theme.palette.other.pink};
      box-shadow: none;
    `}

  & > * {
    text-align: center;
  }
`

const PlanImg = styled.img`
  /* width: 100%; */
  height: 150px;
  margin-bottom: 1rem;
`

const Title = styled(Typography)`
  && {
    font-size: 1.325rem;
    text-transform: uppercase;
    letter-spacing: 0.125rem;
    /* color: ${DARK_GREY}; */
    font-weight: 400;
    /* text-align: center; */
  }
`

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem auto;
  flex-direction: column;
`

const Price = styled(Typography)`
  && {
    /* text-align: center; */
    font-size: 2rem;
    /* color: ${DARK_GREY}; */
    font-weight: 300;
  }
`

const BillingInterval = styled(Typography).attrs(p => ({
  color: 'textSecondary',
}))`
  && {
    /* color: ${LIGHT_GREY}; */
    margin-top: -0.325rem;
  }
`

const Feature = styled(Typography)`
  width: 100%;
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow-x: hidden;
`

type Props = {
  payment_name: string,
  image: string,
  short_description: string,
  cost: number,
  rate_type: string,
  features: string[],
  features_short: string[],
  is_active: boolean,
}

const SubscriptionPlan = ({
  match,
  base_info: {
    app_id,
    payment_name,
    image,
    short_description,
    cost,
    rate_type,
    short_features,
    is_active,
  },
}: Props) => (
  <Link
    to={`${match.url}/p/${app_id}`}
    style={{
      color: 'unset',
      textDecoration: 'none',
    }}
  >
    <SpringCard>
      <Body isActive={is_active} background="primary">
        {/* <PlanImg type="image/svg+xml" data={image} /> */}
        <PlanImg alt="" src={image} />

        <Title>{payment_name}</Title>
        <Typography color="textSecondary">{short_description}</Typography>
        <PriceWrapper>
          <Price>{cost / 100}₽</Price>
          <BillingInterval>в месяц</BillingInterval>
          <BillingInterval>с подъезда</BillingInterval>
        </PriceWrapper>

        {short_features.slice(0, 3).map((f, idx) => (
          <Feature key={idx}>{f}</Feature>
        ))}
        <span style={{ marginBottom: '1rem' }} />
        <Button
          size="small"
          rounded
          style={{ marginTop: 'auto' }}
          // component={Link}
        >
          подробнее
        </Button>
      </Body>
    </SpringCard>
  </Link>
)

export default withRouter(SubscriptionPlan)
