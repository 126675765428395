import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { Container } from 'sputnik-ui'

const MARGIN = 2

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  width: calc(100vw - (100vw - 100%));
  /* min-height: calc(100% - 7.425rem); */
  padding-top: ${MARGIN}rem;
`

const SectionTitle = styled(Typography).attrs(p => ({
  variant: 'h5',
  align: 'center',
}))`
  && {
    /* font-weight: 300;
    font-size:1.625rem; */
  }
`

const SectionSubtitle = styled(Typography).attrs(p => ({
  color: 'textSecondary',
  variant: 'subtitle1',
  align: 'center',
}))`
  && {
    margin-top: ${MARGIN / 6}rem;
    /* font-size: 1rem; */
    /* font-weight: 300; */
  }
`

// const ServicesSection = styled.div`
//   padding-top: ${MARGIN}rem;
//   padding-bottom: ${MARGIN}rem;

//   display: grid;
//   grid-gap: ${MARGIN}rem;
//   grid-template-columns: repeat(auto-fit, 250px);
//   justify-content: center;
// `

const PlansSection = styled.div`
  padding: ${MARGIN}rem;
  padding-top: ${MARGIN}rem;
  display: grid;
  grid-gap: ${MARGIN * 2}rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, min-content));
  justify-content: center;

  @media screen and (max-width: ${p => p.theme.sizes.tablet}) {
    min-height: unset;
  }
`

const ServicesTemplate = ({ services, plans, children, ...props }) => (
  <Wrapper {...props}>
    <SectionTitle>Пакеты</SectionTitle>
    <SectionSubtitle>Доступные вам пакеты услуг.</SectionSubtitle>
    <PlansSection>{plans}</PlansSection>

    {/* <SectionTitle>Услуги</SectionTitle>
    <SectionSubtitle>
      Услуги можно подключить по отдельности или внутри пакета.
    </SectionSubtitle>
    <ServicesSection>{services}</ServicesSection> */}
    {children}
  </Wrapper>
)

export default ServicesTemplate
