import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'
import { showError, showMessage } from 'features/ui/uiSlice'

export const fetchDevices = createAsyncThunk(
  'devices/fetchList',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const state = getState()
    const {
      search,
      is_installed,
      is_online,
      device_type,
      locality_uuid,
      service_partner_uuid,
      order = 'desc',
      page = 1,
      limit = 25,
    } = params

    try {
      const payload = await API.request(
        buildUrl('/devices', params),
        {
          dispatch,
        },
        `${config.redirectUri}/api/v2`
      )
      return payload
    } catch {
      return rejectWithValue()
    }
  }
)

export const fetchDeviceServices = createAsyncThunk(
  'devices/fetchServices',
  async ({ uuid }, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        `/devices/${uuid}/services`,
        {
          dispatch,
        },
        `${config.redirectUri}/api/v2`
      )
      return payload
    } catch {
      return rejectWithValue()
    }
  }
)

export const fetchDeviceServicesHistory = createAsyncThunk(
  'devices/fetchServicesHistory',
  async (
    { uuid, page, limit = 20 },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const payload = await API.request(
        `/devices/${uuid}/service_histories?page=${page}`,
        {
          dispatch,
        },
        `${config.redirectUri}/api/v2`
      )
      return Promise.resolve({
        history: payload.service_history,
        totalPages: payload.meta.pages_count,
        page,
      })
    } catch {
      return rejectWithValue()
    }
  }
)

export const activateService = createAsyncThunk(
  'devices/activateService',
  async ({ service, devices }, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        `/services/${service}/activate`,
        {
          dispatch,
          method: 'PATCH',
          body: JSON.stringify({
            devices,
          }),
        },
        `${config.redirectUri}/api/v2`
      )
      dispatch(showMessage('Вы успешно подключили услугу'))
      return Promise.resolve(payload)
    } catch (err) {
      const message = await parseServiceErrors(err)
      dispatch(showError(message))
      return rejectWithValue()
    }
  }
)

export const deactivateService = createAsyncThunk(
  'devices/deactivateService',
  async ({ service, devices }, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        `/services/${service}/deactivate`,
        {
          dispatch,
          method: 'PATCH',
          body: JSON.stringify({
            devices,
          }),
        },
        `${config.redirectUri}/api/v2`
      )
      dispatch(showMessage('Вы успешно отключили услугу'))
      return Promise.resolve(payload)
    } catch (err) {
      const message = await parseServiceErrors(err)
      dispatch(showError(message))
      return rejectWithValue()
    }
  }
)

const parseServiceErrors = async (err) => {
  if (err.status === 207) {
    const body = await err.json()
    return body.map((item) => {
      return item.error === true ? item.message : ''
    }).join(" ")
  } else {
    const {message} = await err.json()
    return message
  }
}

export const fetchDevice = createAsyncThunk(
  'devices/fetchDevice',
  async (uuid, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        `/devices/${uuid}`,
        {
          dispatch,
        },
        `${config.redirectUri}/api/v2`
      )
      return payload
    } catch {
      return rejectWithValue()
    }
  }
)

// reducers
export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    current: null,
    list: null,
    pages: null,
    total: null,
    totalSum: null,
    page: 1,
    limit: 25,
    selected: {},
    search: '',
    currentServices: [],
    pending: false,
    services: [],
    servicesHistory: null,
    pendingServiceToggle: false,
  },
  reducers: {
    toggleSelect: (state, { payload: device }) => {
      if (device.uuid in state.selected) {
        delete state.selected[device.uuid]
      } else {
        state.selected[device.uuid] = device
      }
    },
    toggleAllDevices: (state) => {
      // add pages

      if (Object.keys(state.selected).length > 0) {
        state.selected = {}
      } else {
        state.selected = state.list.reduce(
          (acc, cur) => ({ ...acc, [cur.uuid]: cur }),
          state.selected
        )
      }
    },
    clearDevices: (state) => {
      state.list = null
      state.pages = null
      state.total = null
      state.totalSum = null
      // state.page = 1
    },
    incPage: (state) => {
      state.page = clamp(state.page + 1, 1, state.pages)
    },
    decPage: (state) => {
      state.page = clamp(state.page - 1, 1, state.pages)
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    },
    clearCurrentDevice: (state) => {
      state.current = null
    },
  },
  extraReducers: {
    [activateService.pending]: (state) => {
      state.pendingServiceToggle = true
    },
    [activateService.fulfilled]: (state) => {
      state.pendingServiceToggle = false
    },
    [activateService.rejected]: (state) => {
      state.pendingServiceToggle = false
    },
    [deactivateService.pending]: (state) => {
      state.pendingServiceToggle = true
    },
    [deactivateService.fulfilled]: (state) => {
      state.pendingServiceToggle = false
    },
    [deactivateService.rejected]: (state) => {
      state.pendingServiceToggle = false
    },
    [fetchDevices.pending]: (state) => {
      state.pending = true
    },
    [fetchDevices.fulfilled]: (state, { payload }) => {
      state.pending = false
      state.list = payload.devices
      state.pages = payload.meta?.total_pages
      state.total = payload.meta?.total
      state.totalSum = payload.meta?.total_sum
    },
    [fetchDevices.rejected]: (state) => {
      state.pending = false
    },
    [fetchDevice.pending]: (state) => {
      state.current = null
    },
    [fetchDevice.fulfilled]: (state, { payload }) => {
      state.current = payload
    },
    [fetchDeviceServices.fulfilled]: (state, { payload }) => {
      state.currentServices = payload
    },
    [fetchDeviceServicesHistory.fulfilled]: (state, { payload }) => {
      state.historyPages = payload.totalPages

      if (payload.page > 1) {
        state.servicesHistory = [...state.servicesHistory, ...payload.history]
      } else {
        state.servicesHistory = payload.history
      }
    },
  },
})

export const {
  toggleSelect,
  toggleAllDevices,
  clearDevices,
  incPage,
  decPage,
  setSearch,
  clearCurrentDevice,
} = devicesSlice.actions

const selectSelectedDevices = (state) => state.devices.selected
export const selectNumSelected = createSelector(
  [selectSelectedDevices],
  (selected) => {
    return Object.keys(selected).length || 0
  }
)
export const selectTotalServicesSum = createSelector(
  [selectSelectedDevices],
  (selected) =>
    Object.values(selected).reduce((acc, cur) => (acc += cur.services_sum), 0)
)

export default devicesSlice.reducer
