/* @flow */

export const SIGN_IN: string = 'auth/SIGN_IN'
export const SIGN_IN_ERROR: string = 'auth/SIGN_IN_ERROR'
export const CHECK_AUTH: string = 'auth/CHECK_AUTH'

export const FETCH_USER_COMPANY_REQUEST: string = 'auth/FETCH_USER_COMPANY_REQUEST'
export const FETCH_USER_COMPANY_SUCCESS: string = 'auth/FETCH_USER_COMPANY_SUCCESS'
export const FETCH_USER_COMPANY_FAILURE: string = 'auth/FETCH_USER_COMPANY_FAILURE'

export const LOG_OUT_REQUEST: string = 'auth/LOG_OUT_REQUEST'
export const LOG_OUT_SUCCESS: string = 'auth/LOG_OUT_SUCCESS'
export const LOG_OUT_ERROR: string = 'auth/LOG_OUT_ERROR'

export const FETCH_PARTNER_ACCEPT = 'partner_program/FETCH_PARTNER_ACCEPT'
export const FETCH_PARTNER_ACCEPT_SUCCESS = 'partner_program/FETCH_PARTNER_ACCEPT_SUCCESS'
export const FETCH_PARTNER_ACCEPT_FAILURE = 'partner_program/FETCH_PARTNER_ACCEPT_FAILURE'

export const FETCH_PARTNER_STATISTIC = 'partner_program/FETCH_PARTNER_STATISTIC'
export const FETCH_PARTNER_STATISTIC_SUCCESS = 'partner_program/FETCH_PARTNER_STATISTIC_SUCCESS'
export const FETCH_PARTNER_STATISTIC_FAILURE = 'partner_program/FETCH_PARTNER_STATISTIC_FAILURE'

export const ACCEPT_LICENSE = 'license/ACCEPT_LICENSE'
export const ACCEPT_LICENSE_SUCCESS = 'license/ACCEPT_LICENSE_SUCCESS'
export const ACCEPT_LICENSE_FAILURE = 'license/ACCEPT_LICENSE_FAILURE'
