//@flow
import * as actions from './actions'
import Api from 'utils/api'

export const getCompanies: Function = (): Function => async (
  dispatch: Function
): Promise<any> => {
  dispatch(actions.getCompaniesRequest())
  try {
    const companies: Object[] = await Api.request('/companies')
    dispatch(actions.getCompaniesSuccess(companies))
  } catch (err) {
    dispatch(actions.getCompaniesError(err))
  }
}
