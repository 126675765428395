import * as types from './types'
import { clear } from 'features/ui/uiSlice'

const initialState = {
  list: null,
  msg: null,
  error: null,
}

export default function service_partners(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case types.FETCH_PARTNERS_SUCCESS:
      return { ...state, list: payload.partners }
    case types.REASSIGN_DEVICES_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.REASSIGN_DEVICES_FAILURE:
      return { ...state, error: payload.error }
    case types.INVITE_PARTNER_SUCCESS:
      return { ...state, msg: payload.msg }
    case types.INVITE_PARTNER_FAILURE:
      return { ...state, error: payload.error }
    case [clear.type]:
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
