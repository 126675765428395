//@flow
//$FlowFixMe
import React from 'react'
import styled from 'styled-components'
import { Typography, Avatar, Tooltip } from '@material-ui/core'
import { ServiceActions } from 'components'
import { Wrapper } from 'sputnik-ui'
import { formatDate } from 'utils/helpers'

import type { IService } from 'globalTypes'
import { rateTypes } from 'globalTypes'
import { CreditCard } from '@material-ui/icons'

const ServiceBody = styled(Wrapper)`
  width: 240px;
  /* height: calc(100% - 20px); */
  flex-direction: column;
  min-height: 225px;
  white-space: normal;
  display: flex;
  flex: 0 0 auto;
  overflow-y: hidden;
  overflow-x: hidden;
`

const PriceTag = styled(Avatar)`
  && {
    width: 80px;
    height: 80px;
    background: ${p =>
      (p.isConnected
        ? p.theme.palette.error['700']
        : p.theme.palette.success['700']) + '11'};
    color: ${p =>
      p.isConnected
        ? p.theme.palette.error['700']
        : p.theme.palette.success['700']};
    margin: 0 auto;
  }
`

const Service = ({
  base_info: {
    payment_name,
    app_id,
    payment,
    cost,
    rate_type,
    description,
    type,
    next_payment_date,
  },
  isConnected,
  ...props
}: IService & {
  isConnected: boolean,
}) => {
  return (
    <ServiceBody shadow={false} {...props}>
      <Typography variant="caption" color="textSecondary" style={styles.rate}>
        {rateTypes[rate_type]}
      </Typography>
      {next_payment_date && (
        <Tooltip title="следующее списание">
          <Typography
            variant="caption"
            color="textSecondary"
            style={styles.rate}
          >
            {formatDate(next_payment_date, undefined, true)}
          </Typography>
        </Tooltip>
      )}
      <PriceTag isConnected={isConnected}>₽{cost / 100}</PriceTag>
      <Typography
        variant="h6"
        color="textPrimary"
        align="center"
        style={{ marginTop: '1rem', fontSize: '1.125rem' }}
      >
        {payment_name}
      </Typography>
      <Typography
        color="textSecondary"
        align="center"
        style={styles.description}
      >
        {description}
      </Typography>

      {type === 'per_device' && (
        <ServiceActions
          isConnected={isConnected}
          style={styles.actions}
          appId={app_id}
        />
      )}
    </ServiceBody>
  )
}

const styles = {
  price: {
    width: 80,
    height: 80,
  },
  description: {
    marginTop: '1rem',
  },
  rate: {
    // marginTop: '0.325rem',
  },
  actions: { margin: '1rem auto 0' },
}

export default Service
