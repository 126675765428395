import React from 'react'
import SputnikImg from './sputnik.png'
import styled from 'styled-components'
import { Typography, TextField } from '@material-ui/core'
import { Button } from 'sputnik-ui'

const ProductWrapper = styled.div`
  height: 100vh;
  display: flex;
  width: 100%;
  /* align-items: center; */
`

const ProductImageWrapper = styled.div`
  background: #e8f5e9;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
`

const MARG_TOP = 15

const ProductImage = styled.img`
  height: 70%;
  margin-top: ${MARG_TOP}%;
`

const ProductInfo = styled.div`
  padding: 0 3.625rem;
  width: 50%;
`

const ProductTitle = styled(Typography)`
  && {
    margin-top: ${MARG_TOP}%;
    font-weight: 500;
  }
`

const ProductPrice = styled(Typography)`
  && {
    margin-top: 0.325rem;
  }
`

const ProductDescription = styled(Typography)`
  && {
    margin-top: 2.325rem;
  }
`

const ProductActions = styled.div`
  display: flex;
  margin-top: 2.325rem;
  justify-content: center;
`

const BuyWrapper = styled.div``

type Props = {
  title: string,
}

const ProductCard = ({ title }: Props) => {
  return (
    <ProductWrapper>
      <ProductImageWrapper>
        <ProductImage src={SputnikImg} />
      </ProductImageWrapper>
      <ProductInfo>
        <ProductTitle variant="h4">{title}</ProductTitle>
        <ProductPrice variant="subtitle1">10000₽</ProductPrice>
        <ProductDescription>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eum, fugiat
          sunt incidunt maxime sint nam doloremque eligendi magni tenetur dolore
          possimus similique consequuntur ipsum nemo aperiam, eius non
          perspiciatis explicabo.
        </ProductDescription>
        <ProductActions>
          <Button>демо</Button>
          <Button>КАЛЬКУЛЯТОР</Button>
        </ProductActions>
        <BuyWrapper>
          <TextField type="number" />
        </BuyWrapper>
      </ProductInfo>
    </ProductWrapper>
  )
}

export default ProductCard
