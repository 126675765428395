import styled, { css } from 'styled-components'

const Option = styled.div`
  display: inline-flex;
  padding: 0.325rem;
  transition: all 300ms cubic-bezier(0.38, -0.53, 0.39, 1.96);
  cursor: pointer;
  border: 1px dashed rgba(0, 0, 0, 0.54);
  margin: 0.325rem;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.54);
  font-family: ${p => p.theme.fonts.primary};

  &:hover {
    transform: translateY(-0.325rem);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-color: black;
    color: black;
  }

  ${p =>
    p.selected &&
    css`
      border-style: solid;
      border-color: black;
      color: black;
      cursor: default;

      &:hover {
        transform: none;
        box-shadow: none;
      }
    `};
`

export default Option
