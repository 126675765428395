import React, { useEffect, useState, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  Table,
  TableRow,
  TableBody,
  Typography,
  TablePagination,
} from '@material-ui/core'
import { Loader } from 'sputnik-ui'
import {
  clearSelectedEntries,
  fetchCustomizationAddresses,
  setCustomizationFetching,
} from './customizationSlice'
import CustomizationAddressItem from './CustomizationAddressItem'
import FetchingScreen from 'components/atoms/FetchingScreen'
import CustomizationAddressChooseAll from './CustomizationAddressChooseAll'
import { useTranslation } from 'react-i18next'

const StyledTableRow = styled(TableRow)`
  && {
    cursor: pointer;
    &:hover {
      background-color: rgba(
        ${(p) =>
          p.status === 'error'
            ? '255,0,0'
            : p.status === 'success'
            ? '0,255,0'
            : '0,0,0'},
        0.07
      );
    }
  }
`

function CustomizationAddressList(
  { addressParams, handleChangeAddressParams },
  ...props
) {
  const { t } = useTranslation(['customization', 'messages'])
  const dispatch = useDispatch()
  const list = useSelector(
    ({ customization }) => customization.addresses?.addresses
  )
  const isFetching = useSelector(
    ({ customization }) => customization.isFetching
  )
  const meta = useSelector(({ customization }) => customization.addresses?.meta)
  const pending = useSelector(({ customization }) => customization.pending)
  const firstUpdate = useRef(true)
  const [allSelected, setAllSelected] = useState(false)

  useEffect(() => {
    dispatch(fetchCustomizationAddresses({ ...addressParams }))
    return () => {
      dispatch(clearSelectedEntries())
    }
  }, [])

  const handleSelectAll = (val) => {
    setAllSelected(val)
  }

  useEffect(() => {
    if (firstUpdate.current) {
      //useRef ignore first render isFectching control
      firstUpdate.current = false
      return
    }
    if (addressParams.page < 0) {
      handleChangeAddressParams({ ...addressParams, page: 0 })
    }

    handleSelectAll(false)
    dispatch(setCustomizationFetching({ isFetching: true }))
    rerender()
  }, [addressParams])

  const rerender = useDebouncedCallback(
    () => dispatch(fetchCustomizationAddresses({ ...addressParams })),
    300
  )
  return (
    <div style={{ position: 'relative', overflowX: 'auto' }}>
      {pending && !isFetching ? (
        <Loader />
      ) : (
        <Table style={styles.table}>
          {isFetching && <FetchingScreen />}
          {list?.length > 0 ? (
            <TableBody>
              <>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: '0.875rem', margin: '1.5rem 0 .5rem' }}
                >
                  {t('customization:address.info')}
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: '0.875rem',  marginBottom: '1.5rem' }}
                >
                  Обратите внимание, что данный функционал временно не
                  поддерживается на адресах, где есть 2+ домофонов и/или
                  калитка.
                </Typography>
                <StyledTableRow hover>
                  <CustomizationAddressChooseAll
                    list={list}
                    handleSelectAll={handleSelectAll}
                    isSelected={allSelected}
                  >
                    {t('customization:address.select_all')}
                  </CustomizationAddressChooseAll>
                </StyledTableRow>
                {list.map((el) => (
                  <StyledTableRow key={el.uuid} hover>
                    <CustomizationAddressItem
                      uuid={el.uuid}
                      connected={el?.customization?.title}
                    >
                      {el.address}
                    </CustomizationAddressItem>
                  </StyledTableRow>
                ))}
              </>
              <TablePagination
                component="div"
                count={meta?.total}
                rowsPerPage={addressParams.limit}
                rowsPerPageOptions={[10, 20, 30]}
                page={addressParams.page}
                labelRowsPerPage="Строк на странице:"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} из ${count}`
                }
                onChangePage={(e, page) => {
                  handleChangeAddressParams({ ...addressParams, page: page })
                }}
                onChangeRowsPerPage={(e) => {
                  handleChangeAddressParams({
                    ...addressParams,
                    limit: e.target.value,
                  })
                }}
                style={{ background: 'white' }}
              />
            </TableBody>
          ) : (
            <Typography align="center">
              {t('messages:content.empty')}
            </Typography>
          )}
        </Table>
      )}
    </div>
  )
}
const styles = {
  table: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
  },
}

export default CustomizationAddressList
