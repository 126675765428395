import { createSlice } from '@reduxjs/toolkit'
import { fetchAddresses } from './services'

const initialState = {
  meta: undefined,
  list: [],
  selected: [],
  pending: false,
}

const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    clearAddresses: () => initialState,
    selectAddress: (state, { payload }) => {
      const index = state.selected.findIndex((address) => address.uuid === payload.uuid)
      if (index === -1) {
        state.selected = [...state.selected, payload]
      }
      if (index !== -1) {
        state.selected.splice(index, 1)
      }
    },
    setSelectedAddresses: (state, { payload }) => {
      if (!Array.isArray(payload)) {
        return
      } 
      state.selected = payload
    },
  },
  extraReducers: {
    [fetchAddresses.rejected]: (state) => {
      state.pending = false
    },
    [fetchAddresses.pending]: (state) => {
      state.pending = true
    },
    [fetchAddresses.fulfilled]: (state, { payload }) => {
      state.list = payload.addresses
      state.meta = payload.meta
      state.pending = false
    },
  },
})

export const { actions: addressesActions } = addressesSlice
export const { reducer: addressesReducer } = addressesSlice
