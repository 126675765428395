//$FlowFixMe
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import ym from 'react-yandex-metrika'
import {
  Button,
  Loader,
  FullscreenExitButton,
  FullscreenDialogTitle,
} from 'sputnik-ui'
import { Dialog, DialogActions, Fade } from '@material-ui/core'
import { buyProduct } from 'store/products/operations'
import { fetchBillingAccount } from 'store/billing/operations'
import DepositForm from 'components/molecules/DepositForm'
import BuyProductForm from 'components/molecules/BuyProductForm'
import SuccessfullPaymentForm from 'components/molecules/SuccessfullPaymentForm'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { makeDeposit } from 'store/billing/operations'

const Transition = (props) => <Fade direction="up" {...props} />

const BuyProductModal = ({
  open = true,
  accountBalance,
  dispatch,
  isLoading,
  t,
}: Props) => {
  console.log('adaddaad')
  const history = useHistory()
  const { product: productIdx } = useParams()
  const product = useSelector(({ products }) => products.list[productIdx])

  const handleClose = () => history.goBack()
  const [productQty, setProductQty] = useState(1)
  const totalPrice =
    (product?.cost / 100) * Number(productQty) * Number(product?.unit)
  const notEnoughBalance = Boolean(accountBalance < totalPrice)
  const onBuy = () => {
    dispatch(buyProduct(product?.app_id, productQty * product?.unit)).then(
      () => {
        dispatch(fetchBillingAccount())
        history.push('/billing')
      },
    )
  }

  const [isDepositing, setIsDepositing] = useState(false)
  const [invoiceUuid, setInvoiceUuid] = useState(null)
  const onBuyAndDeposit = ({
    total,
    paymentMethod,
    usePaymentCard,
    phoneNumber,
  }) => {
    dispatch(
      makeDeposit({
        total: totalPrice - accountBalance,
        quantity: productQty * product?.unit,
        app_id: product?.app_id,
        operation_type: paymentMethod,
        use_payment_method: usePaymentCard,
        phone_number: phoneNumber,
      }),
    ).then(({ operation_id, redirect_url }) => {
      if (paymentMethod === 'direct_deposit') {
        return setInvoiceUuid(operation_id)
      }
      dispatch(fetchBillingAccount())

      history.push('/billing')
    })
  }

  const currentScreen = invoiceUuid
    ? 'invoice'
    : isDepositing
    ? 'deposit'
    : 'buy'

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen
    >
      <FullscreenExitButton
        variant={currentScreen === 'deposit' ? 'back' : 'close'}
        onClick={
          currentScreen === 'deposit'
            ? () => setIsDepositing(false)
            : handleClose
        }
      />
      <FullscreenDialogTitle
        subtitle={
          currentScreen === 'deposit'
            ? `Пополнить баланс на недостающую сумму и купить ${
                productQty * product?.unit
              } ${t(`products:devices.${product?.name}_long`, {
                count: productQty * product?.unit,
              })}`
            : currentScreen === 'invoice'
            ? 'Ваша заявка отправлена в обработку. Ожидайте подтверждения.'
            : ''
        }
      >
        {currentScreen === 'invoice' ? 'Успех!' : 'Покупка'}
      </FullscreenDialogTitle>

      {!isDepositing ? (
        <BuyProductForm
          onBuy={onBuy}
          productQty={productQty}
          setProductQty={setProductQty}
          totalPrice={totalPrice}
          notEnoughBalance={notEnoughBalance}
          product={product}
          handleClose={handleClose}
          setIsDepositing={setIsDepositing}
        />
      ) : invoiceUuid ? (
        <SuccessfullPaymentForm
          invoiceUuid={invoiceUuid}
          handleClose={handleClose}
        />
      ) : (
        <DepositForm defaultAmount={totalPrice - accountBalance}>
          {({ paymentMethod, usePaymentCard, phoneNumber }) => (
            <DialogActions
              style={{ justifyContent: 'center', margin: '1.325rem' }}
            >
              <Button
                rounded
                variant="contained"
                color="primary"
                size="fullScreen"
                disabled={isLoading}
                onClick={() => {
                  // ym('reachGoal', 'partner_billing_kassaOut')
                  onBuyAndDeposit({
                    paymentMethod,
                    usePaymentCard,
                    phoneNumber,
                  })
                }}
              >
                пополнить и купить
              </Button>
            </DialogActions>
          )}
        </DepositForm>
      )}
      {isLoading && (
        <Loader
          msg="Выполняем операцию..."
          style={{
            position: 'absolute',
            background: 'rgba(0,0,0,0.65)',
            color: 'white',
          }}
          textProps={{ variant: 'h5' }}
        />
      )}
    </Dialog>
  )
}

type Props = {
  open: boolean,
  handleClose: Function,
  dispatch: Function,
  history: Object,
  isLoading: boolean,
}

const mapStateToProps = ({ billing }) => ({
  accountBalance: billing.account?.balance,
  isLoading: billing.loading,
  // billingAccountPresent: billing.account && billing.account.is_present,
})

export default withTranslation(['common', 'products'])(
  connect(mapStateToProps)(BuyProductModal),
)
