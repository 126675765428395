import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { checkAuth } from 'store/auth/operations'
import { Loader } from 'sputnik-ui'

export default function requireAuth(C) {
  function Authentication({ dispatch, isLoggedIn, ...props }) {
    useEffect(() => {
      dispatch(checkAuth())
    }, [dispatch])

    if (!isLoggedIn) {
      return <Loader fullPage msg="Проверяем авторизацию..." />
    }

    return <C {...props} />
  }

  const mapStateToProps = ({ auth }) => ({
    isLoggedIn: auth.isLoggedIn,
  })

  return connect(mapStateToProps)(Authentication)
}
