import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { editSelectedEntries, clearSelectedEntries } from './customizationSlice'
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { Checkbox } from 'sputnik-ui'

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: start;
  grid-template-columns: repeat(2, 1fr);
  padding: 15px;
  border-bottom: 1px solid #ededed;
`

function CustomizationAddressChooseAll({
  children,
  list,
  handleSelectAll,
  isSelected,
}) {
  const dispatch = useDispatch()
  const selectedEntries = useSelector(
    ({ customization }) => customization.selectedEntries
  )
  useEffect(() => {
    if (list?.length > 0) {
      const chooseAll = list.map((el) => el.uuid)
      const checker = (arr, target) => target.every((v) => arr.includes(v))
      handleSelectAll(checker(selectedEntries, chooseAll))
    }
  }, [list])

  const handleChange = () => {
    if (!isSelected) {
      const chooseAll = list.map((el) => el.uuid)
      handleSelectAll(true)
      dispatch(
        editSelectedEntries({
          selectedEntries: [...new Set([...selectedEntries, ...chooseAll])],
        })
      )
    } else {
      handleSelectAll(false)
      dispatch(clearSelectedEntries())
    }
  }

  return (
    <Wrapper>
      <Box display="flex" alignItems="center">
        <Checkbox checked={isSelected} onChange={handleChange} color="blue" />
        <Typography>{children}</Typography>
      </Box>
    </Wrapper>
  )
}

export default CustomizationAddressChooseAll
