//@flow
//$FlowFixMe
import React, { useState, useEffect } from 'react'
import {
  BillingTemplate,
  TransactionHistory,
  BillingAccountInfo,
  AccountBalance,
  DepositModal,
  BillingStats,
} from 'components'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withRouter, NavLink, Route, Switch } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import type { Match } from 'react-router-dom'
import type { BillingAccount } from 'globalTypes'
import { Loader, Wrapper, Options } from 'sputnik-ui'
import { useTransition, animated } from 'react-spring'

import {
  fetchTransactions,
  fetchAvailablePeriod,
  fetchStatistics,
} from 'store/operations'
import { clearTransactionsData } from 'store/actions'

// const Tab = props => (
//   <Typography
//     variant="body1"
//     component={NavLink}
//     color="textSecondary"
//     {...props}
//     style={{
//       marginRight: '1rem',
//       textDecoration: 'none',
//     }}
//     activeStyle={{
//       color: 'black',
//     }}
//   />
// )

const Tab = styled(Typography).attrs((p) => ({
  color: 'textSecondary',
  activeStyle: {
    color: p.theme.palette.text.primary,
  },
  component: NavLink,
}))`
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 2rem;
  }
`

function BillingPage({
  dispatch,
  match,
  billingAccount,
  years,
  history,
  location,
  ...props
}: Props) {
  useEffect(() => {
    dispatch(fetchAvailablePeriod())
  }, [dispatch])
  const [selectedYear, setSelectedYear] = useState(null)

  if (years && !selectedYear) {
    setSelectedYear(years[0])
  }

  useEffect(() => {
    dispatch(fetchTransactions(selectedYear))
    dispatch(fetchStatistics(selectedYear))

    return () => dispatch(clearTransactionsData())
  }, [dispatch, selectedYear])

  // const transitions = useTransition(location, location => location.pathname, { ... })
  // return transitions.map(({ item, props, key }) => (
  //   <animated.div key={key} style={props}>
  //     <Switch location={item}>
  //       <Route path="/a" component={A} />
  //       <Route path="/b" component={B} />
  //       <Route path="/c" component={C} />
  //     </Switch>
  //   </animated.div>
  // ))
  const fadeLeft = useTransition(true, null, {
    from: { opacity: 0, transform: 'translate3d(450px,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(450px,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  })
  // const fadeRight = useTransition(true, null, {
  //   from: { opacity: 0, transform: 'translate3d(-450px,0,0)' },
  //   leave: { opacity: 0, transform: 'translate3d(-450px,0,0)' },
  //   enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  // })

  const fadeRight = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(-250px,0,0)' },
    leave: {
      display: 'none',
    },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  })

  if (!billingAccount) return <Loader />

  return (
    <BillingTemplate
      tabs={
        <>
          <Tab exact to={`${match.url}`}>
            История платежей
          </Tab>
          <Tab to={`${match.url}/billing_account`}>Платежная информация</Tab>
          <Tab to={`${match.url}/stats`}>Статистика</Tab>
        </>
      }
      aside={
        <>
          {fadeLeft.map(
            ({ item, key, props }) =>
              item && (
                <animated.div key={key} style={props}>
                  <Wrapper background="primay" style={{ marginBottom: '2rem' }}>
                    <Options
                      options={years || []}
                      value={selectedYear}
                      onChange={setSelectedYear}
                    />
                  </Wrapper>

                  <AccountBalance billingAccount={billingAccount} />
                </animated.div>
              ),
          )}
          {/* <AccountBalance
            data-aos="fade-left"
            billingAccount={billingAccount}
            onClick={() => history.push(`${match.url}/make_deposit`)}
          /> */}
        </>
      }
    >
      {fadeRight.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>
            <Route path={`${match.url}/stats`} component={BillingStats} />
            <Route
              path={`${match.url}/billing_account`}
              component={BillingAccountInfo}
            />
            <Route path={match.url} render={() => <TransactionHistory selectedYear={selectedYear} />} />
          </Switch>
          <Route path={`${match.url}/make_deposit`} component={DepositModal} />
        </animated.div>
      ))}
      {/* <Switch>
        <Route path={`${match.url}/stats`} component={BillingStats} />
        <Route
          path={`${match.url}/billing_account`}
          component={BillingAccountInfo}
        />
        <Route path={match.url} component={TransactionHistory} />
      </Switch>
      <Route path={`${match.url}/make_deposit`} component={DepositModal} /> */}
    </BillingTemplate>
  )
}

type Props = {
  match: Match,
  dispatch: Function,
  billingAccount: BillingAccount,
  years: string[],
  history: Object,
}

const mapStateToProps = ({ billing }) => ({
  billingAccount: billing.account,
  years: billing.period,
})

export default withRouter(connect(mapStateToProps)(BillingPage))
