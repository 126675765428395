import React from 'react'
import { Button, Mascotte } from 'sputnik-ui'
import config from 'config'
import { DialogActions, DialogContent, Typography } from '@material-ui/core'
import { PictureAsPdf } from '@material-ui/icons'

const SuccessfullPaymentForm = ({ handleClose, invoiceUuid }) => (
  <>
    <DialogContent>
      <Mascotte emote="forward" style={{ margin: '0 auto 0.625rem auto' }} />
      <Typography variant="h5" align="center">
        Вы можете посмотреть счет на оплату и распечатать файл.
      </Typography>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'center', margin: '1.325rem' }}>
      <Button
        rounded
        variant="contained"
        color="danger"
        component="a"
        href={`${config.baseUri}/partners/invoices/${invoiceUuid}.pdf`}
        target="_blank"
        style={{
          padding: '1rem 1.5rem',
          width: '100%',
          maxWidth: '22rem',
          fontSize: '1rem',
        }}
      >
        <PictureAsPdf
          style={{ marginRight: 8, height: '1rem', width: '1rem' }}
        />
        счет
      </Button>
    </DialogActions>
  </>
)

export default SuccessfullPaymentForm
