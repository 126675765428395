import {useState} from 'react'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'

function UpdateModal() {
    // const [auto, setAuto] = useState(false)
    const [hide, setHide] = useState(false)
  return (
    <Dialog open={false} disableBackdropClick>
      <DialogTitle>Обновления</DialogTitle>
    </Dialog>
  )
}

export default UpdateModal
