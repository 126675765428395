import React, { useState } from 'react'
import styled from 'styled-components'
import { DepositOption, CreditCard } from 'components'
import { connect } from 'react-redux'
import {
  Typography,
  TextField,
  Checkbox,
  DialogContent,
  FormControlLabel,
  InputAdornment,
} from '@material-ui/core'
import { Payment, Assignment } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import { useInput } from 'sputnik-ui'

import { paymentMethods } from 'globalTypes'

const Wrapper = styled.div`
  max-width: 600px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  /* align-items: center; */
  & > * {
    margin: 1.625rem 0;
  }
`

const DepositForm = ({ t, defaultAmount, billingAccount, children }) => {
  const cardInfoPresent = billingAccount && !!billingAccount.card_info

  const [paymentMethod, setPaymentMethod] = useState(paymentMethods.check)
  const [usePaymentCard, setUsePaymentCard] = useState(cardInfoPresent)
  const { value: phoneNumber, bind: bindPhoneNumber } = useInput('')
  const [total, setTotal] = useState(defaultAmount)

  return (
    <>
      <DialogContent>
        {/* <Typography variant="h5" align="center">
          Способ пополнения
        </Typography>
        <Wrapper> */}
        {/* <DepositOption
            icon={<Payment />}
            selected={paymentMethod === paymentMethods.card}
            onClick={() => setPaymentMethod(paymentMethods.card)}
          >
            Банковской картой
          </DepositOption>

          {paymentMethod === paymentMethods.card &&
            (billingAccount && !billingAccount.phone_number) && (
              <TextField
                fullWidth
                label="Номер телефона"
                helperText="Нужен для выставления чека"
                autoFocus
                {...bindPhoneNumber}
                style={{ marginTop: '-1.5rem' }}
              />
            )} */}

        {/* <DepositOption
            selected={paymentMethod === paymentMethods.check}
            onClick={() => setPaymentMethod(paymentMethods.check)}
            icon={<Assignment />}
          >
            Безналичный расчет
          </DepositOption>
        </Wrapper> */}
        <Typography
          variant="h5"
          align="center"
          style={{ marginBottom: '1.625rem' }}
        >
          Сумма пополнения
        </Typography>
        <Wrapper>
          <TextField
            fullWidth
            helperText={!defaultAmount && 'минимальная сумма 100 рублей'}
            placeholder="100"
            autoFocus
            type="number"
            inputProps={{ min: 100 }}
            onChange={e => setTotal(e.target.value)}
            value={total}
            // eslint-disable-next-line
            InputProps={{
              endAdornment: <InputAdornment position="end">₽</InputAdornment>,
            }}
            // eslint-disable-next-line
            inputProps={{
              style: {
                // textAlign: isMobile ? 'left' : 'center',
                minWidth: 160,
                fontSize: '2.625rem',
                marginLeft: 30,
                textAlign: 'center',
                fontWeight: 300,
              },
              type: 'number',
              step: 1,
              min: 1,
              max: 10000,
            }}
            disabled={defaultAmount}
          />
          {paymentMethod === paymentMethods.card && cardInfoPresent && (
            <>
              <FormControlLabel
                style={styles.checkboxWrapper}
                control={
                  <Checkbox
                    style={styles.checkbox}
                    checked={usePaymentCard}
                    onChange={() => setUsePaymentCard(!usePaymentCard)}
                  />
                }
                label="Использовать сохраненный способ оплаты"
              />

              <CreditCard {...billingAccount.card_info} />
            </>
          )}
        </Wrapper>
      </DialogContent>

      {children &&
        children({ total, paymentMethod, usePaymentCard, phoneNumber })}
    </>
  )
}

const styles = {
  checkboxWrapper: {
    marginTop: '.625rem',
    marginLeft: '-16px',
  },
}

const mapStateToProps = ({ billing }) => ({
  billingAccount: billing.account,
})

export default withTranslation(['common'])(
  connect(mapStateToProps)(DepositForm),
)
