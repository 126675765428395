import React from 'react';
import { select } from 'd3-selection';
import { interpolate } from 'd3-interpolate';
import { transition } from 'd3-transition';

const arcTween = (newAngle, arcGenerator) => (d) => {
    const customInterpolate = interpolate(d.endAngle, newAngle)
    return (t) => {
        d.endAngle = customInterpolate(t);
        return arcGenerator(d);
    };
};

class Bar extends React.Component {
    constructor() {
        super()
        this.ref = React.createRef()
    }
    componentDidMount() {
        this.init()
    }
    componentDidUpdate() {
        this.transitionBar()
    }
    init() {
        const node = this.ref.current
        const { tau } = this.props
        const initialValue = 0

        const g = select(node)
            .append('path')
            .datum({ endAngle: (initialValue / 100) * tau })

        this.g = g;
        this.transitionBar()
    }
    transitionBar() {
        const { g } = this
        const t = transition().duration(800)
        const { value, tau, arcGenerator } = this.props

        g
            .transition(t)
            .duration(750)
            .attrTween('d', arcTween((value * tau) / 100, arcGenerator))
    }
    render() {
        const { svgWidth, svgHeight } = this.props
        return (
            <g
                className="front-bar-group"
                transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}
                ref={this.ref}
                fill={this.props.color}
            >
        
            </g>
        )
    }
}
export default Bar