import { createAsyncThunk } from '@reduxjs/toolkit'
import { showError, showInfo } from 'features/ui/uiSlice'
import api from 'utils/api'
import { buildUrl } from 'utils/helpers'

export const fetchAddresses = createAsyncThunk(
  'addresses/fetch_list',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const {
      search,
      address_type,
      show_devices,
      locality_uuid,
      addresses_uuids,
      service_partner_uuid,
      page = 1,
      limit = 25,
    } = params

    try {
      const apps = await api.request(buildUrl('/addresses', params))
      dispatch(showInfo(apps?.message))
      return apps
    } catch (err) {
      const { error } = await err.json()
      dispatch(showError(error))
      return rejectWithValue(err)
    }
  }
)


export const removeAddresses = createAsyncThunk(
  'addresses/remove_addresses',
  async ({ uuid, address_uuids }, { rejectWithValue, dispatch }) => {
    try {
      const addresses = await api.request(`/managment_companies/${uuid}/remove_addresses`, {
        method: 'PATCH',
        body: JSON.stringify({ address_uuids: address_uuids }),
      })
      dispatch(showInfo(addresses?.message))
      return addresses
    } catch (err) {
      const { error } = await err.json()
      dispatch(showError(error))
      return rejectWithValue(err)
    }
  }
)

export const addAddresses = createAsyncThunk(
  'addresses/add_addresses',
  async ({ uuid, address_uuids }, { rejectWithValue, dispatch }) => {
    try {
      const addresses = await api.request(`/managment_companies/${uuid}/add_addresses`, {
        method: 'PATCH',
        body: JSON.stringify({ address_uuids: address_uuids }),
      })
      dispatch(showInfo(addresses?.message))
      return addresses
    } catch (err) {
      const { error } = await err.json()
      dispatch(showError(error))
      return rejectWithValue(err)
    }
  }
)
