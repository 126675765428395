import * as actions from './actions'
import Api from 'utils/api'

export const createConversation = (msg) => async (dispatch: Function) => {
  dispatch(actions.createConversationRequest())
  try {
    await Api.request(`/intercom/conversations`, {
      method: 'POST',
      body: JSON.stringify({ body: msg }),
    })
    dispatch(actions.createConversationSuccess())
    return Promise.resolve()
  } catch (error) {
    console.log(error)
    // const { message } = await error.json()
    dispatch(actions.createConversationFailure())
    return Promise.reject()
  }
}
