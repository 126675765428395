import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { Typography, Tooltip } from '@material-ui/core'
import { FiberManualRecord, CreditCard } from '@material-ui/icons'
import { Wrapper } from 'sputnik-ui'
import { formatDate, lighterColor } from 'utils/helpers'

import type { IService } from 'globalTypes'

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const OnlineIcon = styled(FiberManualRecord)`
  /* color: ${p =>
    p.isOnline
      ? p.theme.palette.success['500']
      : p.theme.palette.error['500']}; */
 && {
  width: 8px;
  height: 16px;
  margin-right: 4px;
  animation: ${fade} 2s ease-in-out infinite alternate;
 }
`

type Props = IService & {
  dispatch: Function,
}

const PALE_PINK = '#eed7df'
const BRIGHT_PINK = '#ED4887'
const DEFAULT_TEXT = '#333'
const SHADOW_FACTOR = 'AE'

const ServiceBody = styled(Wrapper)`
  border: none;
  background: ${p =>
    p.isActive
      ? (p.colors &&
          `radial-gradient(ellipse farthest-corner at left top, ${
            p.colors.active_bg
          } 0%,${lighterColor(p.colors.active_bg, 0.2)} 100%);`) ||
        BRIGHT_PINK
      : (p.colors && p.colors.inactive_bg) || PALE_PINK};
  color: ${p =>
    p.isActive
      ? (p.colors && p.colors.active_text) || DEFAULT_TEXT
      : (p.colors && p.colors.inactive_text) || DEFAULT_TEXT};
  box-shadow: 0 35px 50px -30px ${p => (p.isActive ? (p.colors && p.colors.active_bg + SHADOW_FACTOR) || BRIGHT_PINK + SHADOW_FACTOR : (p.colors && p.colors.inactive_bg) || PALE_PINK)};
  flex-direction: column;
  height: 340px;
  transition: all 0.3s ease-in-out;
  /* transition-property: all !important; */

  &:hover {
    cursor: pointer;
    transform: translateY(-20px) !important;
    box-shadow: 0 55px 50px -17px ${p => (p.isActive ? (p.colors && p.colors.active_bg + SHADOW_FACTOR) || BRIGHT_PINK + SHADOW_FACTOR : (p.colors && p.colors.inactive_bg) || PALE_PINK)};
  }
`

const ServiceName = styled(Typography)`
  && {
    /* font-weight: 500; */
    /* font-size: 1.325rem; */
    overflow: hidden;
    /* line-height: 2.625rem; */
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow-x: hidden;
    /* height: 50px; */
  }
`

const ServicePrice = styled(Typography)`
  && {
    font-size: 1.125rem;
    /* font-weight: 400; */

    /* margin-top: 50px; */
  }
`

// const ServiceImage = styled.img`
//   stroke: currentColor;
//   fill: currentColor;
//   /* width: 60%; */
//   width: 64px;
//   height: 64px;
//   margin: 2rem auto;
//   /* height: auto; */
// `

const ServiceImage = styled.svg`
  /* stroke: none; */
  fill: currentColor;
  /* width: 60%; */
  width: 64px;
  height: 64px;
  margin: 2rem auto;
`

const ServiceDescription = styled(Typography)`
  && {
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 100px;
    font-size: 1rem;
  }
`

const NextPayment = styled(Typography)`
  && {
    /* font-weight: 300; */
    text-overflow: ellipsis;
    overflow: hidden;
    height: 20px;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export default function GlobalService({
  base_info: {
    cost,
    payment_name,
    payment,
    rate_type,
    description,
    service_address,
    colors,
    is_active,
    next_payment_date,
    image,
  },
  noPrice,
  ...props
}: Props) {
  useEffect(() => {}, [])
  return (
    <ServiceBody colors={colors} isActive={is_active} {...props}>
      <ServiceName color="inherit" variant="h5">
        {is_active && (
          <Tooltip title="услуга подключена" placement="top">
            <OnlineIcon color="inherit" />
          </Tooltip>
        )}
        {payment_name}
      </ServiceName>
      {!noPrice && <ServicePrice color="inherit">{cost / 100}₽</ServicePrice>}

      {/* <ServiceImage src={image} alt="" /> */}

      {/* <ServiceImage data={image}>img</ServiceImage> */}
      <ServiceImage>
        <use
          xlinkHref={`${window.location.origin}/images/${payment}.svg#Artboard`}
        />
      </ServiceImage>

      <ServiceDescription color="inherit">{description}</ServiceDescription>
      {next_payment_date && (
        <Tooltip title="дата следующего списания" placement="top-start">
          <NextPayment color="inherit">
            <CreditCard style={{ marginRight: 8 }} />{' '}
            {formatDate(next_payment_date)}
          </NextPayment>
        </Tooltip>
      )}
    </ServiceBody>
  )
}
