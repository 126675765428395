import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Typography, Box } from '@material-ui/core'
import { Button } from 'sputnik-ui'
import CustomizationForm from './CustomizationForm'
import { useSelector, useDispatch } from 'react-redux'
import {
  addCreditToCurrent,
  addProductToCurrent,
  clearCurrent,
  customizationEditMode,
  editCurrentTitle,
  initCurrent,
} from './customizationSlice'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* border: 1px solid #ededed; */
  width: 100%;
  border-radius: 8px;
`

const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('введите название'),
  })

function CustomizationForms({ ...props }) {
  const { t } = useTranslation(['customization'])
  const dispatch = useDispatch()
  const current = useSelector(({ customization }) => customization.current)
  const customizationTitle = useSelector(
    ({ customization }) => customization.current?.title
  )
  const credits = useSelector(
    ({ customization }) => customization.current?.credits
  )
  const products = useSelector(
    ({ customization }) => customization.current?.products
  )

  useEffect(() => {
    if (!current) {
      const data = {
        id: Math.random(),
        title: '',
        credits: [
          {
            id: Math.random(),
            title: '',
            description: '',
            phone: '',
            website: '',
            img: '',
            // err: { init: 'введите название' },
          },
        ],
        products: [],
      }
      dispatch(initCurrent(data))
    }

    return () => {
      dispatch(clearCurrent())
      dispatch(customizationEditMode({ editMode: false }))
    }
  }, [])

  const handleClickServices = () => {
    dispatch(addProductToCurrent())
  }

  const handleClick = () => {
    dispatch(addCreditToCurrent())
  }

  const { values, handleBlur, touched, errors, handleChange } = useFormik({
    initialValues: {
      title: customizationTitle,
    },
    validationSchema: validationSchema,
  })

  const handleChangeState = (e) => {
    if (e?.target) {
      dispatch(
        editCurrentTitle({
          id: current.id,
          value: e.target.value,
          name: e.target.name,
        })
      )
    }
  }

  return (
    <Wrapper {...props}>
      <form style={{ width: '100%' }}>
        <TextField
          style={{ minWidth: 290 }}
          inputProps={{
            style: {
              fontSize: 25,
              fontWeight: '600',
            },
          }}
          value={values?.title}
          label={t('customization:form.title')}
          onChange={(e) => {
            handleChange(e)
            handleChangeState(e)
          }}
          error={touched.title && !!errors.title}
          helperText={touched.title && errors.title}
          onBlur={handleBlur}
          required
          name="title"
          autoFocus
          placeholder={t('customization:form.placeholder')}
        />

        <Typography
          color="textSecondary"
          style={{ fontSize: '0.875rem', margin: '1.5rem 0' }}
        >
          {t('customization:form.credit.info')}
        </Typography>
        {credits &&
          credits?.map((el, key) => {
            return (
              <>
                {key > 0 ? (
                  <hr
                    style={{
                      width: '100%',
                      background: '#ededed',
                      border: 0,
                      height: '1px',
                    }}
                  />
                ) : null}
                <CustomizationForm key={el.id} card={el} index={key} />
              </>
            )
          })}
      </form>

      <Box display="flex" alignItems="center">
        <Button
          onClick={handleClick}
          disabled={credits?.length >= 3}
          variant="v2"
          secondary
          style={styles.btn}
        >
          {t('customization:button.add_credit')}
        </Button>
        <Button
          onClick={handleClickServices}
          style={styles.btn}
          disabled={products?.length > 0}
          variant="v2"
          secondary
        >
          {t('customization:button.add_product')}
        </Button>
      </Box>
    </Wrapper>
  )
}

const styles = {
  btn: {
    marginRight: '1rem',
  },
}

export default CustomizationForms
