//@flow
import * as actions from './actions'
import Api from 'utils/api'
import { buildUrl } from 'utils/helpers'
import config from 'config'

export const fetchTransactions =
  (year: string = new Date().getFullYear().toString(), page = 0, limit = 10) =>
  async (dispatch: Function) => {
    dispatch(actions.fetchTransactionsRequest())
    try {
      const setParams = () => {
        let params = []
        if (year) params.push(`year=${year}`)
        if (page) params.push(`page=${page + 1}`)
        if (limit) params.push(`limit=${limit}`)
        return params.join('&')
      }

      const transactions = await Api.request(
        buildUrl(`/billing/operations/days?${setParams()}`),
        {
          dispatch,
        },
        `${config.redirectUri}/api/v2`
      )

      dispatch(actions.fetchTransactionsSuccess(transactions))
    } catch (error) {
      dispatch(
        actions.fetchTransactionsFailure(
          'Не удалось получить историю операций. Попробуйте обновить страницу.'
        )
      )
    }
  }

export const fetchStatistics = (year: string) => async (dispatch: Function) => {
  dispatch(actions.fetchStatisticsRequest())
  try {
    const stats = await Api.request(
      `/billing/operations/statistics?year=${year}`
    )
    dispatch(actions.fetchStatisticsSuccess(stats))
  } catch (error) {
    dispatch(
      actions.fetchStatisticsFailure(
        'Проверьте ваше интернет соединение и попробуйте обновить страницу.'
      )
    )
  }
}

export const fetchAvailablePeriod = () => async (dispatch: Function) => {
  dispatch(actions.fetchAvailablePeriodRequest())
  try {
    const period = await Api.request(`/billing/operations/years`)
    dispatch(actions.fetchAvailablePeriodSuccess(period))
  } catch (error) {
    dispatch(
      actions.fetchAvailablePeriodFailure(
        'Проверьте ваше интернет соединение и попробуйте обновить страницу.'
      )
    )
  }
}

export const makeDeposit =
  (params: {
    operation_type: 'card' | 'direct_deposit',
    total: string,
    app_id: ?string,
    quantity: ?string,
    use_payment_method: ?boolean,
    phone: string,
  }) =>
  async (dispatch: Function) => {
    dispatch(actions.makeDepositRequest())
    try {
      const { operation_id, redirect_url } = await Api.request(
        `/partners/invoices`,
        {
          method: 'POST',
          body: JSON.stringify(params),
        }
      )

      if (redirect_url) {
        // dispatch(actions.makeDepositSuccess(''))
        dispatch(actions.makeDepositSuccess(null))

        window.location = redirect_url
        return Promise.resolve({})
      } else {
        dispatch(actions.makeDepositSuccess('Баланс был успешно пополнен'))
        return Promise.resolve({ operation_id })
      }
    } catch (error) {
      const { message } = await error.json()
      dispatch(actions.makeDepositFailure(message))
      return Promise.reject()
    }
  }

export const changeBillingAccountInfo =
  (body: Object) => async (dispatch: Function) => {
    dispatch(actions.changeBillingAccountInfoRequest())
    try {
      const updatedAccount = await Api.request(`/users/me/billing_account`, {
        method: 'PATCH',
        body: JSON.stringify(body),
      })

      dispatch(
        actions.changeBillingAccountInfoSuccess(
          'Информация о платежном аккаунте была успешно обновлена',
          updatedAccount
        )
      )
      return Promise.resolve()
    } catch (error) {
      const r = await error.json()
      dispatch(
        actions.changeBillingAccountInfoFailure(Object.values(r.message)[0])
      )
      return Promise.reject()
    }
  }

export const fetchBillingAccount = () => async (dispatch: Function) => {
  dispatch(actions.fetchBillingAccountRequest())
  try {
    const account = await Api.request(`/users/me/billing_account`)

    dispatch(actions.fetchBillingAccountSuccess(account))
    return Promise.resolve()
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.fetchBillingAccountFailure(message))
    return Promise.reject()
  }
}

export const unbindPaymentMethod = () => async (dispatch: Function) => {
  dispatch(actions.unbindPaymentMethodRequest())
  try {
    await Api.request(`/billing/accounts/unbind_payment_method`, {
      method: 'POST',
    })

    dispatch(actions.unbindPaymentMethodSuccess('Карта была успешно отвязана'))
    return Promise.resolve()
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.unbindPaymentMethodFailure(message))
    return Promise.reject()
  }
}
