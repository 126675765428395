import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import AddBillingAccountForm from 'components/organisms/BillingAccountForm'
import BillingAccountInfo from 'components/organisms/BillingAccountInfo'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function BillingAccountModal({ ...props }) {
  const billingAccount = useSelector(({ billing }) => billing.account)

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (billingAccount) {
      const notFilled =
        !billingAccount?.company_name ||
        billingAccount?.company_name === '' ||
        !billingAccount?.phone_number ||
        billingAccount?.phone_number === ''

    //   console.log(notFilled, billingAccount)
      setOpen(notFilled)
    }
  }, [billingAccount])

  return (
    <Dialog open={open}>
      <DialogTitle>Пожалуйста, заполните платежную информацию</DialogTitle>
      <BillingAccountInfo />
    </Dialog>
  )
}
