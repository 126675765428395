import camelCase from 'lodash/camelCase'
import { combineReducers } from 'redux'
import devices from './devicesSlice'
import localities from './localitiesSlice'
import servicePartners from './servicePartnersSlice'
import ui from 'features/ui/uiSlice'
import servicesNew from 'features/Services/servicesSlice'
import analytics from 'features/analytics/analyticsSlice'
import roadmap from '../features/RoadMap/RoadMapSlice'
import customization from 'features/Customization/customizationSlice'
import { permissionsReducer } from './permissions/slice'
import { addressesReducer } from './addresses/addressesSlice'

const reducers = {
  devices,
  localities,
  servicePartners,
  ui,
  servicesNew,
  analytics,
  roadmap,
  customization,
  permissions: permissionsReducer,
  addresses: addressesReducer,
}

const req = require.context('.', true, /\.\/.+\/reducers\.js$/)

req.keys().forEach((key) => {
  const storeName = camelCase(key.replace(/\.\/(.+)\/.+$/, '$1'))
  reducers[storeName] = req(key).default
})

export default combineReducers(reducers)
