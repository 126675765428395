//@flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import { theme, i18n, Loader, ThemeProvider, Metrika } from 'sputnik-ui'
import { Provider } from 'react-redux'
import store from 'store/store'

import { BrowserRouter } from 'react-router-dom'
import { unregister } from './serviceWorker'
import App from './App'
import { I18nextProvider } from 'react-i18next'
import { showUpdateMessage } from 'features/ui/uiSlice'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/ru'

dayjs.extend(calendar)
dayjs.extend(updateLocale)

dayjs.locale('ru')

dayjs.updateLocale('ru', {
  calendar: {
    sameDay: '[Сегодня]',
    nextDay: 'Завтра',
    lastDay: 'Вчера',
    nextWeek: 'DD MMMM',
    lastWeek: 'DD MMMM',
    sameElse: 'DD MMMM',
  },
  monthsShort: [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ]
})

const Root = (): React.Node => (
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <React.Suspense fallback={<Loader />}>
            <App />
            <Metrika />
          </React.Suspense>
        </I18nextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
)

const node = document.getElementById('root')

if (node === null) {
  throw new Error('no node element')
}

ReactDOM.render(<Root />, node)

// const showUpdateToast = (action) => store.dispatch(showUpdateMessage(action))
// registerServiceWorker(showUpdateToast)
unregister()
