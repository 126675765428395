import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

const gradient = css`
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(38, 249, 211, 0.106) 100%
    ),
    linear-gradient(
      184.05deg,
      rgba(255, 0, 0, 0.2) -8.71%,
      rgba(255, 0, 0, 0.2) -5.96%,
      rgba(0, 0, 0, 0) 96.69%
    ),
    #2a225a;
`

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  font-family: 'Play';
  display: flex;
  font-weight: 700;
  flex-direction: column;
`

const ErrorCode = styled.h1`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  ${gradient}
  margin: 0px;
  height: fit-content;
  font-size: 380px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    font-size: 200px;
  }
`

const Text = styled.div`
  margin-top: 80px;
  text-align: center;
  color: white;
  font-size: 40px;
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    font-size: 25px;
    margin-top: 40px;
  }
`

const HelperText = styled.p`
  margin-top: 32px;
  color: ${(p) => p.theme.palette.color.secondary};
  font-weight: 400;
  font-size: 24px;
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    font-size: 16px;
    margin-top: 24px;
  }
`

const StyledSection = styled.section`
  padding: 0 5%;
  position: absolute;
  bottom: 0;
  top: calc(50% + 80px);
  /* flex-shrink: 1; */
  width: 100%;
  flex-direction: column;
  align-items: center;
  ${gradient}
`

function AccessError({ code }) {
  return (
    <Wrapper>
      <ErrorCode>{code || 403}</ErrorCode>
      <StyledSection>
        <Text>
          Упс, к сожалению у вас нет доступа к <br /> Спутник Про.
        </Text>

        <HelperText>
          Вы можете приобрести данный тариф, написав нам на почту{' '}
          <Link
            style={{ color: '#0EA7FF' }}
            onClick={(e) => {
              window.open('mailto:sales@sputnik.systems')
              e.preventDefault()
            }}
          >
            sales@sputnik.systems
          </Link>{' '}
          в
          <br /> коммерческий отдел.
        </HelperText>
      </StyledSection>
    </Wrapper>
  )
}

export default AccessError
