import React, { useState, useEffect, useCallback } from 'react'
import { Button, Select, Loader } from 'sputnik-ui'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'

import {
  getservicePartners,
  linkDevice,
  getIntercomsByAddress,
  getCamerasByAddress,
} from 'store/operations'
import { useTranslation } from 'react-i18next'
import {
  fetchPartners,
  reassignDevices,
} from 'store/service_partnersOld/operations'

import { fetchDevice, fetchDevices } from 'store/devicesSlice'
import { FullscreenDialogTitle } from 'sputnik-ui'
import { FullscreenExitButton } from 'sputnik-ui'
import { List } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import Device from 'features/Devices/Device'
import { fetchServices } from 'store/services/operations'
import Overlay from 'components/atoms/Overlay'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

export default function AddServiceButchModal({
  onClose,
  device,
  open,
  ...props
}) {
  const dispatch = useDispatch()
  const { device: deviceUuid } = useParams()

  const handleSubmit = useCallback(() => {}, [])

  const { t } = useTranslation(['common', 'service'])

  const selectedDevices = useSelector(({ devices }) =>
    Object.values(devices.selected)
  )
  const services = useSelector(({ services }) => services.list)

  React.useEffect(() => {
    dispatch(fetchServices())
  }, [dispatch])

  const [selectedService, setSelectedService] = React.useState(false)

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <FullscreenExitButton onClick={onClose}></FullscreenExitButton>
      <FullscreenDialogTitle subtitle="Выберите услугу">
        {t('service:actions.add_services_butch')}
      </FullscreenDialogTitle>
      <DialogContent>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div style={{ position: 'relative' }}>
            <List style={{ borderRight: '1px solid #333' }}>
              {selectedDevices?.map((d) => (
                <Device {...d} key={d.uuid}></Device>
              ))}
            </List>
            <Overlay></Overlay>
          </div>
          <List>
            {[{}, {}, {}].map((s) => (
              <ListItem>
                <ListItemText primary="123"></ListItemText>
              </ListItem>
            ))}
          </List>
        </div>
      </DialogContent>

      <DialogActions
        style={{
          justifyContent: 'center',
          margin: '1.325rem',
          marginTop: 'auto',
        }}
      >
        <Button
          onClick={handleSubmit}
          primary
          rounded
          variant="contained"
          size="fullScreen"
        >
          {t('common:button:confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
