export const INVITE_PARTNER_REQUEST = 'service_partners/INVITE_PARTNER_REQUEST'
export const INVITE_PARTNER_SUCCESS = 'service_partners/INVITE_PARTNER_SUCCESS'
export const INVITE_PARTNER_FAILURE = 'service_partners/INVITE_PARTNER_FAILURE'

export const REASSIGN_DEVICES_REQUEST =
  'service_partners/REASSIGN_DEVICES_REQUEST'
export const REASSIGN_DEVICES_SUCCESS =
  'service_partners/REASSIGN_DEVICES_SUCCESS'
export const REASSIGN_DEVICES_FAILURE =
  'service_partners/REASSIGN_DEVICES_FAILURE'

export const FETCH_PARTNERS_REQUEST = 'service_partners/FETCH_PARTNERS_REQUEST'
export const FETCH_PARTNERS_SUCCESS = 'service_partners/FETCH_PARTNERS_SUCCESS'
export const FETCH_PARTNERS_FAILURE = 'service_partners/FETCH_PARTNERS_FAILURE'
