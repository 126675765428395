//@flow
import React from 'react'
import styled from 'styled-components'
import { Transaction } from 'components'
import { Table, TableRow, TableBody, Typography } from '@material-ui/core'
import type { ITransaction } from 'globalTypes'

const StyledTableRow = styled(TableRow)`
  &&& {
    &:hover {
      background-color: rgba(
        ${p =>
          p.status === 'error'
            ? '255,0,0'
            : p.status === 'success'
            ? '0,255,0'
            : '0,0,0'},
        0.07
      );
    }
  }
`

const TransactionsTable = ({ transactions, onApprove, classes }: Props) => {
  return (
    <Table style={styles.table}>
      <TableBody>
        {transactions.length > 0 ? (
          transactions.map(transaction => (
            <StyledTableRow
              key={transaction.operation_id}
              hover
              status={transaction.status}
            >
              <Transaction onApprove={onApprove} transaction={transaction} />
            </StyledTableRow>
          ))
        ) : (
          <Typography align="center">
            Вы не совершили ни одной операции
          </Typography>
        )}
      </TableBody>
    </Table>
  )
}

type Props = {
  transactions: ITransaction[],
  isSalesman: boolean,
  onApprove?: Function,
}

const styles = {
  table: {
    // tableLayout: 'fixed',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
  },
}

export default TransactionsTable
