import { useEffect, useRef } from 'react'
import { select } from 'd3-selection'
import { arc } from 'd3-shape'
import FrontBar from './FrontBar.js'
import { transition } from 'd3-transition'
import { interpolateNumber } from 'd3-interpolate'
import { format } from 'd3-format'
import { useState } from 'react'
import { Typography } from '@material-ui/core'

const formatNumber = format(',d')

function ProgressBar({ progress, color, bgColor, img, bold }) {
  const [value, setValue] = useState(0)
  const ref = useRef()

  useEffect(() => {
    setValue(progress)
    renderText(progress)
  }, [progress])

  function renderText(value) {
    const node = ref.current
    const t = transition().duration(800)

    select(node)
      .transition(t)
      .tween('text', function () {
        const that = select(this)
        const i = interpolateNumber(that.text().replace(/,/g, ''), value)
        return function (t) {
          that.text(formatNumber(i(t)))
        }
      })
  }

  const tau = 2 * Math.PI
  const svgWidth = 150
  const svgHeight = svgWidth
  const arcWidth = bold || 12
  const arcOuterRadius = svgWidth / 2
  const arcInnerRadius = svgWidth / 2 - arcWidth

  const arcGenerator = arc()
    .innerRadius(arcInnerRadius)
    .outerRadius(arcOuterRadius)
    .startAngle(0)
    .cornerRadius(12)

  const num = {
    position: 'absolute',
    top: svgHeight / 2,
    left: svgWidth / 2,
  }

  return (
    <div>
      <div className="relative">
        <div
          style={{
            position: 'absolute',
            width: `${svgWidth}px`,
            height: `${svgHeight}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {img ? (
            <img src={img} height="130" width="130" />
          ) : (
            <div
              style={{
                color: `${color}`,
                whiteSpace: 'nowrap',
                display: 'flex',
              }}
            >
              {isNaN(progress) ? (
                <Typography
                  variant="h5"
                  style={{ fontSize: '3rem', fontWeight: '700' }}
                >
                  ?
                </Typography>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    style={{ fontSize: '2rem', fontWeight: '700' }}
                    ref={ref}
                  >
                    0
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ fontSize: '2rem', fontWeight: '700' }}
                  >
                    %
                  </Typography>
                </>
              )}
            </div>
          )}
        </div>
        <svg height={svgHeight} width={svgWidth}>
          <g
            transform={`translate(${svgWidth / 2}, ${svgHeight / 2})`}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <path d={arcGenerator({ endAngle: tau })} fill={bgColor} />
          </g>
          <FrontBar
            color={color}
            arcGenerator={arcGenerator}
            value={value}
            tau={tau}
            svgWidth={svgWidth}
            svgHeight={svgHeight}
          />
        </svg>
      </div>
    </div>
  )
}

export default ProgressBar
