import * as types from './types'

export const invitePartnerRequest = () => ({
  type: types.INVITE_PARTNER_REQUEST,
})
export const invitePartnerSuccess = msg => ({
  type: types.INVITE_PARTNER_SUCCESS,
  payload: { msg },
})
export const invitePartnerFailure = error => ({
  type: types.INVITE_PARTNER_FAILURE,
  payload: {
    error,
  },
})

export const reassignDevicesRequest = () => ({
  type: types.REASSIGN_DEVICES_REQUEST,
})
export const reassignDevicesSuccess = msg => ({
  type: types.REASSIGN_DEVICES_SUCCESS,
  payload: { msg },
})
export const reassignDevicesFailure = error => ({
  type: types.REASSIGN_DEVICES_FAILURE,
  payload: {
    error,
  },
})

export const fetchPartnersRequest = () => ({
  type: types.FETCH_PARTNERS_REQUEST,
})
export const fetchPartnersSuccess = partners => ({
  type: types.FETCH_PARTNERS_SUCCESS,
  payload: { partners },
})
export const fetchPartnersFailure = error => ({
  type: types.FETCH_PARTNERS_FAILURE,
  payload: {
    error,
  },
})
