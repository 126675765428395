import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'
import { clearDevices } from './devicesSlice'

export const fetchLocalities = createAsyncThunk(
  'localities/fetchList',
  async (params = {}, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        buildUrl('/localities', params),
        {
          dispatch,
        },
        `${config.redirectUri}/api/v2`
      )
      return payload
    } catch {
      return rejectWithValue()
    }
  }
)

const placeholder = { value: undefined, label: 'Весь фонд' }

// reducers
export const localitiesSlice = createSlice({
  name: 'localities',
  initialState: {
    list: null,
    page: 1,
    limit: 100,
    search: '',
    filter: placeholder,
  },
  reducers: {
    [clearDevices.type]: (state) => {
      state.list = []
      state.filter = placeholder
    },
    incPage: (state) => {
      state.page = clamp(state.page + 1, 1, state.pages)
    },
    decPage: (state) => {
      state.page = clamp(state.page - 1, 1, state.pages)
    },
    setSearch: (state, { payload }) => {
      state.search = payload
    },
    setLocalityFilter: (state, { payload }) => {
      state.filter = payload
    },
  },
  extraReducers: {
    [fetchLocalities.fulfilled]: (state, { payload }) => {
      state.list = [
        placeholder,
        ...payload.map((l) => ({ label: l.name, value: l.uuid })),
      ]
    },
  },
})

export const { incPage, decPage, setLocalityFilter } = localitiesSlice.actions

export default localitiesSlice.reducer
