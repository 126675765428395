import React from 'react'
import { Toolbar, Typography, Tooltip, IconButton } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { SubdirectoryArrowRight } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

const StyledToolbar = styled(Toolbar)`
  padding-right: 1rem;
  border-radius: 8px;
  ${p =>
    p.numSelected > 0 &&
    css`
      background-color: ${p => p.theme.palette.other.green};
    `}
`
const Actions = styled.div`
  display: flex;
  color: ${p => p.theme.palette.text.primary};
  align-items: center;
`

const TableToolbar = props => {
  const { selected } = props
  const numSelected = selected.length

  const { t } = useTranslation('service')

  return (
    <StyledToolbar numSelected={numSelected}>
      <div style={{ flex: '0 0 auto' }}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} выбрано
          </Typography>
        ) : (
          <Typography variant="h5">Устройства</Typography>
        )}
      </div>
      <div
        style={{
          flex: '1 1 100%',
        }}
      />
      <Actions>
        {numSelected > 0 && (
          <>
            <Tooltip title={t('service:actions.reassign')}>
              <IconButton
                aria-label={t('service:actions.reassign')}
                onClick={props.openReassignIntercomModal}
              >
                <SubdirectoryArrowRight />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Actions>
    </StyledToolbar>
  )
}

export default TableToolbar
