import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  ServicesTemplate,
  GlobalService,
  SubscriptionPlan,
  ServicePage,
  SubscriptionPage,
} from 'components'

import { fetchServices, fetchPackages } from 'store/operations'
import { Link, withRouter, Route } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useTransition, animated, config } from 'react-spring'

type Props = {
  dispatch: Function,
}

function ServicesPage({ dispatch, match, services, packages }: Props) {
  useEffect(() => {
    dispatch(fetchServices())
    dispatch(fetchPackages())
  }, [dispatch])

  const transitions = useTransition(packages, item => item.base_info.app_id, {
    from: { opacity: 0, transform: 'scale3d(0.6,0.6,0.6)' },
    leave: { opacity: 0, transform: 'scale3d(0.6,0.6,0.6)' },
    enter: { opacity: 1, transform: 'scale3d(1,1,1)' },
    config: {
      mass: 2,
      tension: 60,
      friction: 26,
      precision: 0.01,
      velocity: 15,
    },
    trail: 400 / packages.length,
  })
  return (
    <ServicesTemplate
      plans={
        packages && packages.length > 0 ? (
          // packages.map(plan => (
          //   <SubscriptionPlan key={plan.base_info.app_id} {...plan} />
          // ))
          transitions.map(({ item, props, key }) => (
            <animated.div key={key} style={props}>
              <SubscriptionPlan key={item.base_info.app_id} {...item} />
            </animated.div>
          ))
        ) : (
          <>
            <Skeleton height={462} width={300} />
            <Skeleton height={462} width={300} />
            <Skeleton height={462} width={300} />
          </>
        )
      }
      services={
        services && services.length > 0 ? (
          services.map(service => (
            <Link
              to={`${match.url}/s/${service.base_info.app_id}`}
              style={{ textDecoration: 'none' }}
              key={service.base_info.app_id}
            >
              <GlobalService {...service} />
            </Link>
          ))
        ) : (
          <>
            <Skeleton height={340} />
            <Skeleton height={340} />
            <Skeleton height={340} />
          </>
        )
      }
    >
      <Route path="/subscriptions/s/:app_id" component={ServicePage} />
      <Route path="/subscriptions/p/:app_id" component={SubscriptionPage} />
    </ServicesTemplate>
  )
}

const mapStateToProps = ({
  services: { list, packages },
}: {
  services: { list: Object[], packages: Object[] },
}): Props => ({
  services: list,
  packages,
})

export default connect(mapStateToProps)(withRouter(ServicesPage))
