//$FlowFixMe
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ym from 'react-yandex-metrika'
import {
  Button,
  Loader,
  FullscreenExitButton,
  FullscreenDialogTitle,
} from 'sputnik-ui'
import { DepositForm } from 'components'
import { Dialog, DialogActions, Fade } from '@material-ui/core'
import SuccessfullPaymentForm from 'components/molecules/SuccessfullPaymentForm'

import { makeDeposit } from 'store/operations'
import {
  fetchTransactions,
  fetchBillingAccount,
} from 'store/billing/operations'

const Transition = props => <Fade in={true} {...props} />

const DepositModal = ({ open = true, isLoading, history, dispatch }: Props) => {
  const [invoiceUuid, setInvoiceUuid] = useState(null)

  const handleDeposit = ({
    total,
    paymentMethod,
    usePaymentCard,
    phoneNumber,
  }) => {
    dispatch(
      makeDeposit({
        total,
        operation_type: paymentMethod,
        use_payment_method: usePaymentCard,
        phone_number: phoneNumber,
      }),
    ).then(({ redirect_url, operation_id }) => {
      if (paymentMethod === 'direct_deposit') {
        return setInvoiceUuid(operation_id)
      }
      dispatch(fetchBillingAccount())
      dispatch(fetchTransactions())
      history.push('/billing')
    })
  }
  const handleClose = () => history.push('/billing')

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      onExit={() => setInvoiceUuid(null)}
      fullScreen
    >
      <FullscreenExitButton onClick={handleClose} />
      <FullscreenDialogTitle
        subtitle={
          invoiceUuid &&
          'Ваша заявка отправлена в обработку. Ожидайте подтверждения.'
        }
      >
        {invoiceUuid ? 'Успех!' : 'Пополнение баланса'}
      </FullscreenDialogTitle>
      {!invoiceUuid ? (
        <>
          <DepositForm>
            {({ total, paymentMethod, usePaymentCard, phoneNumber }) => (
              <DialogActions
                style={{ justifyContent: 'center', margin: '1.325rem' }}
              >
                <Button
                  rounded
                  color="primary"
                  size="fullScreen"
                  variant="contained"
                  onClick={() => {
                    // ym('reachGoal','partner_billing_kassaOut')
                    handleDeposit({
                      total,
                      paymentMethod,
                      usePaymentCard,
                      phoneNumber,
                    })
                  }}
                  disabled={!total || isLoading}
                >
                  Перейти к оплате
                </Button>
              </DialogActions>
            )}
          </DepositForm>
        </>
      ) : (
        <>
          <SuccessfullPaymentForm
            invoiceUuid={invoiceUuid}
            handleClose={handleClose}
          />
        </>
      )}

      {isLoading && (
        <Loader
          msg="Выполняем операцию..."
          style={{
            position: 'absolute',
            background: 'rgba(0,0,0,0.65)',
            color: 'white',
          }}
          textProps={{ variant: 'h5' }}
        />
      )}
    </Dialog>
  )
}

type Props = {
  open: boolean,
  handleClose: Function,
  dispatch: Function,
  history: Object,
  isLoading: boolean,
}

const mapStateToProps = ({ billing }) => ({
  isLoading: billing.loading,
})

export default connect(mapStateToProps)(withRouter(DepositModal))
