import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Select,
  Loader,
  Button,
  FullscreenDialogTitle,
  FullscreenExitButton,
} from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Fade,
  Typography,
} from '@material-ui/core/'
import { Send } from '@material-ui/icons'
import { Formik } from 'formik'
import * as Yup from 'yup'
import config from 'config'

import { getApps, invitePartner, getPartners } from 'store/operations'

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Transition = props => <Fade in {...props} />

const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .typeError('введите e-mail')
      .email('неверный формат')
      .required('введите e-mail'),
    name: Yup.string().required('введите имя/название организации'),
  })

class InvitePartnerModal extends React.Component {
  state = {}

  componentDidMount() {}

  handleinvitePartner = values => {
    this.props.dispatch(invitePartner(values)).then(() => {
      this.props.handleClose()
    })
  }

  toggleModal = modal => () => this.setState({ [modal]: !this.state[modal] })

  render() {
    const { open, handleClose } = this.props

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullScreen
      >
        <FullscreenDialogTitle subtitle="На указанный e-mail поступит приглашение к регистрации. После приглашения сервис-партнёра вы сможете передать ему в управление часть своих устройств">
          Пригласить сервис-партнёра
        </FullscreenDialogTitle>
        <FullscreenExitButton onClick={handleClose} />
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={this.handleinvitePartner}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          render={({
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContent
                style={{
                  maxWidth: '40rem',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '0 auto',
                }}
              >
                <TextField
                  autoFocus
                  fullWidth
                  name="email"
                  value={values.email}
                  label="Электронная почта"
                  onChange={handleChange}
                  error={touched.email && !!errors.email}
                  autoComplete="email"
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                  margin="dense"
                  style={{ marginBottom: '1rem' }}
                />
                <TextField
                  fullWidth
                  name="name"
                  value={values.name}
                  label="Имя/название организации"
                  onChange={handleChange}
                  error={touched.name && !!errors.name}
                  autoComplete="name"
                  helperText={
                    (touched.name && errors.name) ||
                    'Используйте для удобного распределения домофонов по территории.'
                  }
                  onBlur={handleBlur}
                  margin="dense"
                  placeholder="город, район или принадлежность к УК"
                  style={{ marginBottom: '1rem' }}
                />
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: 'center',
                  margin: '1.325rem',
                  marginTop: 'auto',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="fullScreen"
                  type="submit"
                  rounded
                >
                  <Send style={{ marginRight: '8px' }} />
                  Пригласить
                </Button>
              </DialogActions>
            </Form>
          )}
        />
      </Dialog>
    )
  }
}

export default connect()(withRouter(InvitePartnerModal))
