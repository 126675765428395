import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Loader, Select } from 'sputnik-ui'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTransition, animated } from 'react-spring'
import styled from 'styled-components'
import CustomizationTemplate from 'features/Customization/CustomizationTemplate'
import CustomizationProductForm from 'features/Customization/CustomizationProductForm'
import CustomizationForms from 'features/Customization/CustomizationForms'
import CustomizationAddressList from 'features/Customization/CustomizationAddressList'
import CustomizationPhone from 'features/Customization/CustomizationPhone'
import Toggle from 'components/atoms/Toggle'
import Tab from 'components/atoms/TabItem'
import Patterns from 'features/Customization/Patterns'
import {
  fetchCustomizations,
  createCustomization,
  editCustomization,
} from 'features/Customization/customizationSlice'
import CustomizationModal from 'features/Customization/CustomizationModal'
import CustomizationUnLinkModal from 'features/Customization/CustomizationUnLinkModal'
import CustomizationFilterBlock from 'features/Customization/CustomizationFilterBlock'
import { showError } from 'features/ui/uiSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import AccessError from 'components/atoms/AccessError'

const StyledButton = styled(Button)`
  &&& {
    display: flex;
    margin-right: 1rem;
    white-space: nowrap;
    @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
      margin-bottom: 1rem;
      width: 100%;
    }
    @media screen and (max-width: ${(p) => p.theme.sizes.phone}) {
      margin-right: 0;
    }
  }
`

function CustomizationPage({ match, location, ...props }) {
  const { t } = useTranslation(['customization'])
  const dispatch = useDispatch()
  const history = useHistory()
  const [subscribePro, setSubscribePro] = useState(null)
  const patternsList = useSelector(({ customization }) => customization.list)
  const addresses = useSelector(({ customization }) => customization.addresses)
  const [openModal, setOpenModal] = useState(false)
  const [openUnLinkModal, setOpenUnLinkModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [picture, setPicture] = useState(true)
  const [filterWindow, setFilterWindow] = useState(false)
  const [addressParams, setAddressParams] = useState({
    page: 0,
    limit: 10,
    with_customization: null,
    search: '',
  })

  const selectedEntries = useSelector(
    ({ customization }) => customization.selectedEntries
  )

  const current = useSelector(({ customization }) => customization.current)
  const isEditMode = useSelector(({ customization }) => customization.editMode)
  const pending = useSelector(({ customization }) => customization.pending)
  const fieldsErr =
    !!current?.credits
      ?.map((el) => el?.err)
      ?.filter((el) => {
        return el !== null && el !== undefined
      })?.length > 0

  useEffect(() => {
    dispatch(fetchCustomizations())
      .then(unwrapResult)
      .then(() => {
        setSubscribePro(true)
      })
      .catch((el) => {
        if (el.status === 403) {
          setSubscribePro(false)
        }
      })
  }, [])

  const fadeLeft = useTransition(true, null, {
    from: { opacity: 0, transform: 'translate3d(450px,0,0)' },
    leave: { opacity: 0, transform: 'translate3d(450px,0,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  })

  const fadeRight = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0, transform: 'translate3d(-250px,0,0)' },
    leave: {
      display: 'none',
    },
    enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  })

  const handleSave = async () => {
    let checkpointReached = false
    setLoading(true)
    try {
      let slice
      if (isEditMode) {
        slice = await dispatch(editCustomization(current))
      } else {
        slice = await dispatch(createCustomization(current))
      }
      if (slice?.meta?.rejectedWithValue) throw new Error('error')
    } catch (error) {
      checkpointReached = true
    } finally {
      await dispatch(fetchCustomizations())
      setLoading(false)
      if (!checkpointReached) !pending && history.push('/customization')
    }
  }

  const selectedAddressesWithCuz = addresses?.addresses.filter(
    (el) => el?.customization && selectedEntries.includes(el.uuid)
  )
  const handleChangeAddressParams = (params) => {
    setAddressParams(params)
  }

  const handleOpenModal = () => {
    if (patternsList?.length && !selectedAddressesWithCuz?.length) {
      setOpenModal(true)
    } else if (!patternsList?.length) {
      dispatch(showError('У вас нет шаблонов'))
    } else if (selectedAddressesWithCuz?.length) {
      dispatch(showError('Сначала открепите старый шаблон'))
    } else {
      dispatch(showError('Error'))
    }
  }
  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleOpenUnLinkModal = () => {
    selectedAddressesWithCuz?.length
      ? setOpenUnLinkModal(true)
      : dispatch(showError('На этих адресах шаблонов нет'))
  }
  const handleCloseUnLinkModal = () => {
    setOpenUnLinkModal(false)
  }

  const toggleFilter = () => {
    setFilterWindow(!filterWindow)
  }

  const handleSearchAddress = (q) => {
    setAddressParams({ ...addressParams, search: q })
  }

  if (typeof subscribePro === 'boolean' && !subscribePro && !pending) {
    return <AccessError />
  }

  return (
    <CustomizationTemplate
      {...props}
      tabs={
        <>
          <Tab exact to={`${match.url}`}>
            {t('customization:tabs.patterns')}
          </Tab>
          <Tab to={`${match.url}/address`}>
            {t('customization:tabs.addresses')}
          </Tab>
        </>
      }
      title={t('customization:title')}
      buttonsList={
        <>
          {location.pathname === '/customization/address' ? (
            <>
              <StyledButton
                onClick={handleOpenUnLinkModal}
                tertiary
                disabled={!selectedEntries.length > 0}
              >
                {t('customization:button.unlink_template')}
              </StyledButton>
              <StyledButton
                onClick={handleOpenModal}
                tertiary
                disabled={!selectedEntries.length > 0}
              >
                {t('customization:button.connect_template')}
              </StyledButton>
              <div style={{ position: 'relative' }}>
                <StyledButton onClick={toggleFilter} rounded tertiary>
                  <img
                    src="/images/filter.svg"
                    style={{ height: 18, width: 16 }}
                    alt="filter"
                  />
                </StyledButton>
                <CustomizationFilterBlock
                  addressParams={addressParams}
                  handleChangeAddressParams={handleChangeAddressParams}
                  isOpen={filterWindow}
                />
              </div>
            </>
          ) : location.pathname === '/customization/create' ? (
            <Button
              rounded
              tertiary
              disabled={
                ![fieldsErr, !current?.title].every((el) => el === false)
              }
              onClick={handleSave}
            >
              {t('customization:button.save_template')} {loading && '...'}
            </Button>
          ) : (
            <Button
              rounded
              tertiary
              onClick={() => history.push('/customization/create')}
            >
              {t('customization:button.create_template')}
            </Button>
          )}
        </>
      }
      search={
        location.pathname === '/customization/address' && (
          <Select
            variant="outlined"
            inputValue={addressParams.search}
            noOptionsMessage={() => null}
            placeholder={'Введите адрес'}
            isMulti={false}
            onInputChange={(value, action) => {
              if (action.action === 'input-change') handleSearchAddress(value)
            }}
          />
        )
      }
      aside={
        <>
          {location.pathname !== '/customization/address' ? (
            <div style={{ maxWidth: '325px', marginLeft: '4rem' }}>
              <CustomizationPhone picture={picture} />
              <div style={{ marginTop: '62px' }}>
                <Toggle
                  label1={'Услуга'}
                  label2={'О компании'}
                  initStatus={picture}
                  value={picture}
                  onClick={() => setPicture(!picture)}
                />
              </div>
            </div>
          ) : null}
        </>
      }
    >
      <CustomizationModal
        addressParams={addressParams}
        handleChangeAddressParams={handleChangeAddressParams}
        isOpen={openModal}
        handleClose={handleCloseModal}
      />
      <CustomizationUnLinkModal
        addressParams={addressParams}
        handleChangeAddressParams={handleChangeAddressParams}
        isOpen={openUnLinkModal}
        handleClose={handleCloseUnLinkModal}
      />
      {fadeRight.map(({ item, props, key }) => (
        <animated.div key={key} style={{ ...props, width: '100%' }}>
          <Switch location={item}>
            <Route exact path={match.url} component={Patterns} />
            <Route
              exact
              path={`${match.url}/address`}
              render={() => (
                <CustomizationAddressList
                  addressParams={addressParams}
                  handleChangeAddressParams={handleChangeAddressParams}
                />
              )}
            />
            <Route
              exact
              path={`${match.url}/create`}
              render={() => (
                <div>
                  <CustomizationForms />
                  {current?.products?.length > 0 ? (
                    <CustomizationProductForm />
                  ) : null}
                </div>
              )}
            />
          </Switch>
        </animated.div>
      ))}
    </CustomizationTemplate>
  )
}

export default CustomizationPage
