import { useState, useRef } from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useEffect } from 'react'

const ToggleWrapper = styled.div`
/* $loadingTime: 10s;
$color: rgb(255, 0, 0); */
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  overflow: hidden;
  /* padding: 20px; */
  color: #6d6d6d;
  user-select: none;
  border-radius: 20px;
  background-color: #ededed;
  cursor: pointer;
`
//#0EA7FF, 100 %
const Label = styled.div`
  /* position: absolute; */
  padding: 14px 0px;
  width: 50%;
  text-align: center;
  z-index: 1;
    /* width: 200px;
     border: 1px solid $color; 
  
    &:after {
      padding-left: 20px;
      content: "0%";
      display: block;
      text-align: center;
      animation: percentage $loadingTime linear infinite;
    } */
  
`
// $color
function Toggle({ initStatus, label1, label2, value, onClick, ...props }) {
  const [isActive, setActive] = useState(initStatus || false)
// const animTime

  const handleClick = () => {
    setActive(!isActive)
  }
  useEffect(() => {
    setActive(value)
  }, [value])

  return (
    <ToggleWrapper onClick={onClick} {...props}>
      <Label style={{ color: !isActive && 'white' }}>{label1}</Label>
      <Label style={{ color: isActive && 'white' }}>{label2}</Label>
      <motion.div
        style={style.animated}
        animate={isActive ? { marginLeft: '50%' } : { marginLeft: '0%' }}
        transition={{ duration: 0.3 }}
      ></motion.div>
    </ToggleWrapper>
  )
}

const style = {
  animated: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    width: '50%',
    height: '100%',
    borderRadius: 20,
    background: '#0EA7FF',
  },
}
export default Toggle
