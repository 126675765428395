import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import ViewItem from './ViewItem'
import { Typography } from '@material-ui/core'
import { customizationEditMode, editSelectedCredit } from './customizationSlice'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ededed;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 24px;
`
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Edit = styled(Typography)`
  && {
    color: #0ea7ff;
    cursor: pointer;
  }
`

function Patterns() {
const {t}= useTranslation(['customization'])

  const dispatch = useDispatch()
  const history = useHistory()
  const list = useSelector(({ customization }) => customization.list)

  const handleEdit = (id) => {
    dispatch(customizationEditMode({ editMode: true }))
    dispatch(editSelectedCredit({ id }))
    history.push('/customization/create')
  }

  return (
    <div>
      <Typography style={{ marginBottom: '1rem', marginTop: 0 }} variant="h5">
        {t('customization:your_templates')}
      </Typography>

      {list &&
        list.map((el) => (
          <Card key={el.id}>
            <Head>
              <Typography variant="h6">{el.title}</Typography>
              <Edit onClick={() => handleEdit(el.id)}> {t('customization:button.edit_template')}</Edit>
            </Head>

            {el.credits.map((e, i) => (
              <ViewItem key={e.id} credit={e} index={i} />
            ))}
            {el.products.map((e, i) => (
              <ViewItem key={e.id} credit={e} index={i} service />
            ))}
          </Card>
        ))}
    </div>
  )
}

export default Patterns
