import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { Trans, useTranslation } from 'react-i18next'
import straightLine from './straightLine.svg'
import downLine from './downLine.svg'
import upLine from './upLine.svg'
import dayjs from 'dayjs'

const borderRadius = '2.3rem'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  border-radius: ${borderRadius};
  height: 287px;
  width: 220px;
  justify-content: space-between;
  text-align: center;
  padding: 20px 25px;
  transition: 0.3s ease-in-out;
  filter: grayscale(40%);
  opacity: 0.5;
  &.active {
    transform: scale(1.1);
    opacity: 1;
    filter: none;
  }
`

const imageWorker = (val) => {
  if (val > 0) {
    return upLine
  } else if (val < 0) {
    return downLine
  } else {
    return straightLine
  }
}

function MoneyCard({ index, date, isActive, setActiveCard, points, moneyCount, status, isStart }) {
  const { t } = useTranslation('partner')

  const labelWorker = (val) => {
    if (val > 0) {
      return 'больше'
    } else if (val < 0) {
      return 'меньше'
    } else {
      return ''
      // return t('partner:card.empty.label')
    }
  }

  return (
    <Card
      style={{
        justifyContent: 'space-between',
        textAlign: 'center',
        padding: '20px 30px',
      }}
      className={isActive(index)}
      onMouseOver={() => setActiveCard(index)}
    >
      <div>
        <Typography color="textSecondary">
          Вы заработали <br />
          за {dayjs(date).format('MMMM')}
        </Typography>
        <Typography
          style={{
            fontSize: '2rem',
            fontWeight: 400,
          }}
        >
          {moneyCount} ₽
        </Typography>
      </div>
      <img src={imageWorker(status)} style={{ width: '100%', maxWidth: 120 }} alt="" />
      <Typography color="textSecondary">
        {!(Number(status) === 0) ? (
          <Typography color="textSecondary">
            это на <span style={{ color: '#EB4A86' }}>{points}%</span> {labelWorker(status)} чем за{' '}
            {dayjs(date).subtract(1, 'month').format('MMMM')}
          </Typography>
        ) : (
          <Typography color="textSecondary">
            <span>это столько же как за </span>
            {dayjs(date).subtract(1, 'month').format('MMMM')}
          </Typography>
        )}
      </Typography>
    </Card>
  )
}

export default MoneyCard
