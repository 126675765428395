import { createSlice } from '@reduxjs/toolkit'
import { fetchAppAbilities, fetchManagementCompany, fetchManagementsCompanies } from './services'

const initialState = {
  test: undefined,
  pending: false,
  appAbilitiesList: undefined,
  permissions: {},
  companies: undefined,
  current: {
    pending: false,
    company: undefined,
  },
}

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissionsList: (state, { payload }) => {
      state.permissions = payload
    },
    clearPermission: () => initialState,
    clearPermissionCurrent: (state) => {
      state.current = { pending: false }
    },
  },
  extraReducers: {
    [fetchManagementCompany.rejected]: (state) => {
      state.current.pending = false
    },
    [fetchManagementCompany.pending]: (state) => {
      state.current.pending = true
    },
    [fetchManagementCompany.fulfilled]: (state, { payload }) => {
      state.current.company = payload
      state.current.pending = false
      state.test = payload.permissions
    },

    [fetchManagementsCompanies.rejected]: (state) => {
      state.pending = false
    },
    [fetchManagementsCompanies.pending]: (state) => {
      state.pending = true
    },
    [fetchManagementsCompanies.fulfilled]: (state, { payload }) => {
      state.companies = payload
      state.pending = false
    },
    [fetchAppAbilities.rejected]: (state) => {
      // state.permissionsList = payload
    },

    [fetchAppAbilities.pending]: (state) => {
      console.log('fetchPermissionsList.pending')
      // state.permissionsList = payload
    },
    [fetchAppAbilities.fulfilled]: (state, { payload }) => {
      state.appAbilitiesList = payload
    },
  },
})

export const { actions: permissionsActions } = permissionsSlice
export const { reducer: permissionsReducer } = permissionsSlice
