import React from 'react'
import { connect } from 'react-redux'
import { Wrapper } from 'sputnik-ui'
import { Typography } from '@material-ui/core'
import Transactions from 'features/billing/Transactions'


const TransactionHistory = ({ operations, error, ...props }) => {
  return (
    <Wrapper {...props} style={styles.wrapper} data-aos="fade-right">
      {operations && Object.keys(operations?.operations).length === 0 ? (
        <Typography align="center">
          У вас нет платежей за данный период.
        </Typography>
      ) : (
        <Transactions {...props} operations={operations} error={error} />
      )}
      {/* <Typography>
        Совсем скоро здесь появится новая история платежей. 😌
      </Typography> */}
    </Wrapper>
  )
}

const styles = {
  wrapper: {
    flexDirection: 'column',
  },
}

const mapStateToProps = ({ billing }) => ({
  operations: billing.list,
  error: billing.error,
})

export default connect(mapStateToProps)(TransactionHistory)
