import React from 'react'

import { useSpring, animated } from 'react-spring'

const calc = ({ x, y, width, height }) => {
  return [
    // -(y - window.innerHeight / 5) / 40,

    // (x - window.innerWidth / 5) / 40,
    -(y - height) / 40,
    (x - width) / 40,
    1.05,
  ]
}
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

function relativeCoords(event) {
  const { left, top, width, height } = event.target.getBoundingClientRect()
  const x = event.clientX - left
  const y = event.clientY - top
  return { x, y, width, height }
}

export default function SpringCard({ children }) {
  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))
  return (
    <animated.div
      // onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseMove={e => set({ xys: calc(relativeCoords(e)) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }}
    >
      {children}
    </animated.div>
  )
}
