import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { Button } from 'sputnik-ui'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Zoom,
} from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import { acceptPartnerProgram, fetchUserCompany } from 'store/auth/operations'

const Transition = (props) => {
  return <Zoom direction="up" {...props} />
}

function AcceptProgramModal({ isOpen }) {
  const { t } = useTranslation('partner')
  const location = useLocation()
  const dispatch = useDispatch()
  const programStatus = useSelector(
    ({ auth }) => auth?.company?.partners_program_accepted
  )
  const [open, setOpen] = useState(false)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    if (!programStatus && programStatus !== undefined) {
      setOpen(true)
    }
  }, [programStatus])
  useEffect(() => {
    if (
      location.pathname === '/partner_program' &&
      !programStatus &&
      programStatus !== undefined
    ) {
      setOpen(true)
    }
  }, [location.pathname, programStatus])

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = useCallback(() => {
    ;(async () => {
      setPending(true)
      try {
        await dispatch(acceptPartnerProgram())
        setOpen(false)
      } catch (error) {
      } finally {
        dispatch(fetchUserCompany())
        setPending(false)
      }
    })()
  }, [programStatus])

  return (
    <Dialog
      open={open || isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      PaperProps={{ style: { minWidth: '40%' } }}
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          accepted: false,
        }}
      >
        {({ values, setValues }) => (
          <Form>
            <DialogTitle>{t('partner:modal.title')}</DialogTitle>
            <DialogContent
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormControlLabel
                style={styles.checkboxWrapper}
                control={
                  <Checkbox
                    style={styles.accept}
                    checked={values.accepted}
                    onChange={() => setValues({ accepted: !values.accepted })}
                  />
                }
                label={
                  <span>
                    {t('partner:modal.accept')}{' '}
                    <Link
                      to={{
                        pathname:
                          'https://api-partner.sputnik.systems/partner_program_agreement.pdf',
                      }}
                      target="_blank"
                      style={{ color: '#66BBFF', textDecoration: 'none' }}
                    >
                      {t('partner:modal.link')}
                    </Link>
                  </span>
                }
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} secondary rounded>
                закрыть
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                type="submit"
                rounded
                disabled={!values.accepted || pending}
              >
                Присоединиться {pending && '...'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

const styles = {
  checkboxWrapper: {
    marginTop: '.625rem',
    marginLeft: '-16px',
    userSelect: 'none',
  },
}

export default AcceptProgramModal
