import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Zoom,
  Typography,
} from '@material-ui/core/'
import { Button } from 'sputnik-ui'
import styled from 'styled-components'
import {
  clearSelectedEntries,
  connectToCustomization,
  fetchCustomizationAddresses,
  fetchCustomizations,
} from './customizationSlice'

const StyledDialogContent = styled(DialogContent)`
  width: 100%;
`

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

function CustomizationUnLinkModal({ isOpen, handleClose, addressParams }) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const addresses = useSelector(
    ({ customization }) => customization?.addresses?.addresses
  )
  const selectedEntries = useSelector(
    ({ customization }) => customization.selectedEntries
  )

  const handleClick = () => {
    setLoading(true)
    // todo: fix algorithm; break logic
    const arr = addresses.filter((el) => {
      if (el?.customization) return el
    }) //get all with cuz

    const queues = arr.map((el) => {
      const { id } = el.customization
      const newEntries = el.customization.entries_uuids.filter((entry) => {
        if (!selectedEntries.includes(entry)) return entry
      })
      return dispatch(
        connectToCustomization({ selected: id, selectedEntries: newEntries })
      )
    })
    Promise.all(queues)
      .then(() => dispatch(fetchCustomizationAddresses({ ...addressParams })))
      .then(() => dispatch(fetchCustomizations()))
      .then(() => dispatch(clearSelectedEntries()))
      .then(() => setLoading(false))
      .then(() => handleClose())
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => !loading && handleClose()}
      TransitionComponent={Transition}
      PaperProps={{
        style: { borderRadius: 12 },
      }}
    >
      <StyledDialogContent>
        <Typography
          variant="h6"
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '0 auto',
            lineHeight: 1.1,
          }}
        >
          Уверены, что хотите
          <br /> открепить шаблоны?
        </Typography>
      </StyledDialogContent>
      <DialogActions>
        <StyledDialogContent
          style={{
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            width: 320,
          }}
        >
          <Button onClick={handleClick} primary variant="v2">
            {loading ? 'Загрузка ...' : 'Открепить'}
          </Button>
          <Button subtle onClick={() => !loading && handleClose()}>
            Назад
          </Button>
        </StyledDialogContent>
      </DialogActions>
    </Dialog>
  )
}

export default CustomizationUnLinkModal
