import Api from 'utils/api'

import * as actions from './actions'

export const fetchDevices = ({
  page,
  items,
  query,
  sortBy,
  orderBy,
}) => async dispatch => {
  dispatch(actions.fetchDevicesRequest())
  try {
    const setParams = () => {
      let params = []

      if (page) params.push(`page=${page + 1}`)
      if (items) params.push(`items=${items}`)
      if (query) params.push(`search=${query}`)
      if (sortBy) params.push(`sort_by=${sortBy}`)
      if (orderBy) params.push(`order_by=${orderBy}`)

      return params.join('&')
    }

    const {
      intercoms: devices,
      meta: { total_pages: totalPages },
    } = await Api.request(`/partners/devices?${setParams()}`)

    dispatch(actions.fetchDevicesSuccess({ devices, totalPages }))
  } catch (error) {
    dispatch(actions.fetchDevicesFailure(error))
  }
}

export const fetchDeviceInfo = uuid => async dispatch => {
  dispatch(actions.fetchDeviceInfoRequest())
  try {
    const device = await Api.request(`/partners/devices/${uuid}`)

    dispatch(actions.fetchDeviceInfoSuccess(device))
  } catch (error) {
    dispatch(
      actions.fetchDeviceInfoFailure(
        'При получении информации об устройстве произошла ошибка',
      ),
    )
  }
}
