import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, FullscreenDialogTitle, FullscreenExitButton } from 'sputnik-ui'

import { Dialog, DialogContent, Fade, Divider } from '@material-ui/core/'

import {
  fetchAppAbilities,
  fetchManagementCompany,
  fetchManagementsCompanies,
  updateAppAbilities,
} from 'store/permissions/services'

import { getAddressesSelected } from 'store/addresses/selectors/getAddressesSelected'
import { permissionsActions } from 'store/permissions/slice'
import { addAddresses, addressesActions } from 'store/addresses'
import { AddressesList } from '../InviteUKModal/AddressesList'
import {
  getAppAbilitiesList,
  getCurrentCompanyPending,
  getPermissionsCurrentCompany,
} from 'store/permissions'
import { PermissionsForm } from '../InviteUKModal/PermissionsForm'
import UKList from './UKList'
import { UKLinkedList } from './UKLinkedList'
import { Loader } from 'sputnik-ui'
import { Typography } from 'sputnik-ui'

const Transition = (props) => <Fade in {...props} />

const EditUKModal = (props) => {
  const { open, handleClose } = props
  const selectedAddresses = useSelector(getAddressesSelected)
  const companyPending = useSelector(getCurrentCompanyPending)
  const [isLoading, setLoading] = useState(false)
  const { clearAddresses, setSelectedAddresses } = addressesActions
  const { clearPermission, setPermissionsList } = permissionsActions
  const dispatch = useDispatch()
  const company = useSelector(getPermissionsCurrentCompany)
  const test = useSelector(({ permissions }) => permissions.test)
  const abi = useSelector(getAppAbilitiesList)

  useEffect(() => {
    dispatch(fetchAppAbilities())
    dispatch(fetchManagementsCompanies())
    return () => {
      dispatch(clearAddresses())
      dispatch(clearPermission())
    }
  }, [clearAddresses, clearPermission, dispatch])

  // useEffect(() => {
  //   if (company?.addresses && Array.isArray(company.addresses)) {
  //     dispatch(setSelectedAddresses(company.addresses))
  //   }
  //   return () => {
  //     dispatch(setSelectedAddresses([]))
  //   }
  // }, [company, dispatch, setSelectedAddresses])

  useEffect(() => {
    if (abi && abi?.length && test && abi[0]?.resources) {
      let nextPermissions = {}
      Object.entries(abi[0].resources).forEach(([key, val]) => {
        test.forEach((a) => {
          if (a.name === val.name) {
            a.privileges?.forEach((el) => {
              if (key in nextPermissions) {
                nextPermissions[key] = [...nextPermissions[key], el.action]
              } else {
                nextPermissions[key] = [el.action]
              }
            })
          }
        })
      })
      dispatch(setPermissionsList(nextPermissions))
    }
  }, [test, abi, dispatch, setPermissionsList])

  const handleConfirmPermissions = async () => {
    await dispatch(updateAppAbilities({ uuid: company.company_uuid }))
    await dispatch(fetchManagementCompany({ uuid: company.company_uuid }))
  }

  const handleAddAddresses = async () => {
    setLoading(true)
    await dispatch(
      addAddresses({
        uuid: company.company_uuid,
        address_uuids: selectedAddresses.map((selected) => selected.uuid),
      })
    )
    await dispatch(setSelectedAddresses([]))
    await dispatch(fetchManagementsCompanies())
    await dispatch(fetchManagementCompany({ uuid: company.company_uuid }))
    setLoading(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} fullScreen>
      <FullscreenDialogTitle subtitle="Выберете компанию которую хотите просмотреть">
        Просмотр и редактирование УК
      </FullscreenDialogTitle>

      <FullscreenExitButton onClick={handleClose} />
      <DialogContent
        style={{
          width: '100%',
          paddingBottom: '2rem',
        }}
      >
        <div
          style={{
            maxWidth: '70rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
            gap: '2rem',
          }}
        >
          <UKList />
          {companyPending && <Loader />}
          {company && !companyPending && (
            <>
              <Divider />
              <div>
                <UKLinkedList />
              </div>
              <Divider />
              <div>
                <Typography variant="title">Список всех адресов</Typography>
                <AddressesList />
                <Button variant="v2" secondary onClick={handleAddAddresses} disabled={isLoading}>
                  Добавить выбранные
                </Button>
              </div>
              <div>
                <PermissionsForm />
                <Button variant="v2" secondary onClick={handleConfirmPermissions}>
                  Сохранить изменения
                </Button>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default EditUKModal
