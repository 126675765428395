import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Typography, Button } from '@material-ui/core'
import { DeleteOutlineOutlined } from '@material-ui/icons'
import * as Yup from 'yup'
import { editCurrentProduct, removeCurrentProduct } from './customizationSlice'
import { Trans, useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
`
const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string().required('введите название'),
    price: Yup.number()
      .required('введите цену')
      .min(0, 'не может быть меньше 0'),
    link: Yup.string().url().required('неверный url'),
    description: Yup.string().required('введите описание'),
  })

function CustomizationProductForm() {
  const dispatch = useDispatch()
  const scroll = useRef()
  const { t } = useTranslation(['customization', 'common'])
  const products = useSelector(
    ({ customization }) => customization.current?.products
  )
  const editMode = useSelector(({ customization }) => customization.editMode)
  useEffect(() => {
    if (!editMode) scroll.current.scrollIntoView()
  }, [])

  const handleDelete = () => {
    dispatch(removeCurrentProduct(products[0].id))
  }

  const { values, handleChange, errors, touched, handleBlur } = useFormik({
    initialValues: {
      ...products[0],
    },
    validationSchema: validationSchema,
  })

  const handleChangeState = (e) => {
    if (e?.target) {
      dispatch(
        editCurrentProduct({
          id: values.id,
          value: e.target.value,
          name: e.target.name,
        })
      )
    }
  }

  return (
    <Wrapper ref={scroll}>
      {products && (
        <>
          <div
            style={{
              marginTop: '2.5rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              marginBottom: '1.5rem',
            }}
          >
            <Typography variant="h5" style={{ fontWeight: '500' }}>
              {t('customization:form.product.title')}
            </Typography>

            <Button
              onClick={handleDelete}
              variant="text"
              size="small"
              style={styles.btnDel}
              rounded
              secondary
            >
              <span
                style={{ marginRight: '10px', textTransform: 'capitalize' }}
              >
                {' '}
                {t('common:button.delete')}
              </span>
              <DeleteOutlineOutlined />
            </Button>
          </div>
          <Typography
            style={{
              fontSize: '0.875rem',
              color: '#6E6D7A',
              marginBottom: '1.5rem',
            }}
          >
            <Trans
              i18nKey="customization:form.product.info"
              components={[<br />]}
            />
          </Typography>

          <div style={styles.form} onSubmit={(event) => event.preventDefault()}>
            <div style={{ width: '50%' }}>
              <TextField
                value={values?.title}
                fullWidth
                label={t('customization:form.product.name.label')}
                error={touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                onBlur={handleBlur}
                required
                onChange={(e) => {
                  handleChange(e)
                  handleChangeState(e)
                }}
                margin="dense"
                name="title"
                style={{ marginBottom: '1rem' }}
              />
              <TextField
                fullWidth
                value={values?.price}
                label={t('customization:form.product.price.label')}
                type="number"
                onChange={(e) => {
                  handleChange(e)
                  handleChangeState(e)
                }}
                error={touched.price && !!errors.price}
                helperText={touched.price && errors.price}
                onBlur={handleBlur}
                name="price"
                required
                margin="dense"
                style={{ marginBottom: '1.5rem' }}
              />
            </div>
            <TextField
              fullWidth
              value={values?.link}
              label={t('customization:form.product.link.label')}
              name="link"
              onChange={(e) => {
                handleChange(e)
                handleChangeState(e)
              }}
              error={touched.link && !!errors.link}
              autoComplete="link"
              helperText={touched.link && errors.link}
              onBlur={handleBlur}
              required
              margin="dense"
              style={{ marginBottom: '1.5rem' }}
            />{' '}
            <TextField
              fullWidth
              value={values?.description}
              label={t('customization:form.product.description.label')}
              name="description"
              onChange={(e) => {
                handleChange(e)
                handleChangeState(e)
              }}
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
              onBlur={handleBlur}
              required
              margin="dense"
            />
          </div>
        </>
      )}
    </Wrapper>
  )
}

const styles = {
  btnDel: {
    color: '#FF0166',
    fontFamily: 'Futura PT',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '21px',
    textTransform: 'none',
    letterSpacing: 'normal',

    borderBottom: 'none',
  },
  form: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
  },
  inlineContent: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '2.5rem',
  },
}

export default CustomizationProductForm
