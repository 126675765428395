import { useEffect, useState } from 'react'
import { Typography, Tooltip, Box } from '@material-ui/core'
import { Container } from 'sputnik-ui'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import ProgressBar from 'features/PartnerProgram/ProgressBar'
import ListItem from 'features/PartnerProgram/ListItem'

import success from './success.png'
import analyctic from './analyctic.png'
import avatar from './avatar.png'
import human from './human.svg'
import star1 from './star1.png'
import star2 from './star2.png'
import star3 from './star3.png'
import actionHelper from './actionHelper.svg'
// import straightLine from './straightLine.svg'
// import downLine from './downLine.svg'
// import upLine from './upLine.svg'
import MoneyCardContainer from 'features/PartnerProgram/MoneyCardContainer'
import { Loader } from 'sputnik-ui'

const borderRadius = '2.3rem'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
  border-radius: ${borderRadius};
  padding: 20px 10px;
  max-width: 300px;
  height: 260px;
`

const Value = styled(Typography).attrs((p) => ({ variant: 'h5' }))`
  display: flex;
  height: 180px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  white-space: nowrap;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
`

const TableWrapper = styled.div`
  overflow: auto;
  margin: 3rem auto;
  width: 90%;
  @media (max-width: 1000px) {
    width: 100%;
  }
`

const CellWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 13.5%;
  min-width: 120px;
  margin-left: 1rem;
  &:first-child {
    margin-left: 0rem;
  }
`

const ResDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
  grid-gap: 8rem;
  row-gap: 3rem;
  align-items: center;
  justify-content: center;
`

const StarImage = styled.img`
  position: absolute;
`

const table = [
  { title: '0-30%', value: '0%' },
  { title: '31-40%', value: '5%' },
  { title: '41-50%', value: '10%' },
  { title: '51-60%', value: '15%' },
  { title: '61-100%', value: '20%' },
]

const numColor = (index) => {
  switch (index) {
    case 0:
      return '#0EA7FF'
    case 1:
      return '#000050'
    case 2:
      return '#EB4A86'
    case 3:
      return '#C130FF'
    case 4:
      return '#000050'

    default:
      return '#000050'
  }
}

function PartnerProgramPage() {
  const { t } = useTranslation('partner')

  const { medianActiveFlats, amountByMonths } = useSelector(
    ({ auth }) => auth?.partnerProgramStatistic
  )

  const circle = [
    {
      progress: medianActiveFlats || 0,
      color: '#EB4A86',
      bgColor: '#EDD7DF',
      label: (
        <>
          {t('partner:progress_circle.circle1')}
          <Tooltip title={t('partner:progress_circle.helper')}>
            <img
              alt='helper'
              style={{
                marginBottom: -8,
                marginLeft: 10,
                height: 'auto',
                width: 26,
                cursor: 'help',
              }}
              src={actionHelper}
            />
          </Tooltip>
        </>
      ),
    },
    {
      progress: 'fd',
      color: '#E4E4E4',
      bgColor: '#E4E4E4',
      label: t('partner:progress_circle.circle2'),
    },
  ]

  if(!amountByMonths) return <Loader />
  return (
    <Container style={{ marginTop: '2rem' }}>
      <Typography
        variant="h5"
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          fontSize: '2.5rem',
          marginBottom: '7rem',
          fontWeight: '500',
        }}
      >
        {t('partner:title')}
      </Typography>
      <div
        style={{
          position: 'relative',
        }}
      >
        <MoneyCardContainer amountByMonths={amountByMonths} />

      </div>

      <div
        style={{
          margin: '5rem 0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" style={{ fontWeight: 450 }}>
          {t('partner:key_metrics')}
        </Typography>
        <Typography
          color="textSecondary"
          style={{
            marginTop: '.5rem',
            fontSize: '1.2rem',
          }}
        >
          {t('partner:analytic_link.label')}{' '}
          <Link to="/analytics" style={{ color: '#66BBFF' }}>
            {t('partner:analytic_link.link')}
          </Link>
        </Typography>
      </div>
      <ResDiv>
        {circle.map((el, index) => {
          return (
            <Card key={index}>
              <ProgressBar
                color={el.color}
                bgColor={el.bgColor}
                progress={el.progress}
                bold={24}
              />
              <Typography
                color="textSecondary"
                style={{
                  marginTop: '1rem',
                  width: '80%',
                }}
              >
                {el.label}
              </Typography>
            </Card>
          )
        })}
      </ResDiv>
      <div style={{ margin: '10rem 0', marginBottom: 0 }}>
        <Typography
          variant="h5"
          style={{ fontWeight: '500', margin: '6rem 0' }}
        >
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            {t('partner:bonus_points')}
          </div>
        </Typography>
        <TableWrapper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 248,
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography style={{ whiteSpace: 'nowrap' }}>
                    <Trans
                      i18nKey="partner:mobile_app.label"
                      components={[<br />]}
                    ></Trans>
                  </Typography>

                  <Tooltip title={t('partner:mobile_app.helper')}>
                    <img
                      alt='helper'
                      style={{
                        marginLeft: 10,
                        height: 'auto',
                        width: 26,
                        cursor: 'help',
                      }}
                      src={actionHelper}
                    />
                  </Tooltip>
                </div>
                <hr
                  style={{
                    borderColor: 'rgba(0, 0, 0, 0.54)',
                    marginBottom: 0,
                  }}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Typography>Revenue share</Typography>
                <Tooltip title={t('partner:revenue_share.helper')}>
                  <img
                    alt='helper'
                    style={{
                      marginLeft: 10,
                      height: 'auto',
                      width: 26,
                      cursor: 'help',
                    }}
                    src={actionHelper}
                  />
                </Tooltip>
              </div>
            </div>

            <div style={{ marginLeft: '1.5rem', width: '70%' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {table.map((el, index) => {
                  let isActiveColor
                  let valueColor = numColor()
                  isActiveColor = numColor()

                  return (
                    <CellWrapper key={index}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            position: 'relative',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: isActiveColor,
                            height: 68.7,
                            borderTopLeftRadius: '1.5rem',
                            borderTopRightRadius: '1.5rem',
                          }}
                        >
                          <Typography
                            style={{
                              color: 'white',
                            }}
                          >
                            {' '}
                            {el.title}
                          </Typography>

                          {index === 4 ? (
                            <>
                              <StarImage
                                style={{
                                  position: 'absolute',
                                  height: 'auto',
                                  width: 40,
                                  left: -20,
                                }}
                                src={star1}
                              />
                              <StarImage
                                style={{
                                  height: 'auto',
                                  width: 40,
                                  top: 55,
                                  left: 20,
                                  position: 'absolute',
                                }}
                                src={star2}
                              />
                              <StarImage
                                style={{
                                  position: 'absolute',
                                  height: 'auto',
                                  width: 35,
                                  top: 0,
                                  right: 0,
                                }}
                                src={star3}
                              />
                            </>
                          ) : null}
                        </div>
                        <Value
                          style={{
                            fontWeight: '500',
                            color: valueColor || '#C5C5C5',
                            border: '1px solid ',
                            borderColor: isActiveColor,
                            borderTopStyle: 'none',
                          }}
                        >
                          {el.value}
                        </Value>
                      </div>
                    </CellWrapper>
                  )
                })}
              </div>
            </div>
          </div>
        </TableWrapper>
      </div>
      <Box display="flex" justifyContent="center">
        <Typography color="textSecondary" style={{ margin: '0rem 0 2rem' }}>
          {t('partner:foot_help.label')}{' '}
          <Link
            to="/billing"
            style={{ color: '#66BBFF', textDecoration: 'none' }}
          >
            {t('partner:foot_help.link')}
          </Link>
        </Typography>
      </Box>
    </Container>
  )
}

export default PartnerProgramPage
