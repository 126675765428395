import * as React from 'react'
import styled from 'styled-components'
import { Container, Button, Select } from 'sputnik-ui'
import { Typography, MenuItem } from '@material-ui/core'

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 5rem; // issue #1109
  overflow-x: hidden;
  height: auto;
`

const Tabs = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`
const Nav = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Children = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* margin-top: 1.5rem; */

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    order: 2;
    margin-right: 0;
  }
`

const ButtonList = styled.div`
  display: flex;

  @media screen and (max-width: ${(p) => p.theme.sizes.phone}) {
    flex-direction: column;
    width: 100%;
  }
`

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  margin-top: 3.2rem;
  & > *:not(:first-child) {
    margin-top: 2rem;
  }

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    display: none;
  }
`

const CustomizationTemplate = ({
  title,
  tabs,
  children,
  aside,
  buttonsList,
  search,
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <Typography variant="h5">{title}</Typography>

      <Nav>
        <Tabs>{tabs}</Tabs>
        <ButtonList>{buttonsList}</ButtonList>
      </Nav>
      {search && <div style={{ maxWidth: 400 }}>{search}</div>}

      <ContentWrapper>
        <Children>{children}</Children>
        <Aside>{aside}</Aside>
      </ContentWrapper>
    </Wrapper>
  )
}

export default CustomizationTemplate
