import React, { useState, useRef } from 'react'
import { BuyPageTemplate, ProductCard, BuyProductModal } from 'components'
import { connect } from 'react-redux'
import { withRouter, Route } from 'react-router-dom'
// import SputnikImg from './sputnik.png'
import styled from 'styled-components'
// import CamImg from './cam.png'
// import CamImg from './cam-group-1.png'
import UnitEconomyModal from 'components/organisms/UnitEconomyModal'

// import CamImg from './cam-group-3.png'

const Carousel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  transition: all 600ms ease-in-out;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    flex-direction: column;

    & > div:last-child {
      display: none;
    }
  }
`

const BuyPage = ({ match, billingAccount, products }) => {
  const [currentPosition, setCurrentPosition] = useState(0)

  const carouselRef = useRef()
  const cardsRef = useRef(products.map(() => React.createRef()))
  const lastNextButton = useRef()
  // useLayoutEffect(() => {
  //   cardsRef.current.forEach(card => {
  //     if (card.current) card.current.style.opacity = 1
  //   })
  // })
  // const handleLoad = (node, isAppearing) => {
  //   // if (isAppearing) {
  //   // console.log(cardsRef)

  //   //(card.current.style.opacity = 1)
  //   cardsRef.current.forEach(card => console.log(card))
  //   // }
  // }

  const getOrder = (itemIndex) => {
    // const numItems = products.length
    // if (itemIndex - currentPosition < 0) {
    //   return numItems - Math.abs(itemIndex - currentPosition)
    // }
    // return itemIndex - currentPosition
    return itemIndex
  }

  const setCurrentPositionWithAnimation = (pos) => {
    if (pos === currentPosition) return

    const nextCard = cardsRef.current[pos].current
    const carousel = carouselRef.current
    const isFirstItem = pos === 0
    const rect = nextCard.getBoundingClientRect()
    console.log(rect)
    console.log('pos', pos)

    // currentCard.style.opacity = 0
    // nextCard.style.opacity = 0
    // lastNextButton.current.style.opacity = 0

    if (isFirstItem) {
      carousel.style.transform = `translateX(0)`
    } else {
      carousel.style.transform = `translateX(-${rect.left * pos}px)`
    }
    setCurrentPosition(pos)
    window.setTimeout(() => {
      // currentCard.style.opacity = 1
      // nextCard.style.opacity = 1
      // lastNextButton.current.style.opacity = 1
    }, 600)
  }
  // console.log(cardsRef)
  return (
    <BuyPageTemplate>
      <Carousel ref={carouselRef}>
        {products.map((product, idx) => (
          <ProductCard
            key={product.id}
            setRef={cardsRef.current[idx]}
            {...product}
            style={{ flexShrink: 0 }}
            order={getOrder(idx)}
            currentPosition={currentPosition}
            productsNumber={products.length}
            match={match}
            idx={idx}
            setCurrentPosition={setCurrentPositionWithAnimation}
          />
        ))}

        <ProductCard
          setRef={lastNextButton}
          key={products[0].app_id}
          {...products[0]}
          style={{ flexShrink: 0 }}
          order={getOrder(products.length)}
          currentPosition={currentPosition}
          productsNumber={products.length}
          match={match}
          idx={0}
          setCurrentPosition={setCurrentPositionWithAnimation}
        />
      </Carousel>
      {/* <Route />                               //HIDE MODALS
        path={`${match.url}/:product/buy`}
        render={() => <BuyProductModal />}
      />
      <Route
        path={`${match.url}/:product/unit`}
        render={() => <UnitEconomyModal />}
      /> */}
    </BuyPageTemplate>
  )
}

const mapStateToProps = ({ billing, products }) => ({
  billingAccount: billing.account,
  products: products.list,
})

export default connect(mapStateToProps)(withRouter(BuyPage))
