import { AnimatePresence, motion } from 'framer-motion'
import { useEffect } from 'react'
const phonePartner = '/images/phonePartner.png'
const phoneServices = '/images/phoneServices.png'
function CustomizationPhone({ picture }) {
  return (
    <div style={{ height: '673px' }}>
      <AnimatePresence>
        {picture ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ display: 'none' }}
          >
            <img src={phonePartner} alt="company" />
          </motion.div>
        ) : (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ display: 'none' }}
          >
            <img src={phoneServices} alt="service" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
export default CustomizationPhone
