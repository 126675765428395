import React from 'react'
import range from 'lodash/range'
import styled from 'styled-components'
import { Checkbox, TableBody, TableRow, TableCell } from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'

import { Power, PowerOff, GpsFixed, GpsOff } from '@material-ui/icons'

const PowerOn = styled(Power)`
  color: ${p => p.theme.palette.success['500']};
`
// const OfflineIcon = styled

export default props => (
  <TableBody>
    {props.devices
      ? props.devices
          // .sort(props.getSorting(props.order, props.orderBy))
          .map(row => {
            const isSelected = props.selected.includes(row.duuid)

            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.duuid}
                onClick={() =>
                  props.history.push(`${props.match.url}/${row.duuid}/`)
                }
                style={{ cursor: 'pointer' }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isSelected}
                    color="primary"
                    onClick={e => {
                      e.stopPropagation()
                      props.handleSelect(e, row.duuid, isSelected)
                    }}
                  />
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  {row.serial_number}
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  {row.service_partner_name}
                </TableCell>

                <TableCell component="th" scope="row" padding="dense">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {row.is_online ? (
                      <PowerOn color="success" style={styles.icon} />
                    ) : (
                      <PowerOff color="error" style={styles.icon} />
                    )}

                    {row.is_online ? 'онлайн' : 'оффлайн'}
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {row.installed ? (
                      <GpsFixed style={styles.icon} />
                    ) : (
                      <GpsOff color="error" style={styles.icon} />
                    )}
                    {row.installed ? 'установлено' : 'не установлено'}
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="dense">
                  {row.services_count}
                </TableCell>
              </TableRow>
            )
          })
      : range(props.rowsPerPage).map(key => (
          <TableRow key={key}>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
            <TableCell component="th" scope="row" padding="dense">
              <Skeleton />
            </TableCell>
          </TableRow>
        ))}
  </TableBody>
)

const styles = {
  icon: {
    width: '0.8125rem',
    height: '0.8125rem',
    marginRight: 8,
  },
}
