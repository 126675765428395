//@flow
import * as types from './types'

type State = {
  +collection: ?string,
  +error: ?string,
}

type Action = {
  type: string,
  payload?: Object | string | Array<Object>,
}

const initialState: State = {
  collection: null,
  error: null,
}

const companies = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case types.GET_COMPANIES_SUCCESS:
      return { ...state, collection: payload, error: null }
    case types.GET_COMPANIES_ERROR:
      return { ...state, error: null }
    default:
      return state
  }
}

export default companies
