import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
  Select,
  Loader,
  Button,
  FullscreenDialogTitle,
  FullscreenExitButton,
} from 'sputnik-ui'
import { withRouter } from 'react-router-dom'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Fade,
  Typography,
} from '@material-ui/core/'
import { Send } from '@material-ui/icons'
import { Formik } from 'formik'
import * as Yup from 'yup'
import config from 'config'

import { getApps, inviteUser, getUsers } from 'store/operations'
import { isEmail, isPhone } from 'utils/helpers'

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Transition = (props) => <Fade in {...props} />

const validationSchema = () =>
  Yup.object().shape({
    login: Yup.string()
      .typeError('введите e-mail или телефон')
      .test(
        'login',
        'введите e-mail или телефон',
        (item) => isEmail(item) || isPhone(item)
      )
      .required('введите e-mail или телефон'),
  })

class InviteUserModal extends React.Component {
  state = {
    selectedApps: [],
    appsError: '',
  }

  componentDidMount() {
    this.props.dispatch(getApps())
  }

  handleSelect = (apps) => this.setState({ selectedApps: apps })

  handleinviteUser = (values) => {
    const { selectedApps } = this.state
    if (!selectedApps || selectedApps.length < 1) {
      this.setState({ appsError: true })
    } else {
      this.setState({ appsError: false })
    }
    const body = {
      login: values.login,
      app_ids: selectedApps.map((o) => o.value),
    }

    this.props.dispatch(inviteUser(body)).then(({ user_id }) => {
      this.props.dispatch(getUsers({}))
      window.open(`${config.userUri}/user/${user_id}`, '_blank')
      this.props.handleClose()
    })
  }

  toggleModal = (modal) => () => this.setState({ [modal]: !this.state[modal] })

  render() {
    const { open, handleClose, apps, appsError } = this.props

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullScreen
      >
        <FullscreenDialogTitle
          subtitle="На указанный e-mail придет письмо с приглашением к
                      регистрации, пройдя которую пользователь получит доступ к
                      выбранным приложениям."
        >
          Пригласить нового пользователя
        </FullscreenDialogTitle>
        <FullscreenExitButton onClick={handleClose} />
        <Formik
          initialValues={{
            selectedApps: [],
            login: '',
          }}
          onSubmit={this.handleinviteUser}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          render={({
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContent
                style={{
                  maxWidth: '40rem',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  margin: '0 auto',
                }}
              >
                {!apps ? (
                  <Loader
                    msg="Загружаем список приложений..."
                    error={appsError}
                  />
                ) : (
                  <>
                    <TextField
                      autoFocus
                      fullWidth
                      name="login"
                      defaultValue={values.login}
                      label="Электронная почта \ Телефон"
                      onChange={handleChange}
                      error={touched.login && !!errors.login}
                      autoComplete="login "
                      helperText={touched.login && errors.login}
                      onBlur={handleBlur}
                      margin="dense"
                      style={{ marginBottom: '1rem' }}
                    />

                    <Select
                      options={
                        apps &&
                        apps.map((s) => ({
                          value: s.app_id,
                          label: s.name,
                        }))
                      }
                      selected={this.state.selectedApps}
                      handleSelect={this.handleSelect}
                      placeholder="Список доступных приложений"
                    />
                    {this.state.appsError && (
                      <Typography color="error">Выберите приложение</Typography>
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: 'center',
                  margin: '1.325rem',
                  marginTop: 'auto',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="fullScreen"
                  type="submit"
                  rounded
                >
                  <Send style={{ marginRight: '8px' }} />
                  Пригласить
                </Button>
              </DialogActions>
            </Form>
          )}
        />
      </Dialog>
    )
  }
}

const mapStateToProps = ({ apps, auth: { user: authorizedUser }, users }) => ({
  apps: apps.collection,
  appsError: apps.error,
  authorizedUser,
  usersError: users.error,
})

export default connect(mapStateToProps)(withRouter(InviteUserModal))
