import React, { useCallback } from 'react'
import styled from 'styled-components'
import { formatDeviceId } from 'sputnik-ui'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Tooltip,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { Power, PowerOff, FileCopy } from '@material-ui/icons'
import cam from './cam.png'
import intercom from './sputnik.png'
import { Link } from 'react-router-dom'
import { toggleSelect } from 'store/devicesSlice'
import { useSelector, useDispatch } from 'react-redux'
// import { ReactComponent as Archive3 } from 'features/Services/archive-3.svg'
// import { ReactComponent as Archive5 } from 'features/Services/archive-5.svg'
// import { ReactComponent as Archive7 } from 'features/Services/archive-7.svg'
import { ReactComponent as FaceRec } from 'features/Services/face_rec.svg'
import { useTranslation } from 'react-i18next'
import { copyToClipboard } from 'utils/helpers'
import { showInfo } from 'features/ui/uiSlice'
import useHover from 'hooks/useHover'
import { Archive3, Archive5, Archive7, Archive14, Archive30, IconColors } from 'sputnik-icons'

const OnlineIcon = styled(Power)`
  color: ${(p) => p.theme.palette.success[500]};
  font-size: 0.825rem;
  margin-left: 0.325rem;
`
const OfflineIcon = styled(PowerOff)`
  color: ${(p) => p.theme.palette.error[500]};
  font-size: 0.825rem;
  margin-left: 0.325rem;
`

const styles = {
  icon: {
    fontSize: '0.625rem',
    marginRight: '.325rem',
    width: 14,
    height: 14,
  },
}

const serviceIcons = {
  archive_3: <Archive3 color={IconColors.blue} style={styles.icon} />,
  archive_5: <Archive5 color={IconColors.blue} style={styles.icon} />,
  archive_7: <Archive7 color={IconColors.blue} style={styles.icon} />,
  archive_14: <Archive14 color={IconColors.blue} style={styles.icon} />,
  archive_30: <Archive30 color={IconColors.blue} style={styles.icon} />,
  face_rec: <FaceRec style={styles.icon} />,
}

export default function Device({
  address,
  serial_number,
  services_count,
  services_sum,
  is_online,
  motherboard_id,
  uuid,
  type,
  service_partner,
  services,
  address_type,
}) {
  const dispatch = useDispatch()
  const selected = useSelector(({ devices }) => uuid in devices.selected)
  const handleToggle = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(
        toggleSelect({
          address,
          serial_number,
          services_count,
          services_sum,
          is_online,
          motherboard_id,
          uuid,
          type,
          service_partner,
          services,
        })
      )
    },
    [uuid]
  )
  const { t } = useTranslation(['service', 'device'])
  const { hovered, bind } = useHover()

  return (
    <ListItem button component={Link} to={`/devices/${uuid}`} style={{ paddingLeft: 0 }} {...bind}>
      <ListItemIcon style={{ minWidth: 'unset' }}>
        <Checkbox color="primary" checked={selected} onClick={handleToggle} />
      </ListItemIcon>
      <ListItemIcon>
        <img
          src={type === 'camera' ? cam : intercom}
          alt="device"
          style={{
            height: 32,
            width: 44,
            objectFit: 'contain',
            objectPosition: 'center',
          }}
        ></img>
      </ListItemIcon>

      <ListItemText
        // primary={agent_serial || formatDeviceId(motherboard_id)}
        style={{ marginRight: '1rem' }}
        primary={
          <>
            {type === 'camera' ? serial_number : formatDeviceId(serial_number)}
            {address_type === 'gate' ? ` [ ${t('device:gate')} ]` : null}
            {hovered && (
              <FileCopy
                style={{ width: 14, height: 14, marginLeft: 8 }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  copyToClipboard(serial_number)
                  dispatch(showInfo('Номер домофона был скопирован в буфер обмена'))
                }}
              />
            )}
          </>
        }
        secondary={
          <>
            {typeof is_online === 'boolean' && (
              <Tooltip title={is_online ? 'в сети' : 'не в сети'}>
                {is_online ? (
                  <OnlineIcon style={styles.icon} />
                ) : (
                  <OfflineIcon style={styles.icon} />
                )}
              </Tooltip>
            )}
            {services?.map((identifier) => {
              const Component = serviceIcons[identifier]

              return <>{Component}</>
            })}
            {`${address || 'Не установлена'}
            ${service_partner ? ` · Обслуживается «${service_partner}»` : ''}`}
          </>
        }
        secondaryTypographyProps={{
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      ></ListItemText>
      <ListItemSecondaryAction>
        <ListItemText
          primary={`${services_sum} ₽`}
          secondary={t(`service:units.service`, {
            count: services_count,
          })}
          primaryTypographyProps={{ align: 'right' }}
          secondaryTypographyProps={{ align: 'right' }}
        ></ListItemText>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
