import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'
import { showError, showMessage } from 'features/ui/uiSlice'

export const fetchCompanyServices = createAsyncThunk(
  'services/fetchCompanyServices',
  async (uuid, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        `/services?company_service=true`,
        {
          dispatch,
        },
        `${config.redirectUri}/api/v2`,
      )
      return payload
    } catch {
      return rejectWithValue()
    }
  },
)

const servicesSlice = createSlice({
  name: 'servicesNew',
  initialState: {
    companyServices: null,
  },
  extraReducers: {
    [fetchCompanyServices.fulfilled]: (state, { payload }) => {
      state.companyServices = payload
    },
  },
})

export default servicesSlice.reducer
