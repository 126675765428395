import React from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import styled from 'styled-components'

const Pagination = styled(TablePagination)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${p => p.theme.palette.background.secondary};
  box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
`

export default props => (
  <Pagination
    component="div"
    count={props.totalPages * props.rowsPerPage}
    rowsPerPage={props.rowsPerPage}
    rowsPerPageOptions={[10, 20, 30]}
    page={props.page}
    labelRowsPerPage="Устройств на странице:"
    labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
    onChangePage={props.handleChangePage}
    onChangeRowsPerPage={props.handleChangeRowsPerPage}
  />
)
