//
import * as React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import type { BillingAccount } from 'globalTypes'

import TextField from '@material-ui/core/TextField'

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // margin: 0 auto;
  // max-width: 100%;
  // padding: 2.625rem;
  //
  // @media screen and (min-width: 768px) {
  //   max-width: 35rem;
  // }
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.625rem;
  width: 100%;

  button:first-child {
    margin-right: 0.625rem;
  }
`

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: 0.625rem;
  }
`

const validationSchema = () => {
  return Yup.object().shape({
    company_name: Yup.string()
      .typeError('введите название компании')
      .required('введите название компании'),
    tin: Yup.string().nullable().typeError('введите ИНН'),
    email: Yup.string()
      .typeError('введите e-mail')
      .email('неверный формат')
      .required('введите e-mail'),
    phone_number: Yup.string().nullable().typeError('введите телефонный номер'),
    postal_code: Yup.number().nullable().typeError('должен состоять из цифр'),
    actual_address: Yup.string().nullable().typeError('заполните физ. адрес'),
    legal_address: Yup.string().nullable().typeError('заполните юр. адрес'),
    bic: Yup.string().nullable().typeError('введите БИК'),
    settlement_account: Yup.number()
      .nullable()
      .typeError('должен состоять из цифр')
      .test('len', 'должен состоять из 20-25 цифр', (val) => {
        const num = val && val.toString()
        return (num && num.length >= 20 && num.length <= 25) || num === null
      }),
    kpp: Yup.string()
      .nullable()
      .typeError('введите КПП')
      .test(
        'len',
        'должен состоять из 9 знаков',
        (val) => (val && val.length === 9) || val === null
      ),
    bank: Yup.string().nullable().typeError('введите банк'),
  })
}

function AddBillingAccountForm({
  billingAccount,
  isDisabled,
  ...props
}: Props) {
  return (
    <Formik
      initialValues={billingAccount}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={props.handleSubmit}
      enableReinitialize
      render={(formikProps) => {
        const {
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          values,
        } = formikProps
        return (
          <Form onSubmit={handleSubmit} style={props.style}>
            <TextField
              disabled={isDisabled}
              autoFocus
              name="company_name"
              defaultValue={values.company_name}
              label="Название компании"
              fullWidth
              onChange={handleChange}
              error={touched.company_name && !!errors.company_name}
              autoComplete="company_name"
              helperText={touched.company_name && errors.company_name}
              onBlur={handleBlur}
              margin="dense"
              required
            />

            <TextField
              disabled={isDisabled}
              name="tin"
              defaultValue={values.tin}
              label="ИНН"
              fullWidth
              onChange={handleChange}
              error={touched.tin && !!errors.tin}
              autoComplete="tin"
              helperText={touched.tin && errors.tin}
              onBlur={handleBlur}
              margin="dense"
            />
            <TextField
              name="contract_number"
              defaultValue={values.contract_number}
              label="Номер договора"
              fullWidth
              error={touched.contract_number && !!errors.contract_number}
              autoComplete="contract_number"
              helperText={touched.contract_number && errors.contract_number}
              onBlur={handleBlur}
              margin="dense"
              disabled
            />
            <TextField
              disabled={isDisabled}
              name="phone_number"
              defaultValue={values.phone_number}
              label="Телефон"
              fullWidth
              onChange={handleChange}
              error={touched.phone_number && !!errors.phone_number}
              autoComplete="phone_number"
              helperText={touched.phone_number && errors.phone_number}
              onBlur={handleBlur}
              margin="dense"
              required
            />

            <InputWrapper>
              <TextField
                disabled={isDisabled}
                name="email"
                defaultValue={values.email}
                label="Электронная почта"
                onChange={handleChange}
                error={touched.email && !!errors.email}
                autoComplete="email"
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                margin="dense"
                style={styles.responsiveInput}
              />
              <TextField
                disabled={isDisabled}
                name="postal_code"
                defaultValue={values.postal_code}
                label="Индекс"
                onChange={handleChange}
                error={touched.postal_code && !!errors.postal_code}
                autoComplete="postal_code"
                helperText={touched.postal_code && errors.postal_code}
                onBlur={handleBlur}
                margin="dense"
              />
            </InputWrapper>
            <TextField
              disabled={isDisabled}
              name="actual_address"
              defaultValue={values.actual_address}
              label="Фактический адрес"
              fullWidth
              onChange={handleChange}
              error={touched.actual_address && !!errors.actual_address}
              autoComplete="actual_address"
              helperText={touched.actual_address && errors.actual_address}
              onBlur={handleBlur}
              margin="dense"
            />
            <TextField
              disabled={isDisabled}
              name="bank"
              defaultValue={values.bank}
              label="Банк"
              fullWidth
              onChange={handleChange}
              error={touched.actual_address && !!errors.actual_address}
              autoComplete="bank"
              helperText={touched.bank && errors.bank}
              onBlur={handleBlur}
              margin="dense"
            />
            <TextField
              disabled={isDisabled}
              name="legal_address"
              defaultValue={values.legal_address}
              label="Юридический адрес"
              fullWidth
              onChange={handleChange}
              error={touched.legal_address && !!errors.legal_address}
              autoComplete="legal_address"
              helperText={touched.legal_address && errors.legal_address}
              onBlur={handleBlur}
              margin="dense"
            />
            <InputWrapper>
              <TextField
                disabled={isDisabled}
                name="bic"
                defaultValue={values.bic}
                label="БИК"
                onChange={handleChange}
                error={touched.bic && !!errors.bic}
                autoComplete="bic"
                helperText={touched.bic && errors.bic}
                onBlur={handleBlur}
                margin="dense"
                style={styles.responsiveInput}
              />
              <TextField
                disabled={isDisabled}
                name="settlement_account"
                defaultValue={values.settlement_account}
                label="Расчетный счет"
                onChange={handleChange}
                error={
                  touched.settlement_account && !!errors.settlement_account
                }
                autoComplete="settlement_account"
                helperText={
                  touched.settlement_account && errors.settlement_account
                }
                onBlur={handleBlur}
                style={styles.responsiveInput}
                margin="dense"
              />
              <TextField
                disabled={isDisabled}
                name="kpp"
                defaultValue={values.kpp}
                label="КПП"
                fullWidth
                onChange={handleChange}
                error={touched.kpp && !!errors.kpp}
                autoComplete="kpp"
                helperText={touched.kpp && errors.kpp}
                onBlur={handleBlur}
                margin="dense"
                style={styles.responsiveInput}
              />
            </InputWrapper>
            <ActionsWrapper>{props.children(formikProps)}</ActionsWrapper>
          </Form>
        )
      }}
    />
  )
}

const styles = {
  responsiveInput: {
    flex: 1,
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}

type Props = {
  billingAccount: BillingAccount,
  handleSubmit: Function,
  style: ?Object,
  children: Function,
  isDisabled?: boolean,
}

export default AddBillingAccountForm
