import React, { useState, useEffect, useCallback } from 'react'
import { Button, Select, Loader } from 'sputnik-ui'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Zoom from '@material-ui/core/Zoom'

import {
  getservicePartners,
  linkDevice,
  getIntercomsByAddress,
  getCamerasByAddress,
} from 'store/operations'
import { useTranslation } from 'react-i18next'
import {
  fetchPartners,
  reassignDevices,
} from 'store/service_partnersOld/operations'

import { fetchDevice, fetchDevices } from 'store/devicesSlice'

function Transition(props) {
  return <Zoom direction="up" {...props} />
}

export default function ReassignIntercomModal({
  onClose,
  device,
  open,
  ...props
}) {
  const [selected, setSelected] = useState()
  const dispatch = useDispatch()
  const { device: deviceUuid } = useParams()

  const servicePartners = useSelector(
    ({ servicePartners }) => servicePartners.list
  )
  const localityFilter = useSelector(({ localities }) => localities.filter)
  const servicePartnerFilter = useSelector(
    ({ servicePartners }) => servicePartners.filter
  )
  const page = useSelector(({ devices }) => devices.page)
  const limit = useSelector(({ devices }) => devices.limit)

  const devices = useSelector(({ devices }) => Object.keys(devices.selected))

  const handleSubmit = useCallback(() => {
    if (deviceUuid) {
      dispatch(reassignDevices(selected.value, [deviceUuid])).then(() => {
        dispatch(fetchDevice(deviceUuid))
        onClose()
      })
    } else {
      if (selected)
        dispatch(reassignDevices(selected.value, devices)).then(() => {
          setSelected(null)

          dispatch(
            fetchDevices({
              page,
              limit,
              locality_uuid: localityFilter.value,
              service_partner_uuid: servicePartnerFilter.value,
            })
          )

          onClose()
        })
    }
  }, [
    dispatch,
    onClose,
    devices,
    selected,
    page,
    limit,
    localityFilter.value,
    servicePartnerFilter.value,
  ])

  const { t } = useTranslation(['common', 'service'])

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      PaperProps={{ style: { overflow: 'visible', minWidth: '40%' } }}
    >
      <DialogTitle>{t('service:actions.reassign')}</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Select
          options={servicePartners}
          isMulti={false}
          isClearable={false}
          isSearchable={false}
          cacheOptions
          defaultOptions
          value={selected}
          onChange={setSelected}
          placeholder="Сервис-партнёр"
        ></Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} secondary rounded>
          {t('common:button.cancel')}
        </Button>
        <Button
          onClick={handleSubmit}
          primary
          rounded
          variant="contained"
          disabled={!selected || (selected && !selected.value)}
        >
          {t('common:button:confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
