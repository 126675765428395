import React, { useState } from 'react'
import { Wrapper } from 'sputnik-ui'
import { Divider } from '@material-ui/core'

import { List } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import { ListItemText } from '@material-ui/core'
import { ListItemSecondaryAction } from '@material-ui/core'
import { Switch } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import {
  activateService,
  deactivateService,
  fetchDevices,
  fetchDeviceServices,
} from 'store/devicesSlice'
import { unwrapResult } from '@reduxjs/toolkit'

import videoarchive from './VideoArchive.svg'
import Present from './Present.svg'
import facerec from './face_rec.png'
import { Typography, Tooltip } from 'sputnik-ui'
import { Question, IconColors } from 'sputnik-icons'

const images = {
  face_rec: facerec,
  archive_3: videoarchive,
  archive_5: videoarchive,
  archive_7: videoarchive,
  mobile_pack: Present,
}

export default function Service({ variants, name, description, ...props }) {
  const [selected, setSelected] = useState(variants.find((v) => v.is_active) || variants[0])

  const dispatch = useDispatch()

  const device = useSelector(({ devices }) => devices.current)
  const pending = useSelector(({ devices }) => devices.pendingServiceToggle)
  const handleToggle = (identifier) => async (e) => {
    try {
      if (e.target.checked) {
        await dispatch(
          activateService({
            service: identifier,
            devices: [{ uuid: device.uuid, type: device.type }],
          })
        ).then(unwrapResult)
      } else {
        await dispatch(
          deactivateService({
            service: variants.find((v) => v.is_active).identifier,
            devices: [{ uuid: device.uuid, type: device.type }],
          })
        ).then(unwrapResult)
      }
    } catch (err) {
    } finally {
      dispatch(fetchDeviceServices({ uuid: device.uuid }))
      dispatch(fetchDevices())
    }
  }

  return (
    <Wrapper shadow={false} style={{ flexDirection: 'column', paddingTop: 32, paddingBottom: 32 }}>
      <img
        alt=""
        src={images[selected?.identifier]}
        style={{
          objectFit: 'contain',
          objectPosition: 'center',
          marginBottom: '1rem',
          height: 60,
        }}
        // TODO: change to Mascotte component
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
        <Typography variant="h5" align="center">
          {name}
        </Typography>
        <Typography
          variant="caption"
          align="center"
          color="textSecondary"
          style={{ maxWidth: 360, whiteSpace: 'normal' }}
        >
          {description}
        </Typography>
      </div>
      <List style={{ padding: 0 }}>
        {variants.map(({ title, variant, is_active, identifier, cost, description }, idx) => (
          <div key={identifier}>
            <ListItem
              value={identifier}
              key={identifier}
              label={title}
              style={{
                marginRight: 0,
                paddingLeft: 0,
                paddingRight: 0,
                opacity: is_active ? 1 : 0.6,
                transition: 'all 200ms ease-in-out',
              }}
            >
              <ListItemText
                primary={
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      gap: '0.5rem',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    {variant}{' '}
                    <Tooltip
                      placement="bottom"
                      title={
                        <div>
                          {description.split(',').map((el) => {
                            return <li style={{ marginLeft: 0 }}>{el}</li>
                          })}
                        </div>
                      }
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Question color={IconColors.grey} />
                      </div>
                    </Tooltip>
                  </div>
                }
                secondary={<Typography variant="note">{!!cost && `${cost} ₽ в день`}</Typography>}
              />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  checked={is_active}
                  onChange={handleToggle(identifier)}
                  disabled={pending}
                />
              </ListItemSecondaryAction>
            </ListItem>
            {idx !== variants.length - 1 && (
              <Divider
                variant="inset"
                light
                style={{ marginLeft: 0, backgroundColor: '#e6ecf1' }}
              />
            )}
          </div>
        ))}
      </List>
    </Wrapper>
  )
}
