// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import styled from 'styled-components'
import { Container,  } from 'sputnik-ui'

const Content = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow-x: hidden;
  height: auto;
`

const AccountsTemplate = ({ header, children, subheader, ...props }) => {
  return (
    <Content>
      {subheader}
      {children}
    </Content>
  )
}

export default AccountsTemplate
