//@flow
//$FlowFixMe
import React, { useEffect, useState } from 'react'
import { Drawer } from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { connect, useSelector, useDispatch } from 'react-redux'
import { BrowserHistory, useHistory, useParams } from 'react-router'
import { FullscreenExitButton } from 'sputnik-ui'
import DeviceServicesHistory from 'features/Devices/DeviceServicesHistory'

import Content from './Content'

import { clearCurrentDevice } from 'store/devicesSlice'
import ReassignIntercomModal from '../ReassignIntercomModal'
import {
  fetchDevice,
  fetchDeviceServices,
  toggleSelect,
} from 'store/devicesSlice'

type Props = {
  match: Match,
  dispatch: Function,
  history: BrowserHistory,
  width: number,
}

function DeviceDetails(props: Props) {
  const { width, ...restProps } = props

  const history = useHistory()

  const { device: deviceUuid } = useParams()
  const dispatch = useDispatch()
  const device = useSelector(({ devices }) => devices.current)

  useEffect(() => {
    if (deviceUuid) {
      dispatch(fetchDevice(deviceUuid))
      dispatch(
        fetchDeviceServices({
          uuid: deviceUuid,
        }),
      )
    }
    return () => {
      dispatch(clearCurrentDevice())
    }
  }, [deviceUuid, dispatch])

  const handleClose = () => history.push('/devices')
  const [reassignModal, setReassignModal] = useState(false)
  const toggleReassignModal = () => {
    setReassignModal(!reassignModal)
  }

  const [historyOpen, setHistory] = useState(false)

  return (
    <Drawer
      onOpen={() => {}}
      anchor="right"
      open={!!deviceUuid}
      onClose={handleClose}
      elevation={10}
      PaperProps={{
        style: {
          width: isWidthUp('sm', width) ? '70%' : '100%',
          overflowX: 'hidden',
          // position: 'relative',
        },
      }}
      ModalProps={{
        BackdropProps: { style: { backgroundColor: 'rgba(0,0,0,0.1)' } },
      }}
    >
      {!isWidthUp('sm', width) && (
        <FullscreenExitButton variant="back" onClick={handleClose} />
      )}

      <Content
        toggleReassignModal={toggleReassignModal}
        openHistory={() => setHistory(true)}
      />

      <DeviceServicesHistory
        open={historyOpen}
        onClose={() => setHistory(false)}
        device={deviceUuid}
      />
      <ReassignIntercomModal
        onClose={toggleReassignModal}
        open={reassignModal}
      ></ReassignIntercomModal>
    </Drawer>
  )
}

//$FlowFixMe
export default withWidth()(DeviceDetails)
