//@flow
import * as React from 'react'
import TableHead from './TableHead'
import { Wrapper } from 'sputnik-ui'
import styled from 'styled-components'

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { TableContainer } from '@material-ui/core'

type User = {
  id: number,
  email: string,
  company: string,
  full_name: string,
  phone: string,
}

type P = {
  users: Object[],
  handleChangePage: Function,
  page: number,
  limit: number,
  handleChangeRowsPerPage: Function,
  count: number,
  style: Object,
  actions?: React.ReactNode,
}

type S = {
  order: string,
  orderBy: string,
  data: Object[],
}

const StyledToolbar = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: space-between;
  background-color: ${(p) => p.theme.palette.other.pink};
  align-items: center;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    justify-content: center;
  }

  & > button {
    font-weight: normal;
  }
`

class UsersTable extends React.Component<P, S> {
  render() {
    const { actions, page, limit, order, sort, ...props } = this.props

    return (
      <div style={{paddingBottom:'3rem'}}>
        <StyledToolbar>{actions}</StyledToolbar>
        <TableContainer component={Wrapper}>
          <Table
            aria-label="simple table"
            style={{
              width: '100%',
              background: 'white',
              tableLayout: 'fixed',
            }}>
            <TableHead
              order={order}
              orderBy={sort}
              onRequestSort={this.props.handleRequestSort}
              rowCount={limit}
            />

            <TableBody>
              {this.props.users.map((n: User) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={n.id}
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                    }}
                    onClick={() =>
                      window.open(
                        `https://account.sputnik.systems/user/${n.id}`,
                        '_blank'
                      )
                    }
                    // data-aos="fade-left"
                  >
                    <TableCell component="th" scope="row" padding="dense">
                      {n.email}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="dense">
                      {n.full_name}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="dense">
                      {n.phone}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component={Wrapper}
          count={this.props.count}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 20, 30]}
          page={page}
          labelRowsPerPage="Строк на странице:"
          labelDisplayedRows={({ from, to, count }) =>
            //$FlowFixMe
            `${from}-${to} из ${count}`
          }
          onChangePage={this.props.handleChangePage}
          onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
          style={{ background: 'white' }}
        />
      </div>
    )
  }
}

export default UsersTable
