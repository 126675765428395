//$FlowFixMe
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  FullscreenExitButton,
  FullscreenDialogTitle,
  Container,
  useInput,
} from 'sputnik-ui'
import {
  Dialog,
  Fade,
  InputAdornment,
  Typography,
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Switch,
  FormControlLabel,
  IconButton,
} from '@material-ui/core'

import { Help, RemoveCircle, AddCircle, Edit, Launch } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

const Transition = (props) => <Fade in {...props} />

const styles = {
  row: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  cell: {
    border: 'none',
  },
  valueCell: {
    border: 'none',
    width: '10%',
    maxWidth: 200,
  },

  field: {
    flex: 1,
  },

  helpIcon: {
    marginLeft: 'auto',
    position: 'absolute',
    right: 4,
    height: '1.2rem',
    cursor: 'help',
  },
}

const Row = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 1rem 0;
  & > * {
    flex: 1;

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    flex-direction: column;

    & > * {
      flex: 1;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
`

const cellCss = css`
  border: none;
  font-size: 1.125rem;
  position: relative;
`

const NameCell = styled(TableCell).attrs({
  align: 'left',
})`
  && {
    ${cellCss};
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    height: inherit;
  }
`

const ValueCell = styled(TableCell).attrs((p) => ({
  align: p.combined ? 'center' : 'center',
}))`
  && {
    ${cellCss};
    width: ${(p) => (p.combined ? 30 : 15)}%;
    padding-right: 0 !important;
    padding-left: 0 !important;
    font-weight: ${(p) => (p.bold ? 600 : 400)};
    background: ${(p) =>
      p.combined
        ? p.theme.palette.grayscale[1]
        : p.month
        ? p.theme.palette.other.pink
        : p.theme.palette.other.green};

    & svg {
      color: ${(p) => p.theme.palette.text.secondary};
    }

    ${(p) =>
      p.hover &&
      css`
        cursor: pointer;

        &:hover {
          opacity: 0.4;
        }
      `}
  }
`

const BuyProductModal = ({
  open = true,
  accountBalance,
  dispatch,
  isLoading,
  t,
}: Props) => {
  const history = useHistory()
  const handleClose = () => history.push('/products')
  const { product: productIdx } = useParams()
  const product = useSelector(({ products }) => products.list[productIdx])

  // dynamic
  const { value: flatsQty, bind: bindFlatsQty } = useInput(20)
  const { value: flatPayment, bind: bindFlatPayment } = useInput(45)
  const { value: profitCoef, bind: bindProfitCoef } = useInput(0.95)
  const [archiveEnabled, setArchiveEnabled] = useState(true)

  // entry profit
  const [entryProfit, setEntryProfit] = useState(
    flatPayment * flatsQty * profitCoef,
  )

  // entry expenses
  const { value: maintenance, bind: bindMaintenance } = useInput(300)
  const { value: internet, bind: bindInternet } = useInput(100)
  const [sputnikBilling, setSputnikBilling] = useState(0)
  const [videoArchive, setVideoArchive] = useState(archiveEnabled ? 150 : 0)
  const [totalExpenses, setTotalExpenses] = useState(
    maintenance + internet + sputnikBilling + videoArchive,
  )

  // investments
  const [sputnikCount, setSputnikCount] = useState(1)
  const [sputnikPrice, setSputnikPrice] = useState(
    (product?.cost / 100) * sputnikCount,
  )
  const [preparationWork] = useState(4200)
  const [totalInvestments, setTotalInvestments] = useState(
    preparationWork + sputnikPrice,
  )

  // profits
  const [buyerMargin, setBuyerMargin] = useState(entryProfit - totalExpenses)
  const [buyerPayback, setBuyerPayback] = useState(
    totalInvestments / buyerMargin,
  )
  // const [revenueRoR, setRevenueRoR] = useState(
  //   (buyerMargin / entryProfit) * 100,
  // )
  const [investmentsRoR, setInvestmentsRoR] = useState(
    ((buyerMargin * 12) / totalInvestments) * 100,
  )

  useEffect(() => {
    setEntryProfit(flatPayment * flatsQty * profitCoef)
    setVideoArchive(archiveEnabled ? 150 : 0)
    setTotalExpenses(
      Number(maintenance) + Number(internet) + sputnikBilling + videoArchive,
    )

    setSputnikPrice((product?.cost / 100) * sputnikCount)
    setTotalInvestments(sputnikPrice + preparationWork)

    setBuyerMargin(entryProfit - totalExpenses)
    setBuyerPayback(totalInvestments / buyerMargin)
    // setRevenueRoR((buyerMargin / entryProfit) * 100)
    setInvestmentsRoR(((buyerMargin * 12) / totalInvestments) * 100)
  }, [
    flatsQty,
    flatPayment,
    profitCoef,
    archiveEnabled,
    entryProfit,
    totalExpenses,
    totalInvestments,
    buyerMargin,
    maintenance,
    internet,
    sputnikBilling,
    videoArchive,
    sputnikCount,
    preparationWork,
    sputnikPrice,
    product,
  ])

  const handleSpuntikBillingClick = () => {
    let nextVal
    switch (sputnikBilling) {
      case 0:
        nextVal = 50
        break
      case 50:
        nextVal = 200
        break
      case 200:
        nextVal = 0
        break
      default:
        break
    }
    setSputnikBilling(nextVal)
  }
  const toggleArchive = () => setArchiveEnabled(!archiveEnabled)

  const [internetEditable, setInternetEditable] = useState(false)
  const [maintenanceEditable, setMaintenanceEditable] = useState(false)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen
    >
      <FullscreenExitButton variant="close" onClick={handleClose} />
      <FullscreenDialogTitle
        subtitle={
          <Typography inline color="inherit">
            Помогает рассчитать стоимость модернизации одного подъезда и оценить
            сроки окупаемости.
          </Typography>
        }
      >
        {`Unit-экономика для ${t(`products:devices.${product?.name}_subject`)}`}
      </FullscreenDialogTitle>

      <Container
        style={{
          paddingBottom: '6rem',
          height: '-webkit-fill-available',
          maxWidth: '50rem',
        }}
      >
        {/* <Typography variant="h5" align="center">
          Вводные данные
        </Typography> */}
        {/* <FormControlLabel
          control={<Switch checked={archiveEnabled} onChange={toggleArchive} />}
          label="Архивировать видео"
        /> */}
        <Row>
          <TextField
            {...bindFlatsQty}
            label="Квартир в подъезде"
            InputProps={{
              endAdornment: <InputAdornment position="end">кв.</InputAdornment>,
            }}
            // eslint-disable-next-line
            inputProps={{
              type: 'number',
              step: 1,
              min: 0,
              max: 1000,
            }}
            placeholder="20"
          />
          <TextField
            {...bindFlatPayment}
            label="Плата с квартиры"
            InputProps={{
              endAdornment: <InputAdornment position="end">₽</InputAdornment>,
            }}
            // eslint-disable-next-line
            inputProps={{
              type: 'number',
              step: 1,
              min: 0,
              max: 1000,
            }}
            placeholder="45"
          />
          <TextField
            {...bindProfitCoef}
            label="Коэффициент собираемости"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            placeholder="0.5-1"
            helperText="Процент жителей, оплачивающих услугу обслуживания домофона."
            // eslint-disable-next-line
            inputProps={{
              type: 'number',
            }}
          />
        </Row>

        <Typography variant="h5" align="center">
          Выручка на один подъезд составит
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <NameCell />
              <ValueCell month>в месяц</ValueCell>
              <ValueCell>в год</ValueCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <NameCell>абонентские платежи жителей за услугу домофон</NameCell>
              <ValueCell month>
                {t('common:format.number', { number: entryProfit })} ₽
                <Tooltip title="считаем как количество квартир, умноженное на плату с квартиры и коэффициент собираемости">
                  <Help style={styles.helpIcon} />
                </Tooltip>
              </ValueCell>
              <ValueCell>
                {t('common:format.number', {
                  number: entryProfit * 12,
                })}{' '}
                ₽
              </ValueCell>
            </TableRow>
          </TableBody>
        </Table>

        <Typography variant="h5" align="center">
          Расходы на один подъезд
        </Typography>
        <Table>
          <TableBody>
            <Tooltip title="Это расходы, которые уже есть у домофонных компаний на офис, инженеров, расходники">
              <TableRow hover>
                <NameCell>себестоимость обслуживания</NameCell>
                <ValueCell
                  month
                  hover={!maintenanceEditable}
                  onClick={() => setMaintenanceEditable(true)}
                >
                  {maintenanceEditable ? (
                    <TextField
                      autoFocus
                      {...bindMaintenance}
                      onBlur={() => setMaintenanceEditable(false)}
                    />
                  ) : (
                    <>
                      {t('common:format.number', { number: maintenance })} ₽
                      <Tooltip title="от 300 до 500 ₽. Нажмите для редактирования">
                        <Edit style={styles.helpIcon} />
                      </Tooltip>
                    </>
                  )}
                </ValueCell>
                <ValueCell>
                  {t('common:format.number', { number: maintenance * 12 })} ₽
                </ValueCell>
              </TableRow>
            </Tooltip>
            <Tooltip title="Возможно бесплатно.">
              <TableRow hover>
                <NameCell>интернет (1 Мбит/c)</NameCell>
                <ValueCell
                  month
                  onClick={() => setInternetEditable(true)}
                  hover={!internetEditable}
                >
                  {internetEditable ? (
                    <TextField
                      autoFocus
                      {...bindInternet}
                      onBlur={() => setInternetEditable(false)}
                    />
                  ) : (
                    <>
                      {t('common:format.number', { number: internet })} ₽
                      <Tooltip title="Нажмите для редактирования">
                        <Edit style={styles.helpIcon} />
                      </Tooltip>
                    </>
                  )}
                </ValueCell>
                <ValueCell>
                  {t('common:format.number', { number: internet * 12 })} ₽
                </ValueCell>
              </TableRow>
            </Tooltip>
            <Tooltip title="На данный момент мы предоставляем на выбор три тарифа: 0-50-200 рублей с подъезда.">
              <TableRow hover>
                <NameCell>
                  лицензионные платежи Спутнику
                  <IconButton
                    onClick={() => setSputnikBilling(0)}
                    disableRipple
                    color={sputnikBilling === 0 ? 'primary' : 'textSecondary'}
                    style={{ padding: 8, marginLeft: 'auto' }}
                  >
                    0
                  </IconButton>
                  <IconButton
                    onClick={() => setSputnikBilling(50)}
                    disableRipple
                    color={sputnikBilling === 50 ? 'primary' : 'textSecondary'}
                    style={{ padding: 8, marginLeft: 8 }}
                  >
                    50
                  </IconButton>
                  <IconButton
                    onClick={() => setSputnikBilling(200)}
                    disableRipple
                    color={sputnikBilling === 200 ? 'primary' : 'textSecondary'}
                    style={{ padding: 8, marginLeft: 8 }}
                  >
                    200
                  </IconButton>
                </NameCell>
                <ValueCell month hover onClick={handleSpuntikBillingClick}>
                  {t('common:format.number', { number: sputnikBilling })} ₽
                </ValueCell>
                <ValueCell hover onClick={handleSpuntikBillingClick}>
                  {t('common:format.number', { number: sputnikBilling * 12 })} ₽
                </ValueCell>
              </TableRow>
            </Tooltip>
            <TableRow hover onClick={toggleArchive}>
              <NameCell>
                архивирование видео (5 суток)
                <FormControlLabel
                  control={
                    <Switch
                      checked={archiveEnabled}
                      // onChange={toggleArchive}
                    />
                  }
                  style={{ marginLeft: 'auto', marginRight: 0 }}
                />
              </NameCell>
              <ValueCell month hover>
                {t('common:format.number', { number: videoArchive })} ₽
              </ValueCell>
              <ValueCell hover>
                {t('common:format.number', { number: videoArchive * 12 })} ₽
              </ValueCell>
            </TableRow>
            <TableRow>
              <NameCell />
              <ValueCell month bold>
                {t('common:format.number', { number: totalExpenses })} ₽
              </ValueCell>
              <ValueCell bold>
                {t('common:format.number', { number: totalExpenses * 12 })} ₽
              </ValueCell>
            </TableRow>
          </TableBody>
        </Table>

        <Typography variant="h5" align="center">
          Инвестиции
        </Typography>
        <Table>
          <TableBody>
            <Tooltip title="Бывает так, что на подъезде несколько Спутников">
              <TableRow hover>
                <NameCell>
                  домофон Спутник ({sputnikCount}{' '}
                  {t('products:units.device', { count: sputnikCount })} на
                  подъезд)
                  <IconButton
                    style={{ marginLeft: 'auto' }}
                    onClick={() => setSputnikCount(sputnikCount - 1)}
                    disabled={sputnikCount <= 1}
                  >
                    <RemoveCircle />
                  </IconButton>
                  <IconButton onClick={() => setSputnikCount(sputnikCount + 1)}>
                    <AddCircle />
                  </IconButton>
                </NameCell>
                <ValueCell combined month>
                  {t('common:format.number', { number: sputnikPrice })} ₽
                </ValueCell>
              </TableRow>
            </Tooltip>
            <TableRow hover>
              <NameCell>
                демонтаж старого домофона, монтаж Спутника и расходники
              </NameCell>
              <ValueCell combined month>
                {t('common:format.number', { number: preparationWork })} ₽
                <Tooltip title="Ссылка на расчеты.">
                  <a
                    component="a"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="http://help.sputnik.systems/ru/articles/3002015-%D0%BF%D1%80%D0%B8%D0%B1%D0%BB%D0%B8%D0%B7%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B0%D1%81%D1%87%D0%B5%D1%82-%D0%B7%D0%B0%D1%82%D1%80%D0%B0%D1%82-%D0%BD%D0%B0-%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%BA%D1%83-%D0%B0%D0%B7%D1%83-%D1%81%D0%BF%D1%83%D1%82%D0%BD%D0%B8%D0%BA"
                  >
                    <Launch style={{ ...styles.helpIcon, cursor: 'pointer' }} />
                  </a>
                </Tooltip>
              </ValueCell>
            </TableRow>
            <TableRow hover>
              <NameCell />
              <ValueCell month combined bold>
                {t('common:format.number', { number: totalInvestments })} ₽
              </ValueCell>
            </TableRow>
          </TableBody>
        </Table>

        <Typography variant="h5" align="center">
          Окупаемость
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <NameCell />
              <ValueCell month>в месяц</ValueCell>
              <ValueCell>в год</ValueCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow hover>
              <NameCell>операционная прибыль от подъезда</NameCell>
              <ValueCell month bold>
                {t('common:format.number', { number: buyerMargin })} ₽
                <Tooltip title="считаем как выручку минус расходы">
                  <Help style={styles.helpIcon} />
                </Tooltip>
              </ValueCell>
              <ValueCell bold>
                {t('common:format.number', { number: buyerMargin * 12 })} ₽
              </ValueCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow hover>
              <NameCell>срок окупаемости</NameCell>
              <ValueCell month bold combined>
                {t('common:format.number', {
                  number: Math.floor(buyerPayback),
                })}{' '}
                {t('time:units.month', {
                  count: Math.floor(buyerPayback),
                })}
                <Tooltip title="считаем как сумму инвестиций, разделенную на операционную прибыль">
                  <Help style={styles.helpIcon} />
                </Tooltip>
              </ValueCell>
            </TableRow>
            {/* <TableRow hover>
              <NameCell>рентабельность выручки</NameCell>
              <ValueCell month combined>
                {t('common:format.number', { number: Math.round(revenueRoR) })}{' '}
                %
                <Tooltip title="маржа / выручку" placement="top">
                  <Help style={styles.helpIcon} />
                </Tooltip>
              </ValueCell>
            </TableRow> */}

            <TableRow hover>
              <NameCell>рентабельность инвестиций</NameCell>
              <ValueCell month combined>
                {t('common:format.number', {
                  number: Math.round(investmentsRoR),
                })}{' '}
                %
                <Tooltip
                  title="считаем как операционную прибыль за год, разделенную на сумму инвестиций"
                  placement="top"
                >
                  <Help style={styles.helpIcon} />
                </Tooltip>
              </ValueCell>
            </TableRow>
          </TableBody>
        </Table>
      </Container>
    </Dialog>
  )
}

type Props = {
  open: boolean,
  handleClose: Function,
  dispatch: Function,
  history: Object,
  isLoading: boolean,
  product: Object,
}

export default withTranslation(['common', 'products', 'time'])(BuyProductModal)
