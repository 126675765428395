import { DialogTitle } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { Dialog, DialogContent } from '@material-ui/core'
import config from 'config'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'sputnik-ui'
import Api from 'utils/api'

export default function LicenseModal({ ...props }) {
  const companyUuid = useSelector(({ auth }) => auth.user?.company.uuid)
  const [open, setOpen] = useState(false)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    if (companyUuid) {
      ;(async () => {
        const status = await Api.request(`/partners/companies/${companyUuid}`)
          .license_agreement_accepted
        setOpen(status === false)
      })()
    }
  }, [companyUuid])

  const handleClick = useCallback(() => {
    ;(async () => {
      setPending(true)
      try {
        await Api.request(`/partners/companies/${companyUuid}`, {
          method: 'PATCH',
          body: JSON.stringify({ license_agreement_accepted: true }),
        })
        setOpen(false)
      } catch (error) {
      } finally {
        setPending(false)
      }
    })()
  }, [companyUuid])

  return (
    <Dialog open={open}>
      <DialogTitle>У нас обновилось лицензионное соглашение!</DialogTitle>
      <DialogContent>
        Нажимая "Присоединиться" и продолжая использование аккаунта, вы
        подтверждаете присоединение к{' '}
        <a
          style={{
            color: 'black',
            paddingBottom: '2px',
            borderBottom: '1px solid black',
            textDecoration: 'none',
          }}
          target="_blank"
          rel="noreferrer"
          href={config.ssoUri + '/license.pdf'}>
          лицензионному соглашению
        </a>{' '}
        в обновленной редакции.
      </DialogContent>
      <DialogActions>
        <Button primary disabled={pending} onClick={handleClick}>
          Присоединиться {pending && '...'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/*

PATCH  /api/v1/partners/companies/:uuid
{"license_agreement_accepted": true}

*/
