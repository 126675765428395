import React, { useMemo } from 'react'
import { Select, Wrapper, Container, Button } from 'sputnik-ui'
import Divider from '@material-ui/core/Divider'
import CardContent from '@material-ui/core/CardContent'
import { useDispatch } from 'react-redux'
import { permissionsActions } from 'store/permissions'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

function isEmptyPermissions([, val]) {
  return val.length < 1
}

export const CompanyPermissionsCard = ({ permissions, appAbilities, handleSelect }) => {
  const { t } = useTranslation('common')
  const { setPermissionsList } = permissionsActions
  const dispatch = useDispatch()
  const appAbilitiesList = appAbilities[0].resources

  const handleSelectAllPermissions = useCallback(() => {
    let nextPermissions = {}
    Object.entries(appAbilitiesList).forEach(([key, value]) => {
      nextPermissions[key] = value.privileges.map((el) => el.action)
    })

    dispatch(setPermissionsList(nextPermissions))
  }, [appAbilitiesList, dispatch, setPermissionsList])

  const handleUnSelectAllPermissions = useCallback(() => {
    let nextPermissions = {}
    Object.entries(permissions).forEach(([key, value]) => {
      nextPermissions[key] = []
    })

    dispatch(setPermissionsList(nextPermissions))
  }, [dispatch, permissions, setPermissionsList])

  const permissionsAsArray = Object.entries(permissions)

  const isDisabledRejectAll =
    permissionsAsArray.length > 0 ? permissionsAsArray.every(isEmptyPermissions) : true

  const allPrivilegesCount = Object.values(appAbilitiesList).reduce((acc, value) => {
    return acc + value.privileges.length
  })

  const allActivePrivilegesCount = permissionsAsArray.reduce((acc, value) => {
    return acc + value[1].length
  }, 0)

  const isDisabledSelectAll = allPrivilegesCount === allActivePrivilegesCount

  return (
    <Wrapper style={{ flexDirection: 'column', padding: 0 }} shadow={false} border={false}>
      <Divider style={{ marginBottom: '1.625rem' }} />
      <dix style={{ display: 'flex' }}>
        <Button
          variant="v2"
          tertiary
          onClick={handleSelectAllPermissions}
          disabled={isDisabledSelectAll}
        >
          {t('common:button.select_all')}
        </Button>
        <Button
          variant="v2"
          tertiary
          onClick={handleUnSelectAllPermissions}
          disabled={isDisabledRejectAll}
        >
          {t('common:button.reset')}
        </Button>
      </dix>
      <Container>
        <CardContent
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          {appAbilities &&
            Object.entries(appAbilitiesList).map(([key, ability]) => {
              const options = ability.privileges.map((s) => ({
                value: s.action,
                label: s.translation,
                isDisabled: s.feature,
              }))
              const selected = (permissions[key] && permissions[key]) || null

              const selectedOptions = options.filter((o) => selected && selected.includes(o.value))

              return (
                <div key={key}>
                  <Select
                    isClearable={true}
                    label={ability.translation}
                    handleSelect={handleSelect(key, ability.value)}
                    selected={selectedOptions}
                    options={options}
                    placeholder="Список действий, доступных пользователю"
                  />
                </div>
              )
            })}
        </CardContent>
      </Container>
    </Wrapper>
  )
}
