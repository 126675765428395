import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, formatDate } from 'utils/helpers'
import config from 'config'
import { showError, showMessage } from 'features/ui/uiSlice'

export const downloadReport = createAsyncThunk(
  'analytics/downloadReport',
  async (params = {}, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        buildUrl('/analytics/reports/citizen_registrations', params),
        {
          dispatch,
          method: 'POST',
        },
        `${config.redirectUri}/api/v2`,
        true
      )

      const url = window.URL.createObjectURL(new Blob([payload]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Спутник отчёт ${new Date(
          params.start_date
        ).toLocaleDateString()} - ${new Date(
          params.end_date
        ).toLocaleDateString()}.${params.download_type}`
      ) //or any other extension
      document.body.appendChild(link)
      link.click()
      return payload
    } catch {
      dispatch(showError('При формировании отчёта произошла ошибка'))
      return rejectWithValue()
    }
  }
)

export const downloadReportProgram = createAsyncThunk(
  'analytics/downloadReport',
  async (params = {}, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        buildUrl('/analytics/reports/partner_program', params),
        {
          dispatch,
          method: 'POST',
        },
        `${config.redirectUri}/api/v2`,
        true
      )

      const url = window.URL.createObjectURL(new Blob([payload]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `Спутник отчёт ${new Date(
          params.start_date
        ).toLocaleDateString()} - ${new Date(
          params.end_date
        ).toLocaleDateString()}.${params.download_type}`
      ) //or any other extension
      document.body.appendChild(link)
      link.click()
      return payload
    } catch {
      dispatch(showError('При формировании отчёта произошла ошибка'))
      return rejectWithValue()
    }
  }
)

export const analytics = createSlice({
  name: 'analytics',
  initialState: {},
  reducers: {},
  extraReducers: {
    [downloadReport.pending]: (state) => {
      state.pending = true
    },
    [downloadReport.fulfilled]: (state) => {
      state.pending = false
    },
    [downloadReport.rejected]: (state) => {
      state.pending = false
    },
  },
})

export const {} = analytics.actions
export default analytics.reducer
