import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 7.125rem);
  width: 100%;
  /* flex-direction: column; */
  overflow: hidden;

  @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
    /* flex-direction: column;
    overflow: scroll;
    margin-bottom: 4rem; */
    margin-bottom: 4rem;
    height: fit-content;
  }
`

const BuyPageTemplate = ({ navigationButtons, children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

export default BuyPageTemplate
