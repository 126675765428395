import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { ServicePageTemplate } from 'components'
import { Mascotte } from 'sputnik-ui'
import { Dialog, IconButton, Typography, Tooltip } from '@material-ui/core'
import { Close, CreditCard, Launch } from '@material-ui/icons'
import Skeleton from 'react-loading-skeleton'
import { formatDate } from 'utils/helpers'

import {
  fetchService,
  fetchServices,
  fetchPackages,
  // deactivateService,
  // activateService,
} from 'store/services/operations'
import { clearServices } from 'store/services/actions'
import type { IService, BillingAccount } from 'globalTypes'

const MARGIN = 2

const Title = styled(Typography)`
  && {
    font-weight: 300;
    font-size: 2.125rem;
    /* line-height: 2.625rem; */
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    /* overflow-x: hidden; */
    /* margin-top: ${MARGIN}rem; */
    min-width: 80px;
    /* height: 50px; */
    position: relative;
    overflow: visible;
    text-decoration:none;
    &:hover {
      color: #333333ae;
    }
  }
`

const Description = styled(Typography)`
  && {
    min-width: 160px;
    margin-top: 4rem;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    margin-top: ${MARGIN * 2}rem;
    font-size: 1rem;
  }
`

const NextPayment = styled(Typography)`
  && {
    /* font-weight: 300; */
    text-overflow: ellipsis;
    overflow: hidden;
    height: 20px;
    font-size: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
`

type Props = {
  service: IService,
  dispatch: Function,
  billingAccount: BillingAccount,
}

function ServicePage({
  service,
  billingAccount,
  dispatch,
  match,
  open,
  onClose,
  loading,
}: Props) {
  // const [isActive, setIsActive] = useState(false)
  const isActive = service && service.base_info && service.base_info.is_active
  // const isDisabled = !service || service.base_info.in_package

  const { app_id: appId } = match.params
  useEffect(() => {
    dispatch(fetchService(appId))

    return () => {
      dispatch(clearServices())
      dispatch(fetchServices())
      dispatch(fetchPackages())
    }
  }, [appId, dispatch])

  // const toggleService = () => {
  //   if (isActive) {
  //     dispatch(deactivateService(appId)).then(() =>
  //       dispatch(fetchService(appId)),
  //     )
  //   } else {
  //     dispatch(activateService(appId)).then(() => dispatch(fetchService(appId)))
  //   }
  // }

  return (
    <Dialog fullScreen open={true} onClose={onClose} scroll="body">
      <ServicePageTemplate
        icon={service && service.base_info.payment}
        activeBg={
          service &&
          service.base_info.colors &&
          service.base_info.colors.active_bg
        }
        inactiveBg={
          service &&
          service.base_info.colors &&
          service.base_info.colors.inactive_bg
        }
        activeText={
          service &&
          service.base_info.colors &&
          service.base_info.colors.active_text
        }
        inactiveText={
          service &&
          service.base_info.colors &&
          service.base_info.colors.inactive_text
        }
        isActive={isActive}
        instructions={
          <>
            <Typography align="center" variant="h5">
              Инструкции
            </Typography>
            {service ? (
              service.base_info.instructions &&
              service.base_info.instructions.length > 0 ? (
                service.base_info.instructions.map(({ type, url, text }) => {
                  switch (type) {
                    case 'embed':
                      return (
                        <iframe
                          key={url}
                          width="560"
                          height="315"
                          src={url}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen="allowFullScreen"
                          title={url}
                          style={{ backgroundColor: '#fafafa' }}
                        />
                      )

                    default:
                      return null
                  }
                })
              ) : (
                <>
                  <Mascotte emote="notFound" style={{ maxHeight: 110 }} />
                  <Typography>Тут пока ничего нет.</Typography>
                </>
              )
            ) : (
              <>
                <Skeleton width={560} height={315} />
              </>
            )}
          </>
        }
      >
        <IconButton
          disableRipple
          style={{ position: 'fixed', top: '1rem', left: '1rem' }}
          color="inherit"
          component={Link}
          to="/subscriptions"
        >
          <Close />
        </IconButton>
        {service && service.base_info.next_payment_date && (
          <Tooltip title="дата следующего списания" placement="top-start">
            <NextPayment color="inherit">
              <CreditCard style={{ marginRight: 8 }} />
              {formatDate(service.base_info.next_payment_date)}
            </NextPayment>
          </Tooltip>
        )}
        {/* // <ServiceIconSrc style={iconStyle} /> */}

        <Title
          color="inherit"
          component="a"
          href={service && service.base_info.app_url}
          target="_blank"
        >
          {service ? (
            <>
              {service.base_info.payment_name}
              <Launch
                style={{
                  position: 'absolute',
                  top: 0,
                  right: '-1rem',
                  width: '1rem',
                  height: '1rem',
                }}
              />
            </>
          ) : (
            <Skeleton />
          )}
        </Title>
        <Description color="inherit">
          {service ? (
            service.base_info.description
          ) : (
            <>
              <Skeleton /> <Skeleton /> <Skeleton />
            </>
          )}
        </Description>
        {/*
        <Price color="inherit">
          {service ? `${service.base_info.cost / 100}₽` : <Skeleton />}
        </Price>

         <Button
          variant="contained"
          color="primary"
          disabled={loading || isDisabled}
          onClick={toggleService}
          style={
            isActive && !isDisabled && !loading
              ? {
                  color: 'black',
                  background: '#fff',
                  boxShadow: 'none',
                }
              : {}
          }
        >
          {isDisabled
            ? 'Уже подключено в рамках пакета'
            : isActive
            ? 'Отключить'
            : 'Подключить'}
          {loading && '...'}
        </Button> */}
      </ServicePageTemplate>
    </Dialog>
  )
}

const mapStateToProps = ({ services, billing }) => ({
  service: services.currentService,
  billingAccount: billing.account,
  loading: services.loading,
})

export default connect(mapStateToProps)(ServicePage)
