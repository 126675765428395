import React, { useEffect, useState, useCallback } from 'react'
import { Drawer, Typography } from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { FullscreenExitButton, Button } from 'sputnik-ui'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core'
import { formatDate } from 'utils/helpers'
import { Check } from '@material-ui/icons'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { fetchDeviceServicesHistory } from 'store/devicesSlice'
import { useScrollYPosition } from 'react-use-scroll-position'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'

const GreenCheck = styled(Check)`
  && {
    color: ${(p) => p.theme.palette.success['500']};
  }
`

export default withWidth()(function DeviceServicesHistory({
  open,
  onClose,
  width,
  ...props
}) {
  const dispatch = useDispatch()
  const { device } = useParams()
  const history = useSelector(({ devices }) => devices.servicesHistory)
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1)
  const incrementPage = useCallback(() => setPage(page + 1), [page])
  const isLastPage = useSelector(
    ({ devices }) => devices.historyPages >= page || devices.historyPages === 0,
  )

  useEffect(() => {
    setLoading(true)
    dispatch(fetchDeviceServicesHistory({ uuid: device, page }))
      .then(unwrapResult)
      .then(() => setLoading(false))
  }, [device, page])

  const { t } = useTranslation('service')

  return (
    <Drawer
      onOpen={() => {}}
      anchor="right"
      open={open}
      onClose={onClose}
      elevation={10}
      PaperProps={{
        style: {
          width: isWidthUp('sm', width) ? '70%' : '100%',
          overflowX: 'hidden',
          marginLeft: 'auto',
          position: 'relative',
          padding: '2rem',
        },
      }}
      ModalProps={{
        BackdropProps: { style: { backgroundColor: 'rgba(0,0,0,0.1)' } },
      }}
    >
      <FullscreenExitButton
        variant="back"
        onClick={onClose}
        style={{ position: 'relative', top: 0, left: 0 }}
      />
      <Typography variant="h4" style={{ marginTop: '1rem' }}>
        История подключения услуг
      </Typography>
      <List style={{ padding: 0 }}>
        {history?.map(({ title, committed_at }) => (
          <ListItem>
            <ListItemIcon>
              <GreenCheck />
            </ListItemIcon>
            <ListItemText
              primary={title}
              secondary={formatDate(committed_at)}
            ></ListItemText>
          </ListItem>
        ))}
        {!isLastPage && !loading && (
          <Button primary onClick={incrementPage}>
            Загрузить еще
          </Button>
        )}
        {loading && <CircularProgress />}

        {isLastPage && (
          <Typography
            style={{ marginTop: '1rem' }}
            textAlign="center"
            variant="h5"
            color="textSecondary"
          >
            {t('service:history_end')}
          </Typography>
        )}
      </List>
    </Drawer>
  )
})
