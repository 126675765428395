import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import api from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'
import { showError, showMessage } from 'features/ui/uiSlice'

export const fetchCustomizations = createAsyncThunk(
  'customizations/fetchCustomizations',
  async (args, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(
        `/customizations`,
        undefined,
        `${config.redirectUri}/api/v2`
      )
      return payload
    } catch (error) {
      return rejectWithValue(await error)
    }
  }
)

export const connectToCustomization = createAsyncThunk(
  'customizations/connectCustomizations',
  async (
    { selected, selectedEntries },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const payload = await api.request(
        `/customizations/${selected}`,
        {
          method: 'PATCH',
          body: JSON.stringify({ entries_uuids: selectedEntries }),
        },
        `${config.redirectUri}/api/v2`
      )
      return Promise.resolve(payload)
    } catch (error) {
      const { message } = await error.json()
      dispatch(showError(message))
      return rejectWithValue()
    }
  }
)

export const createCustomization = createAsyncThunk(
  'customizations/create_template',
  async (args, { getState, rejectWithValue, dispatch }) => {
    try {
      const payload = await api.request(
        '/customizations/create_template',
        {
          method: 'POST',
          body: JSON.stringify(args),
        },
        `${config.redirectUri}/api/v2`
      )
      return payload
    } catch (error) {
      const { message } = await error.json()
      dispatch(showError(message))
      return rejectWithValue(error)
    }
  }
)

export const editCustomization = createAsyncThunk(
  'customizations/edit_template',
  async (args, { getState, rejectWithValue, dispatch }) => {
    const { title, credits, products, id } = args

    try {
      const creditsArr = credits.map(async (el) => {
        // Local credits id created by Math.random()
        if (Number(el.id) > 1) {
          return await api
            .request(
              `/customizations/credits/${el.id}`,
              {
                method: 'PATCH',
                body: JSON.stringify(el),
              },
              `${config.redirectUri}/api/v2`
            )
            .then((res) => res.id)
        } else if (Number(el.id) < 1) {
          return await api
            .request(
              `/customizations/credits`,
              {
                method: 'POST',
                body: JSON.stringify(el),
              },
              `${config.redirectUri}/api/v2`
            )
            .then((res) => res.id)
        }
        return
      })

      const productsArr = products.map(async (el) => {
        // Local credits id created by Math.random()
        if (Number(el.id) > 1) {
          return await api
            .request(
              `/customizations/products/${el.id}`,
              {
                method: 'PATCH',
                body: JSON.stringify(el),
              },
              `${config.redirectUri}/api/v2`
            )
            .then((res) => res.id)
        } else if (Number(el.id) < 1) {
          return await api
            .request(
              `/customizations/products`,
              {
                method: 'POST',
                body: JSON.stringify(el),
              },
              `${config.redirectUri}/api/v2`
            )
            .then((res) => res.id)
        }
        return
      })

      const creditsPromise = await Promise.all(creditsArr)
      const productsPromise = await Promise.all(productsArr)

      await Promise.all([creditsPromise, productsPromise]).then(
        ([creditsPromise, productsPromise]) => {
          const data = api.request(
            `/customizations/${id}`,
            {
              method: 'PATCH',
              body: JSON.stringify({
                title: title,
                credit_ids: creditsPromise,
                product_id: productsPromise, // must be only one item
              }),
            },
            `${config.redirectUri}/api/v2`
          )
          return data
        },
        (err) => console.log(err)
      )
    } catch (error) {
      const { message } = await error.json()
      dispatch(showError(message))
      return rejectWithValue()
    }
  }
)

export const fetchCustomizationAddresses = createAsyncThunk(
  'customizations/fetchCustomizationsAddresses',
  async (
    { page = 0, limit = 10, with_customization = null, search = '' },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const setParams = () => {
        let params = []
        if (Number.isInteger(page)) params.push(`page=${Number(page) + 1}`)
        if (limit) params.push(`limit=${limit}`)
        if (search) params.push(`search=${search}`)
        if (with_customization === false || with_customization === true)
          params.push(`with_customization=${with_customization}`)
        return params.join('&')
      }
      const payload = await api.request(
        buildUrl(`/customizations/addresses?${setParams()}`),
        undefined,
        `${config.redirectUri}/api/v2`
      )
      return payload
    } catch (error) {
      const { message } = await error.json()
      dispatch(showError(message))
      return rejectWithValue()
    }
  }
)

const customizationsSlice = createSlice({
  name: 'customization',
  initialState: {
    editMode: false,
    isFetching: false,
    selectedEntries: [],
    pending: false,
    addresses: null,
    list: [],
    current: null,
    error: null,
  },
  reducers: {
    editSelectedCredit: (state, { payload }) => {
      const { id } = payload
      state.current = state.list.filter((el) => el.id === id)[0]
    },
    editCurrentCredit: (state, { payload }) => {
      const { id, name, value } = payload
      state.current.credits = state.current.credits.map((el) => {
        if (el.id === id) el[name] = value
        return el
      })
    },
    editCurrentProduct: (state, { payload }) => {
      const { id, name, value } = payload
      state.current.products = state.current.products.map((el) => {
        if (el.id === id) el[name] = value
        return el
      })
    },
    editCurrentTitle: (state, { payload }) => {
      state.current.title = payload.value
    },
    setErrCurrentCredit: (state, { payload }) => {
      const { id, err } = payload
      state.current.credits = state.current.credits.map((el) => {
        if (el.id === id) {
          Object.keys(err).length ? (el.err = err) : (el.err = null)
        }
        return el
      })
    },
    addCreditToCurrent: (state) => {
      const tinkoff = Math.random()
      state.current.credits = [
        ...state.current.credits,
        {
          id: tinkoff,
          err: { init: 'введите название' },
        },
      ]
    },
    addProductToCurrent: (state) => {
      const tinkoff = Math.random()
      state.current.products = [...state.current.products, { id: tinkoff }]
    },
    removeCurrentCredit: (state, { payload }) => {
      state.current.credits = [
        ...state.current.credits.filter((el) => el.id !== payload),
      ]
    },
    removeCurrentProduct: (state, { payload }) => {
      state.current.products = [
        ...state.current.products.filter((el) => el.id !== payload),
      ]
    },
    initCurrent: (state, { payload }) => {
      state.current = { ...payload }
    },
    clearCurrent: (state) => {
      state.current = null
    },
    customizationEditMode: (state, { payload }) => {
      state.editMode = payload.editMode
    },
    setCustomizationFetching: (state, { payload }) => {
      state.isFetching = payload.isFetching
    },
    editSelectedEntries: (state, { payload }) => {
      state.selectedEntries = payload.selectedEntries
    },
    clearSelectedEntries: (state) => {
      state.selectedEntries = []
    },
  },
  extraReducers: {
    [fetchCustomizations.pending]: (state) => {
      state.pending = true
    },
    [fetchCustomizations.fulfilled]: (state, { payload }) => {
      state.pending = false
      state.list = payload
    },
    [fetchCustomizations.rejected]: (state) => {
      state.pending = false
    },

    [editCustomization.pending]: (state) => {
      state.pending = true
    },
    [editCustomization.fulfilled]: (state, { payload }) => {
      state.pending = false
      state.list = payload
    },
    [editCustomization.rejected]: (state) => {
      state.pending = false
    },

    [fetchCustomizationAddresses.pending]: (state) => {
      state.pending = true
    },
    [fetchCustomizationAddresses.fulfilled]: (state, { payload }) => {
      state.isFetching = false
      state.pending = false
      state.addresses = payload
    },
    [fetchCustomizationAddresses.rejected]: (state) => {
      state.pending = false
      state.isFetching = false
    },
  },
})
export const {
  addCreditToCurrent,
  removeCurrentCredit,
  editCurrentCredit,
  editCurrentProduct,
  setErrCurrentCredit,
  editSelectedCredit,
  clearCurrent,
  initCurrent,
  setCustomizationFetching,
  addProductToCurrent,
  removeCurrentProduct,
  editCurrentTitle,
  customizationEditMode,
  editSelectedEntries,
  clearSelectedEntries,
} = customizationsSlice.actions

export default customizationsSlice.reducer
