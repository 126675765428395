//@flow

export const FETCH_STATISTICS_REQUEST = 'billing/FETCH_STATISTICS_REQUEST'
export const FETCH_STATISTICS_SUCCESS = 'billing/FETCH_STATISTICS_SUCCESS'
export const FETCH_STATISTICS_FAILURE = 'billing/FETCH_STATISTICS_FAILURE'

export const FETCH_BILLING_ACCOUNT_REQUEST =
  'billing/FETCH_BILLING_ACCOUNT_REQUEST'
export const FETCH_BILLING_ACCOUNT_SUCCESS =
  'billing/FETCH_BILLING_ACCOUNT_SUCCESS'
export const FETCH_BILLING_ACCOUNT_FAILURE =
  'billing/FETCH_BILLING_ACCOUNT_FAILURE'

export const FETCH_AVAILABLE_PERIOD_REQUEST =
  'billing/FETCH_AVAILABLE_PERIOD_REQUEST'
export const FETCH_AVAILABLE_PERIOD_SUCCESS =
  'billing/FETCH_AVAILABLE_PERIOD_SUCCESS'
export const FETCH_AVAILABLE_PERIOD_FAILURE =
  'billing/FETCH_AVAILABLE_PERIOD_FAILURE'

export const FETCH_TRANSACTIONS_REQUEST = 'billing/FETCH_TRANSACTIONS_REQUEST'
export const FETCH_TRANSACTIONS_SUCCESS = 'billing/FETCH_TRANSACTIONS_SUCCESS'
export const FETCH_TRANSACTIONS_FAILURE = 'billing/FETCH_TRANSACTIONS_FAILURE'

export const MAKE_DEPOSIT_REQUEST = 'billing/MAKE_DEPOSIT_REQUEST'
export const MAKE_DEPOSIT_SUCCESS = 'billing/MAKE_DEPOSIT_SUCCESS'
export const MAKE_DEPOSIT_FAILURE = 'billing/MAKE_DEPOSIT_FAILURE'

export const CHANGE_BILLING_ACCOUNT_INFO_REQUEST =
  'billing/CHANGE_BILLING_ACCOUNT_INFO_REQUEST'
export const CHANGE_BILLING_ACCOUNT_INFO_SUCCESS =
  'billing/CHANGE_BILLING_ACCOUNT_INFO_SUCCESS'
export const CHANGE_BILLING_ACCOUNT_INFO_FAILURE =
  'billing/CHANGE_BILLING_ACCOUNT_INFO_FAILURE'

export const UNBIND_PAYMENT_METHOD_REQUEST =
  'billing/UNBIND_PAYMENT_METHOD_REQUEST'
export const UNBIND_PAYMENT_METHOD_SUCCESS =
  'billing/UNBIND_PAYMENT_METHOD_SUCCESS'
export const UNBIND_PAYMENT_METHOD_FAILURE =
  'billing/UNBIND_PAYMENT_METHOD_FAILURE'

export const CLEAR_TRANSACTIONS_DATA = 'billing/CLEAR_TRANSACTIONS_DATA'
