//@flow
export type IService = {
  base_info: {
    is_active: boolean,
    in_package: boolean,
    payment: string,
    payment_name: string,
    image: string,
    type: 'global' | 'per_device',
    rate_type: 'monthly' | 'daily',
    cost: number,
    description: string,
    service_address: string,
    colors: {
      activated: string,
      deactivated: string,
      text: string,
    },
  },
}

export const rateTypes = {
  monthly: 'ежемесячно',
  daily: 'ежедневно',
}

export type Action = {
  +type: string,
  +payload: Object,
  +meta?: Object,
}

export type BillingAccount = {
  actual_address: ?string,
  balance: ?string,
  balance_with_currency: ?string,
  inn: ?string,
  bic: ?string,
  company_name: ?string,
  email: ?string,
  id: ?number,
  legal_address: ?string,
  phone_number: ?string,
  postal_code: ?string,
  settlement_account: ?string,
  kpp: ?string,
  balance_last_update: Date,
  is_filled: boolean,
  card_info: ?Object,
  service_code: ?string,
}

export type Company = {
  id: number,
  name: string,
  tin: string,
  uuid: string,
  inn: string,
  billing_account: BillingAccount,
}

export type ITransaction = {
  type: 'order' | 'invoice',
  description?: string,
  payment_method?: 'card' | 'check',
  operation_id: number,
  status: 'pending' | 'success' | 'error',
  date: string,
  amount: string,
  balance: number,
}

export type Stat = {
  name: string,
  total_spent: number,
  total_deposit: number,
}

export const paymentMethods = {
  card: 'card',
  check: 'direct_deposit',
}
