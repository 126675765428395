import { useRef, useState } from 'react'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import ButtonBase from '@material-ui/core/ButtonBase'
import { DescriptionOutlined } from '@material-ui/icons'

const COLOR = 'rgba(0, 0, 0, 0.54)' //#333
const COLOR_ERR = '#e91e63'

const FileInput = ({
  label,
  onChange,
  name,
  error,
  helperText,
  linkValue,
  required,
}) => {
  const ref = useRef()
  const theme = useTheme()
  const classes = useStyles()
  const [attachment, setAttachment] = useState(null)

  const handleChange = (event) => {
    const files = Array.from(event.target.files)
    const [file] = files
    setAttachment(file)
    if (!!onChange) onChange({ target: { value: file, name: name } })
  }

  return (
    <Box   
      position="relative"
      style={{
        height: '100%',
        width: '100%',
        color: error ? COLOR_ERR : COLOR,
        minHeight: 30,
      }}
      borderBottom={1}
    >
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        mt={-4}
        mb={4}
      >
        <TextField
          //   variant="standard"
          className={classes.field}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: error ? COLOR_ERR : COLOR,
          }}
          InputProps={{ disableUnderline: true, className: classes.input }}
          margin="normal"
          fullWidth
          disabled
          label={label}
          value={attachment?.name || linkValue}
          required={required || false}
          error={!!error}
          helperText={error && helperText}
        />
      </Box>
      <ButtonBase
        className={classes.button}
        style={{
          // height: '30px',
          width: '100%',
        }}
        component="label"
        onKeyDown={(e) => e.keyCode === 32 && ref.current?.click()}
      >
        <input
          ref={ref}
          type="file"
          accept="image/*"
          hidden
          onChange={handleChange}
        />
        <DescriptionOutlined
          style={{
            marginLeft: 'auto',
            background:'#fff',
            marginBottom: '5px',
          }}
        />
      </ButtonBase> 
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-error': {
      color: COLOR_ERR,
    },
    '& .MuiFormHelperText-root': {
      color: COLOR,
    },
  },
  field: {
    '& .Mui-error': {
      color: COLOR_ERR,
    },
  },
  button: {
    width: '100%',
    height: '100%',
    color: '#6E6D7A',
    overflow: 'hidden',
    border: 'none',
 
  },
}))

export default FileInput
