import React from 'react'
import PropTypes from 'prop-types'
import columnData from './columnData'

import {
  TableSortLabel,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
  withStyles,
  Checkbox,
} from '@material-ui/core'

import { RoomService, Power, GpsNotFixed } from '@material-ui/icons'

import { ReactComponent as Sputnik } from './Sputnik.svg'
import { Build } from '@material-ui/icons'

const styles = theme => ({
  cell: {
    // backgroundColor: theme.palette.primary['main'],
  },
  label: {
    // color: 'white',
    // '&:focus': {
    //   color: 'white',
    // },
    // '&:hover': {
    //   color: 'white',
    // },
    '& svg': {
      fill: 'currentColor',
      marginRight: '.625rem',
    },
  },
})

class DevicesTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.handleSortRequest(event, property)
  }

  render() {
    const {
      orderBy,
      sortBy,
      classes,
      selected,
      devices,
      handleSelectAll,
    } = this.props

    const rowCount = (devices && devices.length) || 0

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" className={classes.cell}>
            <Checkbox
              indeterminate={selected.length > 0 && selected.length < rowCount}
              checked={selected.length === rowCount && rowCount > 0}
              onChange={handleSelectAll}
              color="primary"
            />
          </TableCell>
          {columnData.map(column => {
            const active = sortBy === column.id
            return (
              <TableCell
                key={column.id}
                numeric={column.numeric}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={sortBy === column.id ? orderBy : false}
                className={classes.cell}
                id={`table-head-${column.id}`}
              >
                <Tooltip
                  title="Сортировать"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={active}
                    direction={orderBy}
                    onClick={this.createSortHandler(column.id)}
                    className={classes.label}
                  >
                    {column.id === 'serial_number' && <Sputnik />}
                    {column.id === 'service_partner_name' && <Build />}
                    {column.id === 'services_count' && <RoomService />}
                    {column.id === 'is_online' && <Power />}
                    {column.id === 'installed' && <GpsNotFixed />}

                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }
}

DevicesTableHead.propTypes = {
  handleSortRequest: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

export default withStyles(styles)(DevicesTableHead)
