import React from 'react'
import styled, { css } from 'styled-components'
import { darkerColor, lighterColor } from 'utils/helpers'
import Skeleton from 'react-loading-skeleton'

const MARGIN = 4

const Wrapper = styled.div`
  display: flex;
  width: calc(100vw - (100vw - 100%));
  min-height: 100%;
  height:100%;
  overflow: hidden;
  /* padding-top: ${MARGIN}rem; */

  @media screen and (max-width: ${p => p.theme.sizes.screen}) {
    flex-direction: column;
    overflow:auto;
  }
`

const defaultIconStyle = css`
  /* stroke: currentColor;
  fill: currentColor; */
  width: 95px;
  margin-top: 25%;
  margin-bottom: 1rem;
  @media screen and (max-width: ${p => p.theme.sizes.screen}) {
    margin-top: 4rem;
  }
`

const Icon = styled.svg`
  ${defaultIconStyle}
`

const Img = styled.img`
  ${defaultIconStyle}
`

const IconPlaceholder = styled.div`
  ${defaultIconStyle};
  justify-content: center;
  display: flex;
`

const Description = styled.div`
  flex: 4;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 100%;
  display: flex;
  background: #fafafa;
  /* background: ${p =>
    p.isActive
      ? `repeating-radial-gradient(ellipse farthest-corner at left top,${
          p.activeBg
        } 0%,${lighterColor(p.activeBg, 0.2)} 100%);`
      : `repeating-radial-gradient(ellipse farthest-corner at left top,${darkerColor(
          p.inactiveBg,
          0.05,
        )} 0%,${p.inactiveBg} 100%);`}; */
          background: ${p =>
            p.isActive ? p.theme.palette.secondary[100] : p.inactiveBg};
  color: ${p => (p.isActive ? p.activeText : p.inactiveText)};
  padding: 0 ${MARGIN / 2}rem ${MARGIN / 2}rem;
  transition: background-color 1s ease-in-out;
  position: relative;

  @media screen and (max-width: ${p => p.theme.sizes.screen}) {
    width: 100%;
    flex: unset;
  }
`
const Instructions = styled.div`
  /* width: 60%; */
  flex: 6;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  /* padding-bottom: 0; */
  /* min-height: 100%; */
  display: flex;
  overflow: auto;

  & > * {
    margin-bottom: 2rem !important;
  }

  @media screen and (max-width: ${p => p.theme.sizes.screen}) {
    width: 100%;
    overflow: visible;
    flex: unset;
  }
`

const ServicePageTemplate = ({
  activeBg = '#e899b4',
  inactiveBg = '#eed7df',
  activeText = '#333',
  inactiveText = '#333',
  isActive,
  children,
  instructions,
  icon,
  iconStyle,
  ...props
}) => (
  <Wrapper {...props}>
    <Description
      activeBg={activeBg}
      inactiveBg={inactiveBg}
      activeText={activeText}
      inactiveText={inactiveText}
      isActive={isActive}
    >
      {icon ? (
        iconStyle ? (
          <Img src={icon} style={iconStyle} />
        ) : (
          <Icon viewBox="0 0 64 64">
            <use
              xlinkHref={`${window.location.origin}/images/${icon}.svg#Artboard`}
            />
          </Icon>
        )
      ) : (
        <IconPlaceholder style={iconStyle}>
          <Skeleton circle width={95} height={95} />
        </IconPlaceholder>
      )}
      {children}
    </Description>
    <Instructions>{instructions}</Instructions>
  </Wrapper>
)

export default ServicePageTemplate
