import { showError, showMessage } from 'features/ui/uiSlice'
import api from 'utils/api'
const { createAsyncThunk } = require('@reduxjs/toolkit')

export const fetchAppAbilities = createAsyncThunk(
  'permissions/fetch_abilities',
  async (params = {}, { getState, rejectWithValue, dispatch }) => {
    try {
      const apps = await api.request(`/apps_resources`)
      dispatch(showMessage(apps?.message))
      return apps
    } catch (err) {
      const { error } = await err.json()
      dispatch(showError(error))
      return rejectWithValue(err)
    }
  }
)

export const createManagementCompany = createAsyncThunk(
  'permissions/create_company',
  async ({ permissions, email, address_uuids, name }, { getState, rejectWithValue, dispatch }) => {
    const params = { permissions, email, address_uuids, name }
    try {
      const apps = await api.request(`/managment_companies`, {
        method: 'POST',
        body: JSON.stringify(params),
      })
      dispatch(showMessage(apps?.message))

      return apps
    } catch (err) {
      const { error } = await err.json()
      dispatch(showError(error))
      return rejectWithValue(err)
    }
  }
)

export const fetchManagementsCompanies = createAsyncThunk(
  'permissions/fetch_companies',
  async (args, { getState, rejectWithValue, dispatch }) => {
    try {
      const companies = await api.request(`/managment_companies`)
      dispatch(showMessage(companies?.message))
      return companies
    } catch (err) {
      const { error } = await err.json()
      dispatch(showError(error))
      return rejectWithValue(err)
    }
  }
)

export const fetchManagementCompany = createAsyncThunk(
  'permissions/fetch_current_company',
  async ({ uuid }, { getState, rejectWithValue, dispatch }) => {
    // const params = { permissions, email, address_uuids, name }
    try {
      const apps = await api.request(`/managment_companies/${uuid}`)
      return apps
    } catch (err) {
      const { error } = await err.json()
      dispatch(showError(error))
      return rejectWithValue(err)
    }
  }
)

export const updateAppAbilities = createAsyncThunk(
  'permissions/update_app_abilities ',
  async ({ uuid }, { getState, rejectWithValue, dispatch }) => {
    try {
      const permissions = getState().permissions.permissions

      const res = await api.request(`/managment_companies/${uuid}/update_permissions`, {
        method: 'PATCH',
        body: JSON.stringify({ permissions: permissions }),
      })

      dispatch(showMessage(res?.message))
      return Promise.resolve(res)
    } catch (err) {
      const { error } = await err.json()
      dispatch(showError(error))
      return rejectWithValue(err)
    }
  }
)
export const deleteManagementCompany = createAsyncThunk(
  'permissions/deleteManagementCompany',
  async ({ uuid }, { getState, rejectWithValue, dispatch }) => {
    const params = { mc_uuids: [uuid] }
    try {
      const res = await api.request(`/managment_companies/delete_batch`, {
        method: 'DELETE',
        body: JSON.stringify(params),
      })

      dispatch(showMessage(res?.message))
      return res
    } catch (err) {
      const { error } = await err.json()
      dispatch(showError(error))
      return rejectWithValue(err)
    }
  }
)
