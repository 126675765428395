//@flow
import * as React from 'react'
import { TransactionStatus } from 'components'
import { TableCell, IconButton, Tooltip } from '@material-ui/core'
import {
  Payment,
  Assignment,
  RoomService,
  Done,
  CardGiftcard,
} from '@material-ui/icons'
import type { ITransaction } from 'globalTypes'
import { ReactComponent as Sputnik } from './Sputnik.svg'
import { ReactComponent as ISee } from './ISee_alt.svg'

const dateFormatOptions = {
  // year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const styles = {
  cell: {
    borderBottom: 'none',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '.625rem',
    paddingRight: '0.625rem',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
    fill: 'currentColor',
    width: '1em',
    height: '1em',
    display: 'inline-block',
    fontSize: 24,
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    userSelect: 'none',
    flexShrink: 0,
  },
}

const orderIcons = {
  order_intercom: <Sputnik style={styles.icon} />,
  order_camera: <ISee style={styles.icon} />,
  order_service: <RoomService style={styles.icon} />,
  order: <RoomService style={styles.icon} />,
}

const Transaction = ({
  transaction,
  onApprove,
}: {
  transaction: ITransaction,
  onApprove?: Function,
}) => {
  const isOrder = /^order.*/g.test(transaction.type)
  return (
    <>
      <TableCell style={styles.cell}>
        <div style={styles.center}>
          {isOrder ? (
            <>
              {orderIcons[transaction.type]}
              {transaction.description}
            </>
          ) : transaction.payment_method === 'card' ? (
            <>
              <Payment style={styles.icon} />
              Оплата банковской картой
            </>
          ) : (
            <>
              {transaction.description ? (
                <CardGiftcard style={styles.icon} />
              ) : (
                <Assignment style={styles.icon} />
              )}
              {transaction.description || 'Безналичный расчет'}
            </>
          )}
        </div>
      </TableCell>
      <TableCell style={styles.cell}>
        <TransactionStatus status={transaction.status} />
      </TableCell>
      <TableCell style={styles.cell}>#{transaction.operation_id}</TableCell>
      <TableCell style={styles.cell}>
        {new Date(transaction.date).toLocaleDateString(
          undefined,
          dateFormatOptions,
        )}
      </TableCell>
      <TableCell
        style={{ ...styles.cell, fontWeight: 'bold', textAlign: 'right' }}
      >
        {transaction.amount}
      </TableCell>
      {onApprove && (
        <TableCell style={{ ...styles.cell, fontWeight: 'bold' }}>
          <Tooltip title="Одобрить транзакцию" placement="left">
            <IconButton onClick={onApprove(transaction.operation_id)}>
              <Done style={{ color: 'green' }} />
            </IconButton>
          </Tooltip>
        </TableCell>
      )}
    </>
  )
}

export default Transaction
