import React, { useState } from 'react'
import styled from 'styled-components'
import { Wrapper, Button } from 'sputnik-ui'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'

import { ReactComponent as Visa } from './visa.svg'
import { ReactComponent as MasterCard } from './mastercard.svg'
import { Payment } from '@material-ui/icons'

import {
  unbindPaymentMethod,
  fetchBillingAccount,
} from 'store/billing/operations'

const icons = {
  Visa: Visa,
  MasterCard: MasterCard,
  card: Payment,
}

const Div = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  border-radius: inherit;
`

const CardContent = styled.div`
  display: flex;
  width: 100%;
  filter: ${p => (p.isBlurred ? 'blur(2px)' : 'none')};
`

//add blur
const CreditCard = ({
  card_type = 'card',
  expiry_month,
  expiry_year,
  last4,
  first6,
  dispatch,
}) => {
  const Icon = icons[card_type]
  const digits = first6 + 'XXXXXX' + last4
  const formattedDigits = digits.match(/.{1,4}/g).join(' ')
  const [isHovered, setIsHovered] = useState(false)
  return (
    <Wrapper
      style={{
        padding: '0.625rem',
        alignItems: 'center',
        boxShadow: 'none',
        position: 'relative',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardContent isBlurred={isHovered}>
        <Icon style={{ width: 24, height: 24, marginRight: '0.625rem' }} />
        <Typography>{formattedDigits}</Typography>
        <Typography style={{ marginLeft: 'auto' }} color="textSecondary">
          {expiry_month} / {expiry_year}
        </Typography>
      </CardContent>
      {isHovered && (
        <Div>
          <Button
            size="small"
            color="danger"
            rounded
            onClick={() =>
              dispatch(unbindPaymentMethod()).then(() =>
                dispatch(fetchBillingAccount()),
              )
            }
          >
            Отвязать
          </Button>
        </Div>
      )}
    </Wrapper>
  )
}

export default connect()(CreditCard)
