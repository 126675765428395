import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { editSelectedEntries } from './customizationSlice'
import { Box, Typography } from '@material-ui/core'
import { showError } from 'features/ui/uiSlice'
import styled from 'styled-components'
import { Checkbox } from 'sputnik-ui'

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  justify-items: start;
  grid-template-columns: repeat(2, 1fr);
  padding: 15px;
  border-bottom: 1px solid #ededed;
`

function CustomizationAddressItem({ children, uuid, connected }) {
  const dispatch = useDispatch()
  const selectedEntries = useSelector(
    ({ customization }) => customization.selectedEntries
  )
  const [checked, setCheсked] = useState(false)

  useEffect(() => {
    if (uuid && selectedEntries.includes(uuid)) {
      setCheсked(true)
    } else {
      setCheсked(false)
    }
  }, [uuid, selectedEntries])

  const handleChange = () => {
    const val = selectedEntries.filter((el) => el === uuid)
    if (val.length === 1) {
      setCheсked(false)
      const filtered = selectedEntries.filter((el) => el !== uuid)
      dispatch(editSelectedEntries({ selectedEntries: filtered }))
    } else if (val.length === 0) {
      setCheсked(true)
      dispatch(
        editSelectedEntries({ selectedEntries: [...selectedEntries, uuid] })
      )
    } else {
      dispatch(showError('Missing sync'))
    }
  }

  return (
    <Wrapper>
      <Box display="flex" alignItems="center">
        <Checkbox checked={checked} onChange={handleChange} color="blue" />
        <Typography>{children}</Typography>
      </Box>

      <div style={{ margin: '0 auto' }}>
        <Typography>{connected || 'Шаблон не прикреплен'}</Typography>
      </div>
    </Wrapper>
  )
}

export default CustomizationAddressItem
