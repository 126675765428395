export const FETCH_SERVICES_REQUEST = 'services/FETCH_SERVICES_REQUEST'
export const FETCH_SERVICES_SUCCESS = 'services/FETCH_SERVICES_SUCCESS'
export const FETCH_SERVICES_FAILURE = 'services/FETCH_SERVICES_FAILURE'

export const FETCH_PACKAGES_REQUEST = 'services/FETCH_PACKAGES_REQUEST'
export const FETCH_PACKAGES_SUCCESS = 'services/FETCH_PACKAGES_SUCCESS'
export const FETCH_PACKAGES_FAILURE = 'services/FETCH_PACKAGES_FAILURE'

export const FETCH_SERVICE_REQUEST = 'services/FETCH_SERVICE_REQUEST'
export const FETCH_SERVICE_SUCCESS = 'services/FETCH_SERVICE_SUCCESS'
export const FETCH_SERVICE_FAILURE = 'services/FETCH_SERVICE_FAILURE'

export const FETCH_PACKAGE_REQUEST = 'services/FETCH_PACKAGE_REQUEST'
export const FETCH_PACKAGE_SUCCESS = 'services/FETCH_PACKAGE_SUCCESS'
export const FETCH_PACKAGE_FAILURE = 'services/FETCH_PACKAGE_FAILURE'

export const ACTIVATE_SERVICE_REQUEST = 'services/ACTIVATE_SERVICE_REQUEST'
export const ACTIVATE_SERVICE_SUCCESS = 'services/ACTIVATE_SERVICE_SUCCESS'
export const ACTIVATE_SERVICE_FAILURE = 'services/ACTIVATE_SERVICE_FAILURE'

export const DEACTIVATE_SERVICE_REQUEST = 'services/DEACTIVATE_SERVICE_REQUEST'
export const DEACTIVATE_SERVICE_SUCCESS = 'services/DEACTIVATE_SERVICE_SUCCESS'
export const DEACTIVATE_SERVICE_FAILURE = 'services/DEACTIVATE_SERVICE_FAILURE'

export const ACTIVATE_PACKAGE_REQUEST = 'services/ACTIVATE_PACKAGE_REQUEST'
export const ACTIVATE_PACKAGE_SUCCESS = 'services/ACTIVATE_PACKAGE_SUCCESS'
export const ACTIVATE_PACKAGE_FAILURE = 'services/ACTIVATE_PACKAGE_FAILURE'

export const DEACTIVATE_PACKAGE_REQUEST = 'services/DEACTIVATE_PACKAGE_REQUEST'
export const DEACTIVATE_PACKAGE_SUCCESS = 'services/DEACTIVATE_PACKAGE_SUCCESS'
export const DEACTIVATE_PACKAGE_FAILURE = 'services/DEACTIVATE_PACKAGE_FAILURE'

export const CLEAR_SERVICES = 'services/CLEAR_SERVICES'
