import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Zoom,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  RadioGroup,
  Box,
} from '@material-ui/core/'
import { Button, Radio } from 'sputnik-ui'
import styled from 'styled-components'
import ViewItem from './ViewItem'
import { ExpandMore } from '@material-ui/icons'
import {
  clearSelectedEntries,
  connectToCustomization,
  fetchCustomizationAddresses,
  fetchCustomizations,
} from './customizationSlice'
import { useTranslation } from 'react-i18next'

const StyledDialogContent = styled(DialogContent)`
  height: 60vh;
  width: 100%;
  border-radius: 60px;
  @media screen and (max-width: ${(p) => p.theme.sizes.phone}) {
    height: 70vh;
  }
`
const StyledAccordion = styled(Accordion)`
  &&& {
    box-shadow: none;
    border: none;
  }
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
function Transition(props) {
  return <Zoom direction="up" {...props} />
}

function CustomizationModal({
  isOpen,
  handleClose,
  addressParams,
  setAddressParams,
}) {
  const { t } = useTranslation(['common', 'customization'])
  const dispatch = useDispatch()
  const list = useSelector(({ customization }) => customization.list)
  const selectedEntries = useSelector(
    ({ customization }) => customization.selectedEntries
  )

  const [selected, setSelected] = useState(null) //id
  const [expanded, setExpanded] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleSelect = (id) => {
    setSelected(id)
  }

  const handleSubmit = useCallback(() => {
    setLoading(true)
    const { entries_uuids } = list.find((el) => el?.id === selected) //must be array
    dispatch(
      connectToCustomization({
        selected,
        selectedEntries: [...entries_uuids, ...selectedEntries],
      })
    )
      .then(() => dispatch(fetchCustomizationAddresses({ ...addressParams })))
      .then(() => dispatch(fetchCustomizations()))
      .then(() => dispatch(clearSelectedEntries()))
      .then(() => setLoading(false))
      .then(() => handleClose())
  }, [dispatch, handleClose, selected, selectedEntries, list, addressParams])

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth={'md'}
      onClose={() => !loading && handleClose()}
      TransitionComponent={Transition}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <StyledDialogContent>
        <RadioGroup>
          {list &&
            list.map((el) => {
              const index = el.id
              return (
                <StyledAccordion
                  elevation={0} //box shadow
                  key={index}
                  expanded={expanded === index}
                  onChange={handleChange(index)}
                  onClick={() => handleSelect(index)}
                >
                  <AccordionSummary
                    style={{
                      flexGrow: 0, //
                      padding: 0,
                    }}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ExpandMore />}
                  >
                    <Box display="flex" alignItems="center">
                      <Radio color="blue" checked={selected === el.id} />
                      <Typography variant="h6">{el.title}</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Card>
                      {el?.credits.map((credit, creditIndex) => {
                        const creditID = credit.id
                        return (
                          <ViewItem
                            key={creditID}
                            credit={credit}
                            index={creditIndex}
                          />
                        )
                      })}
                      {el?.products.map((product, productIndex) => {
                        const productID = product.id
                        return (
                          <ViewItem
                            key={productID}
                            credit={product}
                            index={productIndex}
                            service
                          />
                        )
                      })}
                    </Card>
                  </AccordionDetails>
                </StyledAccordion>
              )
            })}
        </RadioGroup>
      </StyledDialogContent>
      <DialogActions>
        <div
          style={{
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            padding: '15px 0 30px 0',
            width: 250,
          }}
        >
          <Button
            style={{ textTransform: 'capitalize' }}
            subtle
            onClick={() => !loading && handleClose()}
          >
            {t('common:button.cancel')}
          </Button>
          <Button onClick={handleSubmit} primary variant="v2">
            {loading
              ? t('common:loading') + ' ...'
              : t('customization:button.apply_template')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default CustomizationModal
