import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { ServicePageTemplate, GlobalService } from 'components'
import { Button } from 'sputnik-ui'
import { Dialog, IconButton, Typography } from '@material-ui/core'
import { Close, Check } from '@material-ui/icons'
import Skeleton from 'react-loading-skeleton'
import {
  fetchPackage,
  fetchServices,
  fetchPackages,
  deactivatePackage,
  activatePackage,
} from 'store/services/operations'
import { clearServices } from 'store/services/actions'
import type { IService, BillingAccount } from 'globalTypes'
import type { Match } from 'react-router-dom'

const MARGIN = 2

const Title = styled(Typography)`
  && {
    font-weight: 300;
    font-size: 2.125rem;
    /* line-height: 2.625rem; */
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    min-width: 80px;
    /* height: 50px; */
  }
`

const Description = styled(Typography)`
  && {
    min-width: 160px;
    margin-top: 4rem;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    font-size: 1.325rem;
    margin-top: ${MARGIN}rem;
  }
`

const Price = styled(Typography)`
  && {
    font-size: 1.625rem;
    min-width: 80px;
    text-align: center;
    /* font-weight: 400; */
    margin-top: ${MARGIN}rem;
    margin-bottom: ${MARGIN / 4}rem;
    /* margin-top: 50px; */
  }
`

const ServicesWrapper = styled.div`
    /* padding: ${MARGIN}rem; */
  /* padding-top: ${MARGIN / 2}rem; */
  width: 100%;
  justify-content: center;
  display: grid;
  grid-gap: ${MARGIN}rem;
  grid-template-columns: repeat(auto-fit, 250px);
`

const FeaturesList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${MARGIN}rem;
  /* margin-bottom: ${MARGIN / 4}rem; */
  overflow: hidden;
  max-height: 208px;
`

type Props = {
  currentPackage: IService,
  billingAccount: BillingAccount,
  dispatch: Function,
  onClose: Function,
  open: boolean,
  match: Match,
}

function PackagePage({
  currentPackage,
  billingAccount,
  dispatch,
  match,
  open,
  onClose,
  loading,
}: Props) {
  // const [isActive, setIsActive] = useState(false)
  const isActive =
    currentPackage &&
    currentPackage.base_info &&
    currentPackage.base_info.is_active

  const { app_id: appId } = match.params
  useEffect(() => {
    dispatch(fetchPackage(appId))

    return () => {
      dispatch(clearServices())
      dispatch(fetchServices())
      dispatch(fetchPackages())
    }
  }, [appId, dispatch])

  const toggleService = () => {
    if (isActive) {
      dispatch(deactivatePackage(appId)).then(() =>
        dispatch(fetchPackage(appId)),
      )
    } else {
      dispatch(activatePackage(appId)).then(() => dispatch(fetchPackage(appId)))
    }
  }

  return (
    <Dialog fullScreen open={true} onClose={onClose} scroll="body">
      <ServicePageTemplate
        icon={currentPackage && currentPackage.base_info.image}
        iconStyle={{
          maxWidth: '180px',
          marginTop: '4rem',
          width: 'auto',
        }}
        activeBg={
          currentPackage &&
          currentPackage.base_info.colors &&
          currentPackage.base_info.colors.active_bg
        }
        inactiveBg={
          currentPackage &&
          currentPackage.base_info.colors &&
          currentPackage.base_info.colors.inactive_bg
        }
        activeText={
          currentPackage &&
          currentPackage.base_info.colors &&
          currentPackage.base_info.colors.active_text
        }
        inactiveText={
          currentPackage &&
          currentPackage.base_info.colors &&
          currentPackage.base_info.colors.inactive_text
        }
        isActive={isActive}
        instructions={
          <>
            <Typography variant="h5" align="center">
              Включенные услуги
            </Typography>
            {currentPackage ? (
              currentPackage.base_info.services && (
                <>
                  <ServicesWrapper>
                    {currentPackage &&
                      currentPackage.base_info.services.map(service => (
                        <Link
                          to={`/subscriptions/s/${service.base_info.app_id}`}
                          style={{
                            textDecoration: 'none',
                            width: '80%',
                            maxWidth: 800,
                            minWidth: 250,
                          }}
                          key={service.base_info.app_id}
                        >
                          <GlobalService
                            // data-aos="fade-left"
                            noPrice
                            {...service}
                          />
                        </Link>
                      ))}
                  </ServicesWrapper>
                </>
              )
            ) : (
              <>
                <Skeleton width={250} height={340} />
                <Skeleton width={250} height={340} />
              </>
            )}
          </>
        }
      >
        <IconButton
          disableRipple
          style={{ position: 'fixed', top: '1rem', left: '1rem' }}
          color="inherit"
          component={Link}
          to="/subscriptions"
        >
          <Close />
        </IconButton>
        <Title color="inherit">
          {currentPackage ? (
            currentPackage.base_info.payment_name
          ) : (
            <Skeleton />
          )}
        </Title>
        <Description color="inherit">
          {currentPackage ? (
            currentPackage.base_info.description
          ) : (
            <>
              <Skeleton /> <Skeleton /> <Skeleton />
            </>
          )}
        </Description>
        <FeaturesList>
          {currentPackage &&
            currentPackage.base_info.features &&
            currentPackage.base_info.features.map((f, idx) => (
              <Typography
                style={{ display: 'flex', alignItems: 'center' }}
                align="left"
                color="inherit"
                key={idx}
              >
                <Check style={{ marginRight: '.325rem', height: 12 }} />
                {f}
              </Typography>
            ))}
        </FeaturesList>

        <>
          <Price>
            {currentPackage ? (
              `${currentPackage.base_info.cost / 100}₽`
            ) : (
              <Skeleton />
            )}
          </Price>

          <Button
            variant="contained"
            color={'primary'}
            disabled={
              loading || !currentPackage || currentPackage.base_info.in_package
            }
            onClick={toggleService}
            style={
              isActive
                ? {
                    color: 'black',
                    background: '#fff',
                    boxShadow: 'none',
                  }
                : {}
            }
          >
            {isActive ? 'Отключить' : 'Подключить'}
            {loading && '...'}
          </Button>
        </>
      </ServicePageTemplate>
    </Dialog>
  )
}

const mapStateToProps = ({ services, billing }) => ({
  currentPackage: services.currentPackage,
  billingAccount: billing.account,
  loading: services.loading,
})

export default connect(mapStateToProps)(PackagePage)
