import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Tab = styled(Typography).attrs((p) => ({
  color: 'textSecondary',
  activeStyle: {
    color: p.theme.palette.text.primary,
  },
  component: NavLink,
}))`
  text-decoration: none;
  &:not(:last-child) {
    margin-right: 2rem;
  }
`
function TabItem({ children, exact, to, ...props }) {
  return (
    <Tab {...props} exact={exact || false} to={to}>
      {children}
    </Tab>
  )
}

export default TabItem
