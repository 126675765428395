import { showError, showMessage } from 'features/ui/uiSlice'
import Api from 'utils/api'
import * as actions from './actions'

export const invitePartner = ({ email, name }) => async dispatch => {
  dispatch(actions.invitePartnerRequest())
  try {
    await Api.request('/service_partners', {
      body: JSON.stringify({ name, email }),
      method: 'POST',
    })

    dispatch(showMessage('Приглашение было отправлено на электронную почту партнёра'))
    // dispatch(
    //   actions.invitePartnerSuccess(
    //     'Приглашение было отправлено на электронную почту партнёра',
    //   ),
    // )
    return Promise.resolve()
  } catch (error) {
    const { message } = await error.json()
    dispatch(showError(message))
    // dispatch(actions.invitePartnerFailure(message))
    return Promise.reject()
  }
}

export const reassignDevices = (partnerUuid, devices) => async dispatch => {
  dispatch(actions.reassignDevicesRequest())
  try {
    await Api.request(`/service_partners/${partnerUuid}/devices/reassign`, {
      method: 'PATCH',
      body: JSON.stringify({ device_uuids: devices }),
    })
    dispatch(
      actions.reassignDevicesSuccess('Устройства были успешно привязаны'),
    )
    return Promise.resolve()
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.reassignDevicesFailure(message))
    return Promise.reject()
  }
}

export const fetchPartners = q => async dispatch => {
  dispatch(actions.fetchPartnersRequest())
  try {
    const partners = await Api.request(`/service_partners?q=${q}`)
    dispatch(actions.fetchPartnersSuccess(partners))
    return Promise.resolve(partners)
  } catch (error) {
    const { message } = await error.json()
    dispatch(actions.fetchPartnersFailure(message))
    return Promise.reject()
  }
}
