import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button, FullscreenDialogTitle, FullscreenExitButton } from 'sputnik-ui'

import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core/'
import { Send } from '@material-ui/icons'

import * as Yup from 'yup'

import { PermissionsForm } from './PermissionsForm'
import { useFormik } from 'formik'
import { createManagementCompany, fetchAppAbilities } from 'store/permissions/services'
import { AddressesList } from './AddressesList'
import { getAddressesSelected } from 'store/addresses/selectors/getAddressesSelected'
import { permissionsActions } from 'store/permissions/slice'
import { addressesActions } from 'store/addresses'
import { getPermissionsList } from 'store/permissions'

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const Transition = (props) => <Fade in {...props} />

const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .typeError('введите e-mail')
      .email('неверный формат')
      .required('введите e-mail'),
    name: Yup.string().required('введите имя/название организации'),
  })

const initialValues = {
  email: '',
  name: '',
}

const customPermissionsVariables = {
  on: 'true',
  off: 'false',
}
const isArrayLengthMoreThanZero = (array) => array.length > 0

const InviteUKModal = (props) => {
  const { open, handleClose } = props
  const { clearAddresses } = addressesActions
  const { clearPermission } = permissionsActions
  const dispatch = useDispatch()
  const permissionsList = useSelector(getPermissionsList)
  const selectedAddresses = useSelector(getAddressesSelected)
  const [customPermissions, setCustomPermissions] = useState(customPermissionsVariables.off)

  useEffect(() => {
    dispatch(fetchAppAbilities())
    return () => {
      dispatch(clearAddresses())
      dispatch(clearPermission())
    }
  }, [clearAddresses, clearPermission, dispatch])

  const handleSelectPermission = useCallback((e) => {
    setCustomPermissions(e.target.value)
  }, [])

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: (values) => {
      const params = {
        permissions: permissionsList,
        email: values.email,
        address_uuids: selectedAddresses.map((address) => address.uuid) || [],
        name: values.name,
      }
      dispatch(createManagementCompany(params)).then(handleClose)
    },
  })

  const isSubmitDisabled =
    Object.keys(formik.errors).length > 0 ||
    selectedAddresses?.length < 1 ||
    Object.keys(permissionsList).length < 1 ||
    (customPermissions === customPermissionsVariables.on
      ? !Object.values(permissionsList).some(isArrayLengthMoreThanZero)
      : false)

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} fullScreen>
      <FullscreenDialogTitle subtitle="На указанный e-mail поступит приглашение к регистрации.">
        Пригласить управляющую компанию
      </FullscreenDialogTitle>
      <FullscreenExitButton onClick={handleClose} />

      <Form onSubmit={formik.handleSubmit}>
        <DialogContent
          style={{
            maxWidth: '40rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            margin: '0 auto',
          }}
        >
          <TextField
            required
            autoFocus
            fullWidth
            name="email"
            value={formik.values.email}
            label="Электронная почта"
            onChange={formik.handleChange}
            error={formik.touched.email && !!formik.errors.email}
            autoComplete="email"
            helperText={formik.touched.email && formik.errors.email}
            onBlur={formik.handleBlur}
            margin="dense"
            style={{ marginBottom: '1rem' }}
          />
          <TextField
            required
            fullWidth
            name="name"
            value={formik.values.name}
            label="Имя/название организации"
            onChange={formik.handleChange}
            error={formik.touched.name && !!formik.errors.name}
            autoComplete="name"
            helperText={
              (formik.touched.name && formik.errors.name) ||
              'Используйте для удобного распределения домофонов по территории.'
            }
            onBlur={formik.handleBlur}
            margin="dense"
            placeholder="город, район или принадлежность к УК"
            style={{ marginBottom: '1rem' }}
          />
          <FormControl>
            <FormLabel component="legend">Настройка доступа</FormLabel>
            <RadioGroup
              onChange={handleSelectPermission}
              value={customPermissions}
              name="permissions_customize"
            >
              <FormControlLabel
                control={<Radio />}
                value={customPermissionsVariables.off}
                label="Полный доступ"
              />
              <FormControlLabel
                control={<Radio />}
                value={customPermissionsVariables.on}
                label="Ручной доступ"
              />
            </RadioGroup>
          </FormControl>
          {customPermissions === customPermissionsVariables.on ? <PermissionsForm /> : null}
          <AddressesList />
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'center',
            margin: '1.325rem',
            marginTop: 'auto',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="fullScreen"
            type="submit"
            rounded
            disabled={isSubmitDisabled}
          >
            <Send style={{ marginRight: '8px' }} />
            Пригласить
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default InviteUKModal
