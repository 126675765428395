import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchServicePartners,
  setServicePartnerFilter,
} from 'store/servicePartnersSlice'
import {
  RadioGroup,
  Divider,
  Radio,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  FormHelperText,
  TextField,
  Form,
  InputAdornment,
  withStyles,
} from '@material-ui/core'
import { ReactComponent as SearchIcon } from './Search.svg'
import { ReactComponent as CalendarIcon } from './Calendar.svg'
import {
  downloadReport,
  downloadReportProgram,
} from 'features/analytics/analyticsSlice'
import styled from 'styled-components'
import { Button, Wrapper, Container, Select } from 'sputnik-ui'
import { fetchLocalities, setLocalityFilter } from 'store/localitiesSlice'
import { components } from 'react-select'
import { useFormik } from 'formik'
import ym from 'react-yandex-metrika'
import { ExpandMore } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

const BlueRadio = withStyles({
  root: {
    '&$checked': {
      color: '#2C98F0',
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />)

const Content = styled.div`
  margin-left: 8rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1000px) {
    margin-left: 0rem;
  }
`

const ResDiv = styled.div`
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export default function AnalyticsPage({ ...props }) {
  const dispatch = useDispatch()
  const { t } = useTranslation(['analytics'])

  React.useEffect(() => {
    dispatch(fetchLocalities())
  }, [])

  const localities = useSelector(({ localities }) => localities.list)
  const localityFilter = useSelector(({ localities }) => localities.filter)
  const pending = useSelector(({ analytics }) => analytics.pending)
  const programStatus = useSelector(
    ({ auth }) => auth?.company?.partners_program_accepted
  )

  //   const servicePartners = useSelector(
  //     ({ servicePartners }) => servicePartners.list
  //   )
  //   const servicePartnerFilter = useSelector(
  //     ({ servicePartners }) => servicePartners.filter
  //   )

  const [monthAgo, setMonthAgo] = useState('')
  const [minDate, setMinDate] = useState(null)
  const [isChecked, setIsChecked] = useState(false)

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      download_type: 'xlsx',
      start_date: formatDate(monthAgo),
      end_date: formatDate(new Date()),
      locality_uuid: undefined,
      gilad: false,
    },
    onSubmit: (values) => {
      ym('reachGoal', 'partner_analytics_clickExportRawData')

      values.gilad && programStatus
        ? dispatch(downloadReportProgram(values))
        : dispatch(downloadReport(values))
    },
  })

  useEffect(() => {
    let kek = new Date()
    kek.setMonth(kek.getMonth() - 1)
    setMinDate(null)

    if (dayjs(kek) < dayjs('2021-08-01') && programStatus && isChecked) {
      kek = '2021-08-01'
      setMinDate('2021-08-01')
    }
    if (programStatus && isChecked) {
      setMinDate('2021-08-01')
    }
    setMonthAgo(kek)
  }, [programStatus, isChecked])

  useEffect(() => {
    formik.setFieldValue('start_date', formatDate(monthAgo))
  }, [minDate])

  useEffect(() => {
    formik.setFieldValue('gilad', programStatus)
    setIsChecked(programStatus)
  }, [programStatus])

  //   const DropdownIndicator = (props) => {
  //     return (
  //       <components.DropdownIndicator {...props}>
  //         <SearchIcon />
  //       </components.DropdownIndicator>
  //     )
  //   }
  return (
    <Container>
      <Typography
        variant="h5"
        style={{ marginTop: '2rem', marginBottom: '1rem' }}
      >
        {t('analytics:title')}
      </Typography>

      <form onSubmit={formik.handleSubmit}>
        <Wrapper
          shadow={false}
          style={{ flexDirection: 'column', width: 'fit-content' }}
        >
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            {t('analytics:settings')}
          </Typography>
          <ResDiv>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormControl
                component="fieldset"
                style={{
                  width: '100%',
                  minWidth: 300,
                }}
              >
                <Select
                  // async
                  // loadOptions={options}
                  name="locality_uuid"
                  options={localities}
                  isMulti={false}
                  // isClearable={false}
                  cacheOptions
                  defaultOptions
                  value={localityFilter}
                  onChange={(o) => {
                    dispatch(setLocalityFilter(o))
                    formik.setFieldValue('locality_uuid', o.value)
                  }}
                  placeholder={t('analytics:address_placeholder')}
                  style={{ width: '100%' }}
                  //   value={formik.values.locality_uuid}
                  //   components={{DropdownIndicator}} https://bitbucket.org/sputniks/sputnik-ui/src/d80999d01ab36ce0f7b8936c6d9ec807bec80f86/src/components/atoms/Select/index.js
                />

                <Typography
                  variant="h6"
                  color="textSecondary"
                  style={{ marginTop: '1rem', fontWeight: 400 }}
                >
                  {t('analytics:key_indicators')}
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    disabled={!programStatus}
                    control={
                      <BlueRadio
                        checked={formik.values.gilad}
                        onChange={(e) => {
                          formik.setFieldValue('gilad', true)
                          setIsChecked(true)
                        }}
                      />
                    }
                    label={t('analytics:intecom_income')}
                    name="gilad"
                  />
                  <FormControlLabel
                    control={
                      <BlueRadio
                        checked={!formik.values.gilad}
                        onChange={(e) => {
                          formik.setFieldValue('gilad', false)
                          setIsChecked(false)
                        }}
                      />
                    }
                    label={t('analytics:download_default')}
                    name="gilad"
                  />
                </FormGroup>
              </FormControl>
            </div>

            <Content>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'flex-end',
                  marginTop: 11,
                }}
              >
                <div>
                  <Typography
                    style={{
                      padding: '0 5px 6px  ',
                      lineHeight: '20.3667px',
                    }}
                  >
                    {t('analytics:from')}
                  </Typography>

                  <Divider
                    style={{
                      width: 40,
                      backgroundColor: 'rgba(0, 0, 0, 0.45)',
                    }} //need fix
                  />
                </div>

                <TextField
                  id="date"
                  type="date"
                  inputProps={{
                    min: minDate,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{
                    marginTop: '.325rem',
                  }}
                  name="start_date"
                  onChange={formik.handleChange}
                  value={formik.values.start_date}
                />
                <Divider
                  style={{
                    width: 30,
                    backgroundColor: 'rgba(0, 0, 0, 0.45)',
                  }} //need fix
                />
                <div>
                  <Typography
                    style={{
                      padding: '0 5px 6px  ',
                      marginLeft: 'auto',
                      lineHeight: '20.3667px',
                    }}
                  >
                    -
                  </Typography>

                  <Divider
                    style={{
                      width: 30,
                      backgroundColor: 'rgba(0, 0, 0, 0.45)',
                    }} //need fix
                  />
                </div>
                <div>
                  <Typography
                    style={{
                      padding: '0 5px 6px  ',
                      lineHeight: '20.3667px',
                    }}
                  >
                    {t('analytics:to')}
                  </Typography>

                  <Divider
                    style={{
                      width: 40,
                      backgroundColor: 'rgba(0, 0, 0, 0.45)',
                    }} //need fix
                  />
                </div>
                <TextField
                  //   id="date"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: minDate,
                  }}
                  //   InputProps={{
                  //     endAdornment: (
                  //       <InputAdornment position="end">
                  //         <CalendarIcon style={{ marginTop: -2 }} />
                  //       </InputAdornment>
                  //     ),
                  //   }}
                  style={{
                    marginTop: '.325rem',
                  }}
                  name="end_date"
                  onChange={formik.handleChange}
                  value={formik.values.end_date}
                />
              </div>
              <Typography variant="h6" style={{ margin: '1rem 0' }}>
                {t('analytics:uploading_data')}
              </Typography>

              <FormControl>
                <FormLabel component="legend">
                  {t('analytics:data_format')}
                </FormLabel>
                <RadioGroup
                  onChange={formik.handleChange}
                  value={formik.values.download_type}
                  name="download_type"
                >
                  <FormControlLabel
                      control={<Radio />}
                      value="xlsx"
                      label=".xlsx"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="csv"
                    label=".csv"
                  />
                </RadioGroup>
              </FormControl>
              <Button
                type="submit"
                primary
                style={{ marginTop: '1rem', marginRight: 'auto' }}
                disabled={pending}
              >
                {pending ? t('analytics:loading') : t('analytics:export_data')}
              </Button>
            </Content>
          </ResDiv>
        </Wrapper>
      </form>
    </Container>
  )
}
