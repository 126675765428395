import React from 'react'
import { Button } from 'sputnik-ui'
import {
  DialogActions,
  DialogContent,
  Typography,
  TextField,
  InputAdornment,
  withWidth,
} from '@material-ui/core'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'

const Separator = styled.div`
  width: 100%;
  /* border-bottom: 1px dashed #333; */
  margin: 2rem 0;
`
const ValueText = styled(Typography)`
  && {
    position: relative;
    width: 100%;
    /* font-size: 2.625rem; */
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 2.625rem;
    font-weight: 300;

    @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
      justify-content: flex-start;
    }
  }
`
const DescText = styled(Typography).attrs((p) => ({
  color: 'textSecondary',
}))`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  && {
    display: flex;
    align-items: center;
  }
`

const QuantityInput = styled(TextField)`
  && {
    width: 100%;
    @media screen and (max-width: ${(p) => p.theme.sizes.tablet}) {
      margin: 0;
      width: 100%;
      text-align: left;
    }
  }
`

const BuyProductForm = ({
  onBuy,
  notEnoughBalance,
  product,
  productQty,
  setProductQty,
  handleClose,
  totalPrice,
  setIsDepositing,
  t,
  width,
}) => {
  const isMobile = width === 'xs' || width === 's'
  return (
    <form
      style={{
        overflow: 'visible',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      onSubmit={(e) => {
        e.preventDefault()
        notEnoughBalance ? setIsDepositing(true) : onBuy()
      }}
    >
      <DialogContent
        style={{
          overflow: 'visible',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <ValueText variant="h5">
          {t('common:format.number', { number: product?.cost / 100 })}₽
          <DescText>цена</DescText>
        </ValueText>
        <ValueText variant="h5">
          {product?.unit} <DescText>штуки в одной упаковке</DescText>
        </ValueText>
        <QuantityInput
          autoFocus
          placeholder="количество"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {t('products:units.package', { count: productQty })}
              </InputAdornment>
            ),
          }}
          value={productQty}
          onChange={(e) => {
            const {
              currentTarget: { value },
            } = e
            if (value === '') {
              return setProductQty(value)
            }
            const num = Number(value)
            if (num > 10000) return
            return setProductQty(parseInt(num))
          }}
          // eslint-disable-next-line
          inputProps={{
            style: {
              textAlign: isMobile ? 'left' : 'center',
              minWidth: 160,
              fontSize: '2.625rem',
              marginLeft: isMobile ? 0 : 90,
              fontWeight: 300,
            },
            type: 'number',
            step: 1,
            min: 1,
            max: 10000,
          }}
        />
        <Separator />
        <Typography variant="h5">
          {productQty * product?.unit}{' '}
          {t(`products:devices.${product?.name}_long`, {
            count: productQty * product?.unit,
          })}
        </Typography>
        <Typography variant="h5" color="textSecondary">
          за
        </Typography>
        <Typography
          variant="h4"
          color={notEnoughBalance ? 'error' : 'textPrimary'}
          style={{ fontSize: '4rem', fontWeight: 500 }}
        >
          {t('common:format.number', { number: totalPrice })}₽
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', margin: '1.325rem' }}>
        <Button
          rounded
          onClick={() => (notEnoughBalance ? setIsDepositing(true) : onBuy())}
          disabled={!productQty}
          size="fullScreen"
          variant="contained"
          color={notEnoughBalance ? 'danger' : 'primary'}
          type="submit"
        >
          {notEnoughBalance ? 'пополнить баланс' : 'купить'}
        </Button>
      </DialogActions>
    </form>
  )
}

export default withTranslation(['common', 'products'])(
  withWidth()(BuyProductForm),
)
