// https://github.com/diegohaz/arc/wiki/Atomic-Design#templates
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100vw - (100vw - 100%));
`

const Content = styled.section`
  /* min-height: calc(100% - 7.425rem); */
  height: fit-content;

  /* margin-top: 7.125rem; */
  /* overflow-x: hidden;
  overflow-y: auto; */
`

const AppTemplate = ({ header, children, subheader, ...props }) => {
  return (
    <AppWrapper {...props}>
      {header}
      <Content>{children}</Content>
    </AppWrapper>
  )
}

AppTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  children: PropTypes.any.isRequired,
}

export default AppTemplate
