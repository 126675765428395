import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

const ResDiv = styled.div`
  display: flex;
  align-items: 'center';
  margin: 0 auto;
  margin-bottom: 2.5rem;
  align-items: center;
  max-width: 700px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

function ListItem({ el }) {
  return (
    <ResDiv>
      <img
        style={{ height: 'auto', width: '130px', marginRight: '1rem' }}
        src={el.image}
      />
      <div>
        <Typography
          variant="h5"
          style={{ margin: '1rem 0px', fontWeight: '500' }}
        >
          {el.title}
        </Typography>
        <Typography
          color="textSecondary"
        >
          {el.subTitle}
        </Typography>
      </div>
    </ResDiv>
  )
}

export default ListItem
