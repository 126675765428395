import { Loader } from 'sputnik-ui'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #ffffff55;
  z-index: 10;
`

function FetchingScreen() {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  )
}

export default FetchingScreen