import React from 'react'
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton'
import { Option } from 'components'

const Div = styled.div`
  display: inline-flex;
  alignself: flex-start;
  width: auto;
`

const Options = ({ options, value, onChange, ...props }) => (
  <Div {...props}>
    {options ? (
      options.map(y => (
        <Option selected={value === y} onClick={() => onChange(y)} key={y}>
          {y}
        </Option>
      ))
    ) : (
      <Skeleton width={48} />
    )}
  </Div>
)

export default Options
