//@flow
import * as types from './types'
import type { Action, ITransaction, BillingAccount } from 'globalTypes'

export const fetchTransactionsRequest = (): Action => ({
  type: types.FETCH_TRANSACTIONS_REQUEST,
  payload: {},
})
export const fetchTransactionsSuccess = (
  transactions: ITransaction[]
): Action => ({
  type: types.FETCH_TRANSACTIONS_SUCCESS,
  payload: {
    transactions,
  },
})
export const fetchTransactionsFailure = (error: string): Action => ({
  type: types.FETCH_TRANSACTIONS_FAILURE,
  payload: {
    error,
  },
})

export const fetchAvailablePeriodRequest = (): Action => ({
  type: types.FETCH_AVAILABLE_PERIOD_REQUEST,
  payload: {},
})
export const fetchAvailablePeriodSuccess = (period: string[]): Action => ({
  type: types.FETCH_AVAILABLE_PERIOD_SUCCESS,
  payload: {
    period,
  },
})
export const fetchAvailablePeriodFailure = (error: string): Action => ({
  type: types.FETCH_AVAILABLE_PERIOD_FAILURE,
  payload: {
    error,
  },
})

export const fetchStatisticsRequest = (): Action => ({
  type: types.FETCH_STATISTICS_REQUEST,
  payload: {},
})
export const fetchStatisticsSuccess = (statistics: Array<*>): Action => ({
  type: types.FETCH_STATISTICS_SUCCESS,
  payload: {
    statistics,
  },
})
export const fetchStatisticsFailure = (error: string): Action => ({
  type: types.FETCH_STATISTICS_FAILURE,
  payload: {
    error,
  },
})

export const changeBillingAccountInfoRequest = (): Action => ({
  type: types.CHANGE_BILLING_ACCOUNT_INFO_REQUEST,
  payload: {},
})
export const changeBillingAccountInfoSuccess = (
  msg: string,
  account
): Action => ({
  type: types.CHANGE_BILLING_ACCOUNT_INFO_SUCCESS,
  payload: { msg, account },
})
export const changeBillingAccountInfoFailure = (error: string): Action => ({
  type: types.CHANGE_BILLING_ACCOUNT_INFO_FAILURE,
  payload: { error },
})

export const makeDepositRequest = (): Action => ({
  type: types.MAKE_DEPOSIT_REQUEST,
  payload: {},
})
export const makeDepositSuccess = (msg?: string): Action => ({
  type: types.MAKE_DEPOSIT_SUCCESS,
  payload: { msg },
})
export const makeDepositFailure = (error: string): Action => ({
  type: types.MAKE_DEPOSIT_FAILURE,
  payload: { error },
})

export const fetchBillingAccountRequest = (): Action => ({
  type: types.FETCH_BILLING_ACCOUNT_REQUEST,
  payload: {},
})
export const fetchBillingAccountSuccess = (
  account: BillingAccount
): Action => ({
  type: types.FETCH_BILLING_ACCOUNT_SUCCESS,
  payload: { account },
})
export const fetchBillingAccountFailure = (error: string): Action => ({
  type: types.FETCH_BILLING_ACCOUNT_FAILURE,
  payload: { error },
})

export const unbindPaymentMethodRequest = (): Action => ({
  type: types.UNBIND_PAYMENT_METHOD_REQUEST,
  payload: {},
})
export const unbindPaymentMethodSuccess = (msg: string): Action => ({
  type: types.UNBIND_PAYMENT_METHOD_SUCCESS,
  payload: { msg },
})
export const unbindPaymentMethodFailure = (error: string): Action => ({
  type: types.UNBIND_PAYMENT_METHOD_FAILURE,
  payload: { error },
})

export const clearTransactionsData = (): Action => ({
  type: types.CLEAR_TRANSACTIONS_DATA,
  payload: {},
})
