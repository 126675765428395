//@flow
//$FlowFixMe
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from 'recharts'
import { Wrapper, Options } from 'sputnik-ui'
import { CircularProgress } from '@material-ui/core'

import type { Stat } from 'globalTypes'

const BillingStats = (props: Props) => {
  const [chart, setChart] = useState('столбчатая')

  return (
    <>
      <Wrapper
        style={{
          flexDirection: 'column',
        }}
      >
        <Options
          options={['столбчатая', 'линейная']}
          value={chart}
          onChange={setChart}
          style={{ marginBottom: '0.625rem' }}
        />
        {props.stats ? (
          <ResponsiveContainer height={400} width="100%">
            {chart === 'столбчатая' ? (
              <BarChart data={props.stats}>
                <XAxis axisLine={false} dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  name="потрачено"
                  dataKey="total_spent"
                  fill={props.theme.palette.primary['500']}
                  minPointSize={5}
                />
                <Bar
                  name="внесено"
                  dataKey="total_deposit"
                  fill={props.theme.palette.success['500']}
                  minPointSize={10}
                />
              </BarChart>
            ) : (
              <LineChart data={props.stats}>
                <XAxis axisLine={false} dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dot={false}
                  name="потрачено"
                  dataKey="total_spent"
                  stroke={props.theme.palette.primary['500']}
                  activeDot={{ r: 8 }}
                />
                <Line
                  dot={false}
                  type="monotone"
                  name="внесено"
                  dataKey="total_deposit"
                  stroke={props.theme.palette.success['500']}
                />
              </LineChart>
            )}
          </ResponsiveContainer>
        ) : (
          <div style={centered}>
            <CircularProgress />
          </div>
        )}
      </Wrapper>
    </>
  )
}

const centered = {
  height: 300,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

type Props = {
  stats?: Stat[],
  theme: Object,
}

const mapStateToProps = ({ billing }) => ({
  stats: billing.statistics,
})

//$FlowFixMe
export default connect(mapStateToProps)(withTheme<any, any>(BillingStats))
