import { TableBody } from '@material-ui/core'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Button } from 'sputnik-ui'
import { Loader } from 'sputnik-ui'
import { Checkbox } from 'sputnik-ui'
import { TableCell } from 'sputnik-ui'
import { TableRow } from 'sputnik-ui'
import { Table } from 'sputnik-ui'
import { removeAddresses } from 'store/addresses'
import {
  fetchManagementCompany,
  fetchManagementsCompanies,
  getCurrentCompanyAddresses,
  getPermissionsCurrentCompany,
} from 'store/permissions'

export const UKLinkedList = () => {
  const dispatch = useDispatch()
  const addresses = useSelector(getCurrentCompanyAddresses)
  const [isLoading, setLoading] = useState(false)
  const company = useSelector(getPermissionsCurrentCompany)
  const [selectedAddresses, setSelectedAddresses] = useState([])
  const handleSelectAddress = useCallback(
    (address) => {
      const isSelected = selectedAddresses?.some((el) => el.address_uuid === address.address_uuid)
      if (isSelected) {
        const updatedAddresses = selectedAddresses.filter(
          (el) => el.address_uuid !== address.address_uuid
        )
        setSelectedAddresses(updatedAddresses)
      } else {
        setSelectedAddresses([...selectedAddresses, address])
      }
    },
    [selectedAddresses]
  )
  const handleRemoveAddresses = async () => {
    setLoading(true)
    await dispatch(
      removeAddresses({
        uuid: company.company_uuid,
        address_uuids: selectedAddresses.map((selected) => selected.address_uuid),
      })
    )
    await dispatch(fetchManagementsCompanies())
    await dispatch(fetchManagementCompany({ uuid: company.company_uuid }))
    setSelectedAddresses([])
    setLoading(false)
  }

  return (
    <div>
      <Typography variant="title">Прикрепленные адреса</Typography>
      <Table>
        <TableBody>
          {addresses ? (
            addresses?.map((address) => {
              return (
                <TableRow key={address.uuid}>
                  <TableCell>
                    <Checkbox
                      disabled={isLoading}
                      checked={selectedAddresses?.find(
                        (selected) => selected.address_uuid === address.address_uuid
                      )}
                      onChange={(e) => handleSelectAddress(address)}
                    />
                    {address.short_address}
                  </TableCell>
                </TableRow>
              )
            })
          ) : (
            <TableRow>Тут ничего нет</TableRow>
          )}
        </TableBody>
      </Table>
      <Button variant="v2" secondary onClick={handleRemoveAddresses} disabled={isLoading}>
        Удалить выбранные
      </Button>
    </div>
  )
}
