import * as React from 'react'
import Table from '@material-ui/core/Table'

import TableHead from './TableHead'
import TableBody from './TableBody'
import TablePagination from './TablePagination'
import TableToolbar from './TableToolbar'

const DevicesTable = props => (
  <>
    <TableToolbar {...props}></TableToolbar>
    <Table
      style={{
        width: '100%',
        tableLayout: 'fixed',
        marginBottom: 56,
      }}
    >
      <TableHead {...props} />
      <TableBody {...props} />
    </Table>
    <TablePagination {...props} />
  </>
)

export default DevicesTable
