//@flow
//$FlowFixMe
import React, { useState, useEffect } from 'react'
import { Button } from 'sputnik-ui'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import type { Match } from 'react-router-dom'

import {
  activateService,
  deactivateService,
  fetchServices,
  fetchDeviceInfo,
  checkAuth,
} from 'store/operations'

type Props = {
  isConnected: boolean,
  appId: string,
  dispatch: Function,
  style: ?Object,
  match: Match,
  loading: boolean,
  active: Array<Object>,
  inactive: Array<Object>,
}

const mapStateToProps = ({ services: { loading, active, inactive } }) => ({
  loading,
  active,
  inactive,
})

export default withRouter(
  connect(mapStateToProps)(
    ({
      isConnected,
      appId,
      loading,
      active,
      inactive,
      match,
      dispatch,
      style,
    }: Props) => {
      const { device } = match.params

      const updateData = () => {
        if (device) {
          dispatch(fetchDeviceInfo(device))
        } else {
          dispatch(fetchServices())
        }
        dispatch(checkAuth(true))
      }

      const [disabledButton, setDisabled] = useState(loading)
      useEffect(() => {
        setDisabled(loading)
      }, [active, inactive, loading])

      return isConnected ? (
        <Button
          rounded
          color="danger"
          variant={undefined}
          size="small"
          onClick={() => {
            setDisabled(true)
            dispatch(deactivateService(appId, device))
              .then(updateData)
              .catch(updateData)
          }}
          style={style}
          disabled={disabledButton}
        >
          отключить
        </Button>
      ) : (
        <Button
          rounded
          color="success"
          variant={undefined}
          size="small"
          onClick={() => {
            setDisabled(true)
            dispatch(activateService(appId, device))
              .then(updateData)
              .catch(updateData)
          }}
          style={style}
          disabled={disabledButton}
        >
          подключить
        </Button>
      )
    },
  ),
)
