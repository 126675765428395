import React from 'react'
import styled from 'styled-components'
import { Container } from 'sputnik-ui'

const Wrapper = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
`

const Actions = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > * {
    margin-bottom: 0.625rem;

    &:not(:last-child) {
      margin-right: 0.625rem;
    }
  }
`

const DevicesTemplate = ({ children, actions, ...props }) => {
  return (
    <Wrapper {...props}>
      <Container>
        {/* <Typography variant="h5">Действия</Typography> */}
        <Actions>{actions}</Actions>
      </Container>
      {children}
    </Wrapper>
  )
}

DevicesTemplate.propTypes = {}

export default DevicesTemplate
