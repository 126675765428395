import React, { useState, useEffect } from 'react'
import { DevicesTable, DeviceDetails, DevicesTemplate } from 'components'
import { Search, Button, Container } from 'sputnik-ui'
import { useDispatch } from 'react-redux'
import { Typography } from '@material-ui/core'

import { AddAlert, Settings, Poll } from '@material-ui/icons'
import ym from 'react-yandex-metrika'
import InvitePartnerModal from 'components/organisms/InvitePartnerModal'
import { PersonAdd } from '@material-ui/icons'

import DevicesList from 'features/Devices/DevicesList'
import CompanyServices from 'features/Services/CompanyServices'
import { useTranslation } from 'react-i18next'
import InviteUKModal from 'components/organisms/InviteUKModal'
import EditUKModal from 'components/organisms/EditUKModal'

const styles = {
  icon: { marginRight: 8, height: 28 },
}

function DevicesPage() {
  const dispatch = useDispatch()
  const { t } = useTranslation('device')

  const [invitePartnerModal, setInvitePartnerModal] = useState()
  const [inviteUKModal, setInviteUKModal] = useState(false)
  const [editUKModal, setEditUKModal] = useState(false)
  return (
    <DevicesTemplate
      actions={
        <>
          <Button
            primary
            size="small"
            rounded
            onClick={() => setInvitePartnerModal(true)}
            variant="text"
          >
            <PersonAdd style={styles.icon} />
            {t('device:tabs.invite_partner')}
          </Button>
          <Button
            primary
            size="small"
            rounded
            onClick={() => setInviteUKModal(true)}
            variant="text"
          >
            <PersonAdd style={styles.icon} />
            Пригласить УК
          </Button>
          <Button primary size="small" rounded onClick={() => setEditUKModal(true)} variant="text">
            <Settings style={styles.icon} />
            Управлять УК
          </Button>
          <Button
            primary
            size="small"
            rounded
            component="a"
            href="https://control.sputnik.systems"
            onClick={() => ym('reachGoal', 'partner_devices_controlClick')}
            variant="text"
          >
            <Settings style={styles.icon} />
            {t('device:tabs.control_panel')}
          </Button>

          <Button
            primary
            size="small"
            rounded
            component="a"
            href="https://glashatai.sputnik.systems/new"
            onClick={() => ym('reachGoal', 'partner_devices_newNotification')}
            variant="text"
          >
            <AddAlert style={styles.icon} />
            {t('device:tabs.social_info')}
          </Button>

          <Button
            primary
            size="small"
            rounded
            component="a"
            href="https://glashatai.sputnik.systems/new"
            onClick={() => ym('reachGoal', 'partner_devices_newPoll')}
            variant="text"
          >
            <Poll style={styles.icon} />
            {t('device:tabs.create_poll')}
          </Button>
        </>
      }
    >
      <Container>
        {/* <CompanyServices /> */}
        <Typography variant="h5" gutterBottom>
          {t('device:your_devices')}
        </Typography>
      </Container>

      {/* <DevicesTable />*/}
      <DeviceDetails />
      <DevicesList></DevicesList>

      <InvitePartnerModal
        open={invitePartnerModal}
        handleClose={() => setInvitePartnerModal(false)}
      />
      <InviteUKModal open={inviteUKModal} handleClose={() => setInviteUKModal(false)} />
      <EditUKModal open={editUKModal} handleClose={() => setEditUKModal(false)} />
    </DevicesTemplate>
  )
}

export default DevicesPage
