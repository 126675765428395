import * as types from './types'
import SputnikImg from './sputnik-group.png'
import CamImg from './cam-group-2.png'
import { clear } from 'features/ui/uiSlice'

const initialState = {
  msg: null,
  error: null,
  list: [
    {
      id: 0,
      title: 'Умный домофон «Спутник»',
      description: 'Спутник это управляемый через интернет IP домофон.',
      features: [
        '- со встроенной IP-видеокамерой и мобильным приложением,',
        '- не требующий замены аналоговых координатно-матричных линий и абонентских трубок в квартирах,',
        '- совместимый с абонентскими ключами, распространенными в России',
      ],
      img: SputnikImg,
      cost: 8800 * 100,
      calculator_link:
        'https://docs.google.com/spreadsheets/d/1CRgDDMB2UockTGnQmzBxCkRnNcwQZfTo5YqnFo1VOOQ/edit#gid=0',
      app_id: '60fea06b-7230-4a13-8f2a-3241bf068769',
      unit: 4,
      name: 'sputnik',
    },
    {
      id: 1,
      title: 'Цифровая камера «ISee»',
      description:
        'Купольная вандалоустойчивая камера в металлическом корпусе, работающая только с облаком Спутника. В приложении жителя будет доступно видео с этой камеры.',
      features: [
        '- 1/2.8" IMX307 Starlight CMOS sensor',
        '- Full HD resolution 1920 x 1080P',
        '- Low illumination 0.001 Lux',
        '- Day/Night (ICR), AWB, 2D/3D-DNR, IR-CUT',
        '- D-WDR, Motion Detection, Privacy Mask, Mirror, Flip',
        '- RTSP and Onvif 17.06 compatible',
        '- H.265/H.264 dual-stream media server ',
        '- Easy-to-use P2P Cloud service',
        '- Multi web browser, PC Client, mobile APP remote access',
        '- Lightning protection 4000V',
        '- 3.6mm Fixed Lens',
      ],
      img: CamImg,
      cost: 3000 * 100,
      calculator_link: null,
      app_id: 'c3ed5d0a-2806-43b0-89e9-699880756c47',
      unit: 4,
      name: 'camera',
    },
  ],
}

export default function products(state = initialState, { type, payload }) {
  switch (type) {
    case types.BUY_PRODUCT_SUCCESS:
      return { ...state, msg: payload.msg, error: null }
    case types.BUY_PRODUCT_FAILURE:
      return {
        ...state,
        error: payload.error,
        msg: null,
      }
    case [clear.type]:
      return { ...state, msg: null, error: null }
    default:
      return state
  }
}
