export default [
  {
    id: 'serial_number',
    numeric: false,
    disablePadding: false,
    label: 'устройство',
  },
  {
    id: 'service_partner_name',
    numeric: false,
    disablePadding: false,
    label: 'сервис-партнер',
  },

  {
    id: 'is_online',
    numeric: false,
    disablePadding: false,
    label: 'статус подключения',
  },
  {
    id: 'installed',
    numeric: false,
    disablePadding: false,
    label: 'статус установки',
  },
  {
    id: 'services_count',
    numeric: false,
    disablePadding: false,
    label: 'подключенных услуг',
  },
]
