import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import API from 'utils/api'
import { buildUrl, clamp } from 'utils/helpers'
import config from 'config'
import { showError, showMessage } from 'features/ui/uiSlice'

export const fetchRoadMapList = createAsyncThunk(
  'roadmap/fetchList',
  async (arg, { rejectWithValue, dispatch }) => {
    try {
      const payload = await API.request(
        '/roadmap/events',
        undefined,
        `${config.redirectUri}/api/v2`
      )
      return payload
    } catch (err) {
      const { message } = await err.json()
      dispatch(showError(message))
      return rejectWithValue()
    }
  }
)

export const fetchAnswer = createAsyncThunk(
  'roadmap/fetchAnswer',
  async ({ id, status }, { rejectWithValue, dispatch }) => {
    try {
      await API.request(
        `/roadmap/events/${id}/poll_results`,
        { method: 'POST', body: JSON.stringify({ result: status }) },
        `${config.redirectUri}/api/v2`
      )
    } catch (err) {
      const { message } = await err.json()
      dispatch(showError(message))
      return rejectWithValue()
    }
  }
)

export const RoadMapSlice = createSlice({
  name: 'roadmap',
  initialState: {
    list: [],
    pending: true,
  },
  reducers: {
    clearRoadMap: (state) => {
      state.list = []
      state.pending = false
    },
    vote: (state, { payload }) => {
      state.list = state.list.map((el) => {
        if (el.id === payload.id) el.poll_result = payload.status
        return el
      })
    },
  },
  extraReducers: {
    [fetchRoadMapList.pending]: (state) => {
      state.pending = true
    },
    [fetchRoadMapList.fulfilled]: (state, { payload }) => {
      state.pending = false
      state.list = payload
    },
    [fetchRoadMapList.rejected]: (state) => {
      state.pending = false
    },
  },
})

export const { clearRoadMap, vote } = RoadMapSlice.actions

export default RoadMapSlice.reducer
