//@flow
import * as React from 'react'
import { AccountsTemplate, AddUserModal, UsersTable, Search } from 'components'
import { Loader, Button } from 'sputnik-ui'
import { connect } from 'react-redux'
import type { State, Props } from './types'
import throttle from 'lodash/throttle'
import { PersonAdd, Help } from '@material-ui/icons'

import { getUsers } from 'store/users/operations'
import { Typography, Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { FileCopy } from '@material-ui/icons'
import { copyToClipboard } from 'utils/helpers'
import { showInfo } from 'features/ui/uiSlice'

// const styles = theme => ({
//   root: {
//     width: '100%',
//     marginTop: theme.spacing.unit * 3,
//   },

//   search: {
//     minWidth: '300px',
//     zIndex: 1200,
//     background: 'white',
//     paddingLeft: 10,
//   },
// })

class HomePage extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      inviteUserModal: false,
      isSearching: false,
      searchQuery: '',
      page: 0,
      limit: 10,
      order: 'asc',
      sort: 'email',
    }

    this.searchUsers = throttle(this.searchUsers, 400)
  }

  componentDidMount = () => {
    this.props.dispatch(getUsers({ page: this.state.page, limit: this.state.limit }))
  }

  toggleInviteUserModal = () =>
    this.setState({
      inviteUserModal: !this.state.inviteUserModal,
    })

  toggleSearch = () => this.setState({ isSearching: !this.state.isSearching })

  searchUsers = () =>
    this.props.dispatch(
      getUsers({
        q: this.state.searchQuery,
        page: this.state.page,
        limit: this.state.limit,
        order: this.state.order,
        sort: this.state.sort,
      })
    )

  onChange = ({ currentTarget: { value } }) =>
    this.setState({ searchQuery: value }, this.searchUsers)

  handleChangePage = (event: ?SyntheticEvent<>, page: number) =>
    this.setState({ page }, () => {
      this.props.dispatch(
        getUsers({
          q: this.state.searchQuery,
          page: this.state.page,
          limit: this.state.limit,
          order: this.state.order,
          sort: this.state.sort,
        })
      )
    })

  handleChangeRowsPerPage = (e: SyntheticEvent<>) =>
    this.setState({ limit: e.target.value }, () =>
      this.props.dispatch(
        getUsers({
          q: this.state.searchQuery,
          page: this.state.page,
          limit: this.state.limit,
          order: this.state.order,
          sort: this.state.sort,
        })
      )
    )

  handleRequestSort = (event: SyntheticEvent<Event>, property: string) => {
    const sort = property
    let order = 'desc'

    if (this.state.sort === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, sort, page: 0 }, () =>
      this.props.dispatch(
        getUsers({
          page: this.state.page,
          limit: this.state.limit,
          q: this.state.searchQuery,
          order: this.state.order,
          sort: this.state.sort,
        })
      )
    )
  }
  handleCopyCode = () => {
    copyToClipboard(this.props.serviceToken)
    this.props.dispatch(showInfo('Скопировано'))
  }

  render() {
    const { usersList } = this.props
    const { inviteUserModal, page, limit } = this.state

    if (!usersList) return <Loader msg="Загружаем список пользователей" />

    return (
      <AccountsTemplate
        subheader={
          <Search
            placeholder="имя, компания или e-mail"
            style={{ width: '100%' }}
            onChange={this.onChange}
            value={this.state.searchQuery}
          />
        }
      >
        <UsersTable
          users={usersList.users}
          count={usersList.meta.total}
          page={page}
          handleChangePage={this.handleChangePage}
          limit={limit}
          sort={this.state.sort}
          order={this.state.order}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          handleRequestSort={this.handleRequestSort}
          style={{ marginTop: '2rem' }}
          actions={
            <>
              <Button onClick={this.toggleInviteUserModal} secondary rounded disableRipple>
                <PersonAdd style={{ marginRight: 8 }} />
                Пригласить пользователя
              </Button>

              <div
                style={{
                  display: 'inline-flex',
                  marginLeft: '1rem',
                  alignItems: 'center',
                }}
              >
                <Link
                  to={{
                    pathname: 'https://t.me/PartnerSystemsBot',
                  }}
                  target="_blank"
                  style={{ textDecoration: 'none', color: 'unset' }}
                >
                  <Typography>Секретный код для @PartnerSystemsBot: </Typography>
                </Link>

                <Typography style={{ marginLeft: '.5rem' }}>{this.props.serviceToken}</Typography>
                <FileCopy
                  style={{ marginLeft: '.5rem', cursor: 'pointer' }}
                  onClick={() => this.handleCopyCode()}
                />
                <Tooltip title="Данный код используйте для авторизации в телеграм боте @PartnerSystemsBot">
                  <Help
                    style={{
                      marginLeft: 8,
                      width: '1.2rem',
                      height: '1.2rem',
                      cursor: 'help',
                    }}
                  />
                </Tooltip>
              </div>
            </>
          }
        />

        <AddUserModal open={inviteUserModal} handleClose={this.toggleInviteUserModal} />
      </AccountsTemplate>
    )
  }
}

const mapStateToProps = ({ users, billing, auth }) => ({
  usersList: users.list,
  serviceCode: billing.account && billing.account.service_code,
  serviceToken: auth.company.service_token,
})

//$FlowFixMe
export default connect(mapStateToProps)(HomePage)
