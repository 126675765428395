import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConfirmModal } from 'sputnik-ui'
import { Typography, Button } from 'sputnik-ui'
import { Loader } from 'sputnik-ui'

import {
  deleteManagementCompany,
  fetchManagementCompany,
  fetchManagementsCompanies,
  getCompaniesList,
  getManagementCompaniesPending,
} from 'store/permissions'

const UKList = () => {
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [currentCompany, setCurrentCompany] = useState(undefined)

  const dispatch = useDispatch()
  const companies = useSelector(getCompaniesList)
  const pending = useSelector(getManagementCompaniesPending)

  if (pending) {
    return <Loader />
  }

  const handleFetchCompany = (uuid) => {
    return dispatch(fetchManagementCompany({ uuid }))
  }

  const handleCloseDeleteModal = () => {
    setCurrentCompany(undefined)
    setConfirmDeleteModal(false)
  }

  const handleDeleteCompany = async () => {
    await dispatch(deleteManagementCompany({ uuid: currentCompany }))
    await dispatch(fetchManagementsCompanies())
    handleCloseDeleteModal()
    await dispatch(fetchManagementCompany({ uuid: currentCompany }))
  }

  const handleOpenDeleteModal = (company_uuid) => {
    setCurrentCompany(company_uuid)
    setConfirmDeleteModal(true)
  }

  return (
    <>
      <ConfirmModal
        onClose={handleCloseDeleteModal}
        open={confirmDeleteModal}
        title="Аннулировать доступ"
        label="Компания не будет удалена и будет лишена всех доступов к адресам. Это действие нельзя отменить"
        actions={
          <>
            <Button fullWidth variant="v2" primary onClick={handleDeleteCompany}>
              Аннулировать доступ
            </Button>
            <Button variant="v2" fullWidth tertiary onClick={handleCloseDeleteModal}>
              Отмена
            </Button>
          </>
        }
      />

      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto 0px',
          alignItems: 'center',
        }}
      >
        {companies &&
          companies.map(({ company_uuid, email, name, addresses }) => {
            return (
              <div
                key={company_uuid}
                style={{
                  width: '100%',
                  display: 'flex',
                  padding: '1rem',
                  justifyContent: 'space-between',
                  gap: '1rem',
                }}
              >
                <div>
                  <Typography>Название: {name}</Typography>
                  <Typography>Email: {email}</Typography>
                  <Typography>{addresses?.length ? 'Есть доступы' : 'Нет доступов'}</Typography>
                </div>

                <div style={{ display: 'flex', gap: '1rem' }}>
                  <Button secondary variant="v2" onClick={() => handleFetchCompany(company_uuid)}>
                    Настройки доступа
                  </Button>

                  <Button
                    primary
                    variant="v2"
                    onClick={() => handleOpenDeleteModal(company_uuid)}
                    disabled={!addresses?.length}
                  >
                    Аннулировать доступ
                  </Button>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default UKList
