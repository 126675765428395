import * as types from './types'

const initialState = {
  list: null,
  totalPages: null,
  error: null,
  current: null,
  query: '',
}

export default function devices(state = initialState, { type, payload }) {
  switch (type) {
    case types.FETCH_DEVICES_REQUEST:
      return { ...state, list: null }
    case types.FETCH_DEVICES_SUCCESS:
      return { ...state, list: payload.devices, totalPages: payload.totalPages }
    case types.FETCH_DEVICES_FAILURE:
      return { ...state, error: payload.error }
    case types.FETCH_DEVICE_INFO_SUCCESS:
      return { ...state, current: payload.device }
    case types.FETCH_DEVICE_INFO_FAILURE:
      return { ...state, error: payload.error }
    case types.CLEAR_CURRENT_DEVICE:
      return { ...state, current: null }
    case types.SET_DEVICE_SEARCH_QUERY:
      return { ...state, query: payload.query }
    default:
      return state
  }
}
